//
//	RIS Specific overwrite, from old search style
//

// Fix containers auto-height
#page.zoeken #wrapper {
	.container { min-height: auto; }
	#content { min-height: 500px; }

	#gosFoldout .container {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.container .container { margin: 0px; }
}

// SD-54981 Add spinner for feedback when longer loading times
.gosearch {
	.results { position: relative; }

	.loading-placeholder {
		text-align: center;
		position: absolute;
		inset: 0px auto 0px 0px;
		background-color: #fff;
		transition: all 0.3s 1s linear;
		width: 100%;
		max-width: var(--gos-results-size);

		.loading-txt {
			position: sticky;
			inset: 100px 0 auto 0;
			margin-top: 100px;
			color: var(--alt-grey);
		}

		.icn {
			font-size: 32px;
			color: var(--soft-grey);
		}
	}

	&:not(.is-loading) {
		.loading-placeholder {
			opacity: 0;
			visibility: hidden;
		}
	}
}

