//
//  Desktop wide
//  - defines css for wider desktop view (max 1200px)
//

// Wider container ( max 1200px on desktop )
/* Desktop wide option */
@media ( min-width: 1240px ){

    .desktop-wide {
        .container {
            max-width: 1200px;
            width: 100%;
            min-width: 1024px;

            .container {
                min-width: auto;
            }
        }
    
        .block.next {
            height: 441px;
            background-size: cover;
        }
    
        #live_player { width: calc( 66.666666% - 20px ); }
        #content { width: calc( 100% - 340px ); }
        
        #user .container {
            box-sizing: border-box;
            padding-right: 0px;
        }
        
        // Submenu size
        #navigation, .mijnoverzicht, .mijnprofiel {
            width: 300px;
        }
    
        #uitzending_meeting #live_meta {
            height: 441px;
        }
    }
}
