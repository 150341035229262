//
//  Calendar
//  - styling of the calendar widget, used on home and on the kalender/agenda page (default /kalender
//  - matches the style of the same widget in CMS (Inwonersportaal)
//  - therefor the calendar can be UL/LI based or TABLE based
//
.calendar-default {

    // Set calendar variables
    --cal-bg: #{$white};
    --cal-border: #{$e4};
    --cal-caption-bg: var(--primary);
    --cal-caption-txt: #{$white};
    --cal-caption-btn: #{$white};
    --cal-today: var(--primary);
    --cal-today-col: #{$white};
    --cal-amount-bg: var(--secondary);
    --cal-amount-size: 20px;
    --cal-amount-offset: 0px;

    // Color schemes
    &.secondary, .secondary & {
        --cal-caption-bg: var(--secondary);
        --cal-today: var(--secondary);
        --cal-amount-bg: var(--primary);
    }

    // Calendar styling
    background-color: getVar(--cal-bg, $white);
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    height: 100%;
    color: getVar( --dark-grey, $darkGrey );
    border: 1px solid var(--cal-border);

    // Reset table
    table, ul {
        width: 100%;
        margin: 0px;
        list-style: none;
        padding: 0px;
    }

    tr, ul {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        margin-top: 5px;
    }

    tr, td, th {
        background-color: transparent;
        border: 0px;
        color: inherit;
        padding: 0px;
    }

    td { border-bottom: 1px solid $e4; }

    h3, h4 {
        font: inherit;
        margin: 0px;
    }

    // Calendar title and next / previous navigation
    .caption {
        background-color: var(--cal-caption-bg);
        color: getVar(--cal-caption-txt, $white);
        display: flex;
        flex-flow: row nowrap;

        a {
            color: inherit;
            text-decoration: none;
            flex: 0 0 40px;
            font-weight: bold;
            line-height: 40px;

            &:hover, &:focus {
                background-color: rgba( 0, 0, 0, 0.25 );
            }
        }

        .current-month {
            order: 2;
            flex: 1 1 auto;
            font-size: var(--font-size-large);
            margin: 0px;
        }

        .previous-month, .next-month {
            color: var(--cal-caption-btn, #fff);
            font-size: var(--font-size-larger);

            &:before {
                font-family: $font-awesome;
            }
        }

        .previous-month { order: 1; }
        .next-month { order: 3; }

        .previous-month:before { content: "\f104"; }
        .next-month:before { content: "\f105"; }
    }

    // Calendar days
    .days {
        background-color: $f5;
        color: inherit;
        line-height: 40px;
        text-align: center;
        margin: 0px;

        th, li {
            text-align: center;
        }
    }

    // Day in calendar
    .day {
        position: relative;

        .day-label-holder { display: block; }
        .amount, .day-label { display: inline-block; }

        .day-link {
            display: block;
            width: 100%;
            text-align: center;
            color: inherit;
            text-decoration: none;
        }

        .day-label {
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            font-size: var(--font-size-smaller);
            background-color: $white;
            border-radius: 3px;
        }

        .amount {
            visibility: hidden;
            color: $white;
            background-color: var(--cal-amount-bg);
            text-align: center;
            width: var(--cal-amount-size);
            height: var(--cal-amount-size);
            line-height: var(--cal-amount-size);
            border-radius: 100%;
            font-size: var(--font-size-smaller);
            margin: var(--cal-amount-offset);
        }

        a.day-link {
            .amount { visibility: visible; }

            &:hover, &:focus {
                background-color: $backBlue;
                outline: 2px solid $focus-outline-color;
            }
        }
    }

    .today .day-label {
        background-color: var(--cal-today);
        color: var(--cal-today-col);
    }
}


// Size variations
.double, .triple, .full {
    .calendar-default {
        @media( min-width: $portrait ) {
            --cal-amount-size: 30px;
            --cal-amount-offset: 5px 0px 10px 0px;
        }
    }
}
