//
//  Load notifications component
//  - shows a small notifications box when the user is logged-in.
//  - notification box can shows updates and can be minimized
//


/*
     Notifications statussen
     - verborgen - bij verbergen schuift deze naar onderen het scherm uit
     - actief - schuift in van onderen
     - minimal - geminimaliseerd, toont alleen het icoon
     - update animatie geminimaliseerd
     - update animatie gemaximaliseerd
*/

$offset: 40px;
$border: 1px solid $softGrey;
$amount-bg: #de1b1b;

@import "_base";
@import "header";
@import "content";
@import "mini";
