//
//  Styling of common parts in header and sidebar
//

@use 'sass:math';

// Options
.options {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    
    a:not(.button) {
        text-decoration: none;
        color: inherit;
    }
    
    .opt {
        display: block;
        flex: 0 0 auto;
        text-align: center;
        border-radius: 3px;
        font-size: 20px;
        color: $darkGrey;
        margin: 2px;
        cursor: pointer;
        transition: all 0.2s linear;
        position: relative;
        box-sizing: border-box;
        
        @include equalAll($opt-size);
    
        * { pointer-events: none; }
        
        &:hover {
            background-color: $f5;
            color: inherit;
        }

        @media( max-width: ( $mobileLandscape - 1px  ) ) {
            margin: 0px;
        }

        @media( min-width: $portrait ) {
            &.mobile-only { display: none; }
        }


    }
    
    @media ( min-width: $quicksearch-breakpoint ) {
        .mobile-quicksearch { display: none; }
    }
    
    .toggle-mobile-menu {
        text-decoration: none;

        @media (min-width: $landscape) {
            display: none;
        }
        
        * { pointer-events: none; }
    }
    
    .initials {
        font-size: ( math.div($initials-size, 2) - 2px );
        color: $white;
        background-color: getVar( --primary, $primary);
        border-radius: 100%;
        display: inline-block;
        transition: all 0.2s linear;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        @include equalAll( $initials-size );
        
        &:hover { opacity: 0.7; }
    }
}

// Quicksearch
.quicksearch {
    margin-left: 10px;
    position: relative;
    
    @media ( max-width: ( $quicksearch-breakpoint - 1px ) ) {
        display: none;
    }

    legend, label { display: none; }
    
    fieldset {
        padding: 0px;
        margin: 0px;
        border: 0px;
    }
    
    .quicksearch-input {
        width: 230px;
        margin: 0px;
        padding: 0px ($quicksearch-size + 4px) 0px 10px;
        min-height: $quicksearch-size;
        border-radius: 3px;
    }
    
    .quicksearch-button, .quicksearch-icon {
        position: absolute;
        right: 0px;
        top: 0px;
        border: 0px;
        
        @include equalAll( $quicksearch-size );
    }
    
    .quicksearch-button {
        margin: 0px;
        text-indent: -9999px;
        overflow: hidden;
        min-width: $quicksearch-size;
        min-height: $quicksearch-size;
        background-color: transparent;
        z-index: 3;
        cursor: pointer;
        
        &:hover+.quicksearch-icon { color: $mediumGrey; }
    }
    
    .quicksearch-icon {
        text-align: center;
        z-index: 2;
        pointer-events: none;
        color: var(--alt-grey);
        transition: all 0.15s linear;
    }
}

// Menu bar
.menu {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-flow: row wrap;
    margin: 0px;

    ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
    }

    li {
        position: relative;
        box-sizing: border-box;
    }

    a {
        color: getVar( --basic-menu-txt, inherit );
        text-decoration: none;
        padding: 12px 20px;
        font-size: var(--font-size);
        font-weight: bold;
        line-height: 30px;
        vertical-align: center;
        transition: all 0.15s linear;
        
        &:hover { color: $darkGrey; }
        &.active { color: getVar( --header-active, $primary); }
    }
}
