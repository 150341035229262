//
//  Dashboard toolbar
//
.dashboard-toolbar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin: 12px 0px 25px 0px;
    align-items: center;

    .icn, .text {
        pointer-events: none;
    }

    .options {
        flex: 0 0 auto;
        margin-left: 10px;
        line-height: 24px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;

        & + .options {
            border-left: 1px solid var(--e4);
            padding-left: 10px;
        }
    }

    .title {
        flex: 1 1 auto;
        text-align: left;
        margin: 0px;
    }

    .button.smaller {
        &, .icn { color: var(--secondary); }
    }

    .amount {
        margin-right: 10px;

        @media ( max-width: ( $portrait - 1px )) {
            display: none;
        }
    }

    .opt, .view-opt {
        text-decoration: none;
        display: inline-block;
        text-align: center;
        min-width: 24px;
        padding: 0px 5px;
        font-size: var(--font-size);
        transition: all 0.15s linear;
        border: 1px solid transparent;

        &:hover {
            color: var(--secondary);
            background-color: var(--e4);
        }

        &.active {
            color: var(--light-blue);
            background-color: var(--f5);
        }

        .text {
            font-size: var(--font-size-small);
            color: var(--medium-grey);
            margin-left: 3px;

            @media ( max-width: ( $portrait - 1px )) {
                display: none;
            }
        }
    }

    .opt {
        color: var(--primary);
        border-radius: 100%;
        background-color: var(--f5);
    }

    .view-opt {
        color: var(--soft-grey);
        border-radius: 3px;
    }
}

.show-extra [data-toggle-extra] {
    color: var(--light-blue);
    background-color: var(--f5);
    border-color: var(--e4);
}
