//
//  Tweaks for home
//

// Live streaming ( home / meeting )
#uitzending, #uitzending_meeting {
    .next, #live_meta {
        width: 100%;
        float: none;
        margin: 0px;
        height: auto;
        margin-bottom: 30px;
        background-color: $f5;
    }

    #next_live ul.meta {
        border-bottom: 0px;
    }

    #next_meeting { position: relative; }
}

#uitzending_meeting {
    #live_meta {
        position: relative;
    }
}


// Fix meeting groups
.orgaan.block {
    .listextra {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin: 0px;
    }
}
