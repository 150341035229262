//
//  Basic layout of a profile
//

.profile-page {
    .page-head h1 { opacity: 0; }
    .message { margin: 30px 0px  50px 0px; }
}

// Column layout
.profile-columns {
    @media ( min-width: $mobileLandscape ) and ( max-width: ( $portrait - 1px )) {
        .profile-card {
            padding-left: 160px;

            .thumb {
                position: absolute;
                left: 0px;
            }
        }
    }

    @media ( min-width: $portrait ) and ( max-width: ( $landscape - 1px )) {
        display: flex;
        flex-flow: row nowrap;

        .profile-content { flex: 1 1 auto; }

        .profile-card {
            flex: 0 0 220px;
            margin-right: 40px;
        }
    }

    .message { margin-bottom: 40px; }
}
