//
//  Dashboard widget annotations
//

.dashboard-annotations-widget {
    .fa-share-alt { color: $blue; }

    .dashboard-widget-item {
        .status { flex: 0 0 24px; }
    }

    .meta {
        font-size: var(--font-size-small);
        padding: 0px;

        .icn { color: $softGrey; }
    }
}
