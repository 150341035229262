//
//  Meeting
//

.vergadering {

    // Hide page actions on mobile
    @media ( max-width: ( $portrait - 1px )) {
        #pageHead .actions {
            display: none;
        }

        #pageHead .meta {
            padding-right: 0px;
            border: 0px;
        }
    }

    // Smaller page actions and hide print on tablet
    @media ( min-width: $portrait ) and ( max-width: ( $desktop - 1px )) {
        #pageHead .actions {
            flex-basis: 230px;
            font-size: var(--font-size-small);

            a, div { font-size: inherit; }
            .print_pagina:not(.download_documenten) { display: none; }
        }
    }

    // Hide notelinks (raadmobiel)
    @media ( max-width: ( $landscape - 1px ) ){
        .notelink { display: none!important; }
    }
}

// Add border to general documents rows
@media ( max-width: ( $landscape - 1px )) {
    .general-documents li { border-top: 1px solid $e4; }
    #documenten.general-documents { border-bottom: 0px; }
}


// Hide reactions
@media ( max-width: ( $landscape - 1px ) ) {
    .showComments { display: none; }
    .attachementRow .comments { display: none; }
}

// Archive streaming in meeting | agenda item row
@media ( max-width: ( $landscape - 1px ) ) {
    .agendaRow {
        .speakerfragments {
            font-size: var(--font-size-small);

            * { font-size: inherit }
            .speaking { display: none!important; }
        }

        .FragmentSpecificButtons { display: none; }
    }
}

@media ( max-width: ( $portrait - 1px ) ) {
    .agendaRow {
        .speakerfragments { display: none; }
    }

    .agendaRowWrap {
        display: flex;
        flex-flow: row nowrap;

        .first, .last { flex: 0 0 auto; }
        .title { flex: 1 1 auto; }
        .last { padding-left: 0px; }
    }
}


// Hide votings buttons
@media ( max-width: ( $landscape - 1px ) ) {
    .toggle-votings-button { display: none; }
}

@media ( max-width: ( $portrait - 1px ) ) {
    #stemmingen, .agenda-item-votes, .agenda-items-votes-title { display: none; }
}
