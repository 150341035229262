//
//  CSS Variables for search
//

& {

    // Size of left column elements
    --gos-results-size: 650px;

    // Size sidebar overlay
    --gos-sidebar-size: 550px;
    --gos-sidebar-size-max: 85vw;
    --gos-sidebar-bg: rgba( 0, 0, 0, 0.85 );

    // Line height of the preview
    --gos-result-line-height: 1.6em;

    // Amount of lines of the preview
    --gos-result-line-amount: 2;
}
