//
//  Involved row
//
.involved-holder {

    // Align the persons and the buttons side-by-side from mobile landscape
    @media ( min-width: $mobileLandscape ) {
        display: flex;
        flex-flow: row nowrap;
        gap: 60px;
    }

    // Set grid fro persons
    .person-grid {
        flex: 1 1 auto;
        display: grid;
        grid-template-columns: repeat( auto-fit, 240px );
        grid-gap: 10px;
    }

    // Set size of subscribe buttons
    .subscribe-options {
        flex: 0 0 240px;
        text-align: right;

        .button {
            width: 100%;
            text-align: left;
            margin-bottom: 10px;
        }
    }

    // On smaller than landscape, reduce the gap and size of the subscribe buttons
    @media ( max-width: ($landscape - 1px )) {
        gap: 20px;

        .subscribe-options { flex: 0 0 210px; }
    }

    // On smaller than portait, set the thumb and name in a column and center the columns
    @media ( max-width: ($portrait - 1px )) {
        .person-grid {
            grid-template-columns: repeat( auto-fit, 180px );
            justify-content: center;
        }

        .dossier-person {
            display: block;
            text-align: center;
        }
    }

    // Set the subscribe buttons side-by-side on larger mobile portrait screens and smaller than the mobile landscape
    @media ( min-width: 470px) and  ( max-width: ($mobileLandscape - 1px )) {
        .subscribe-options {
            margin-top: 30px;
            display: flex;
            gap: 20px;

            .button { flex: 1 1 50%; }
        }
    }
}
