//
//  Meeting list
//  - list for showing next/previous meetings
//

:root {
    --bar-heading: #696E74;
    --bar-heading-txt: var(--white);
    --bar-heading-img: url("../images/meetings_previous.png");
}

.meetings {
    p, ul { min-height: 40px; }
    p { padding: 0px 10px; }
    
    .list li {
        a {
            color: var(--alt-grey);
            font-style: italic;
        }
        
        .date {
            color: #222;
            font-style: normal;
        }

        .tag {
            margin-left: 5px;
        }
    }
}

@media print {
    .meetings h3, .meetings h3:after, #next.meetings h3:after, #next.meetings h3 {
        background: none;
        height: auto;
        padding: 0px;
        text-indent: 0px;
        color: #444;
    }

    .meetings h3 {
        border-bottom: 0.5mm solid #ccc;
        font-weight: bold;
    }
}

// Meeting heading with image
.bar-heading {
    background: var(--bar-heading);
    color: var(--bar-heading-txt);
    font-weight: bold;
    font-size: var(--font-size-large);
    line-height: 40px;
    text-indent: 15px;

    &:after {
        background: var(--white) var(--bar-heading-img) no-repeat center bottom;
        background-size: cover;
        content: " ";
        display: block;
        height: 80px;
        padding-top: 30px;
        width: 100%;
    }

    &.upcoming {
        --bar-heading-img: url("../images/meetings_next.png");
        --bar-heading: var(--primary);
    }
}
