//
//  term-agenda Header
//  - header / legend above the term-agenda widget
//
// Header / Legend above the overview
.term-agenda-overview-head {
    background-color: var(--lta-bg);
    border-bottom: 1px solid var(--lta-border);

    .legend-before {
        display: flex;
        width: var(--lta-left-col);
        flex: 0 0  var(--lta-left-col);
        position: sticky;
        left: 0px;
        background-color: var(--lta-bg);
        border-right: 1px solid var(--lta-border);
        z-index: 1;
        align-items: center;
        padding: 0px 20px;
        color: var(--dark-grey);

        button {
            border-top: 0px;
            font-size: var(--lta-font-larger);
            line-height: 1.2;
            font-weight: bold;
            cursor: pointer;
            margin: 0px;
            text-align: center;

            .icn { color: var(--alt-grey); }
        }

        .period {
            font-weight: bold;
            margin-right: 10px;
            flex: 1 1 auto;
        }
    }

    .quarter {
        display: block;
        flex: 0 0 auto;
        font-size: var(--lta-font-small);
        padding: 5px 0px;
        line-height: 1.4;
        position: relative;

        // Set border as after to prevent issues with zooming in
        &:after {
            content: " ";
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: -1px;
            width: 1px;
            border-left: 1px solid var(--lta-border);
        }
    }

    .month {
        display: inline-block;
        width: var(--lta-month-size);
        font-weight: bold;
        font-size: var(--lta-font-larger);
        padding-left: 20px;
    }

    .quarter-name {
        display: block;
        color: var(--alt-grey);
        font-size: var(--lta-font-tiny);
        padding-left: 20px;
    }
}
