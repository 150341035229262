//
//  Download media
//  - styling for download-media element
//  - element contains the download options for the full meeting as audio or video
//
.download-meeting-media {

    ul {
        list-style: none;
        margin-bottom: 10px;
    }

    .subtitle { font-size: var(--font-size-small); }

    h3 {
        margin-bottom: 0px;
        font-size: var(--font-size-large);
    }

    .download-link {
        max-width: 800px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border-radius: 3px;
        padding: 5px 10px;

        &:hover { background-color: var(--f5); }
        .name { flex: 1 1 auto; }
        .fa-download { color: var(--blue); }
        .fa-volume-up { color: var(--red); }
        .fa-film { color: var(--secondary); }

        .file-info {
            font-size: var(--font-size-small);
            color: var(--medium-grey);
        }

        .download-name {
            flex: 1 1 auto;

            @media ( min-width: $mobileLandscape ) {
                display: flex;

                .name { flex: 1 1 auto; }
                .file-info { flex: 0 0 160px; }
            }
        }

        @media ( max-width: ( $mobileLandscape - 1px )) {
            align-items: flex-start;
            .download-txt { display: none; }
            .file-info { margin-top: -2px; }
            .icn { margin-top: 5px; }
        }
    }
}
