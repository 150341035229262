//
//  Expanded menu
//  - mobile navigation showing menu in a curtain falling overlay
//

.expanded-menu {
    position: fixed;
    box-sizing: border-box;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba( $white, 0.95 );
    outline: 1px solid #a0afb0;
    z-index: 8;
    transform: translateY( -100% );
    transition: all 0.3s linear;
    overflow: hidden;
    overflow-y: auto;
    
    .container {
        min-width: auto;
        position: relative;
    }
    
    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
        min-width: 300px;
    }
    
    .skip { top: 80px; }
    
    .menu-holder {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        height: 100vh;
        
        a {
            display: block;
            font-size: var(--font-size-large);
            font-weight: bold;
            line-height: 20px;
            border-bottom: 1px solid #e4e4e4;
            padding: 10px 35px 10px 15px;
            text-decoration: none;
            position: relative;
            color: getVar( --primary, $primary);
            transition: all 0.15s linear;
    
            &:hover {
                background-color: $f5;
                color: #d25c0a;
            }
        }
    }
    
    .close-menu {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        color: inherit;
        font-size: var(--font-size-small);
        
        * { pointer-events: none; }
        
        .label {
            display: inline-block;
            float: left;
            line-height: 30px;
            margin-bottom: 0;
            padding-right: 10px;
            width: auto;
        }
        
        .icn {
            display: inline-block;
            text-align: center;
            font-size: 24px;
            color: $white;
            background-color: getVar( --primary, $primary);
            float: right;
            width: 30px;
            height: 30px;
            line-height: 30px;
        }
        
        &:hover {
            .label { text-decoration: underline; }
            .icn { opacity: 0.8; }
        }
    }
}

// CSS for opened panel (based on class on #page element)
body:has(.expanded-menu-open) {
    overflow: hidden;
    height: 100vh;
    
    .expanded-menu { transform: translateY( 0px ); }
}

@media ( min-width: $mobileLandscape ) and ( max-height: ( 420px )) {
    .expanded-menu {
        &:before {
            display: block;
            content: " ";
            width: 100%;
        }

        ul {
            display: grid;
            grid-template-columns: repeat(2, auto);
            justify-content: start;

            li {
                margin-left: 1em;
            }
        }
    }
}

