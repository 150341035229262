//
//  Member grid
//
.members-grid {
    display: grid;
    grid-template-columns: repeat( auto-fit, 150px );
    gap: 30px 10px;

    .member {
        text-align: center;
        border-radius: 5px;
        color: inherit;
        text-decoration: none;
        display: block;
        hyphens: auto;

        &, * { transition: all 0.15s linear; }

        &:hover, &:focus {
            background-color: $f5;

            .thumb {
                transform: scale( 1.1 );
                box-shadow: 0px 3px 3px rgba( 0, 0, 0, 0.15 );
            }
        }
    }

    .name, .role, .start, .end {
        font-size: var(--font-size);
        font-weight: normal;
        display: block;
        line-height: 1.4;
        margin: 10px 0px 5px 0px;
    }

    .role { font-size: var(--font-size-small); }

    .thumb {
        max-width: 120px;
        transform-origin: bottom center;
        margin-bottom: 5px
    }
}
