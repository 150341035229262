//
//  Youtube widget
//  - used in employee profile
//
$colorYoutube: #d32421;

.youtube-widget {
    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
    }

    .fa-youtube { color: $colorYoutube; }

    .video {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        color: inherit;
        text-decoration: none;
        padding: 5px;
        margin-bottom: 5px;
        transition: all 0.15s linear;
    }

    .thumb {
        flex: 0 0 100px;
        position: relative;
        margin-right: 20px;

        @media  ( min-width: $landscape ) and ( max-width: ($desktop - 1px )),
                ( min-width: $mobileLandscape ) and ( max-width: ( $portrait - 1px )) {
            flex-basis: 90px;
        }

        .time, .icn {
            position: absolute;
            color: $white;
        }

        .icn {
            top: 50%;
            left: 50%;
            margin: -10px 0px 0px -10px;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-shadow: 0px 0px 3px rgba( 0, 0, 0, 0.3 );
            font-size: var(--font-size-larger);
        }

        .time {
            background-color: $black;
            bottom: 0px;
            right: 0px;
            padding: 3px;
            font-weight: bold;
            font-size: var(--font-size-small);
        }
    }

    .video-info {
        flex: 1 1 auto;
        align-self: center;

        .title {
            font-weight: bold;
            color: $darkGrey;
            display: block;
            transition: all 0.15s linear;
            line-height: 1.4;

            @media ( min-width: $desktop ) {
                font-size: var(--font-size-large);
            }
        }

        .author, .date {
            color: $mediumGrey;
            display: inline-block;
            margin-right: 10px;
            font-size: var(--font-size-small);
        }
    }

    li:first-of-type .video {
        display: block;
        border-bottom: 1px solid $e4;
        margin-bottom: 20px;

        .video-info {
            margin: 20px 0px;
            align-self: flex-start;
        }
    }

    .video:hover {
        background-color: $f5;

        .title { color: $secondary; }
    }
}
