//
//  Meetings
//  - overview of meetings and groups ( on /vergaderingen)
//

.vergaderingen {
	--_group-border: var(--e4);

	.groups {
		padding: 0px;
		background: none;
	}

	.orgaan {
		background: var(--white);
		border: 1px solid var(--_group-border);
		box-sizing: border-box;
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;

		h3 {
			padding: 10px;
			text-align: center;
			font-size: var(--font-size-large);
			min-height: 66px;
			margin-bottom: 0px;
			flex: 1 1 auto;
		}

		.listextra {
			background: var(--e4);
			padding: 0px;
			margin-bottom: 0px;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			li {
				display: block;
				padding: 0px;

				&:before { border: 0px; }
			}

			a {
				display: block;
				float: left;
				padding: 5px 10px;
				background: var(--e4);
			}

			.next a {
				background-color: var(--f5);
				padding-left: 10px;
				padding-right: 20px;
				min-width: 80px;
				color: var(--link-color);
			}
		}
	}

	// Print view
	@media print {
		.orgaan { display: none; }
	}
}

// Extra styling for high-contrast theme
.high-contrast {
	.vergaderingen {
		--_group-border: var(--black);
	}
}



