//
//  Sessionbar vertical
//  - restyles horizontal tabs to a vertical tabbar
//

.session-bar {
    background-color: $f5;
    border: 1px solid $border;

    .block-header {
        display: flex;
        text-transform: capitalize;
        border: 0px;
        border-bottom: 1px solid $border;
    }

    .session-tabs {
        display: block;

        li {
            margin: 0px;
        }
    }

    .session-tab {
        padding: 15px 20px;
        border: 0px;
        border-bottom: 1px solid $border;
        background-color: transparent;

        .arrow { display: none; }

        &:hover {
            background-color: $white;
            border-right: 5px solid $hover-blue;
        }

        &:after {
            opacity: 0;
        }

        &.active {
            background-color: $white;
            border-right: 5px solid $primary;
        }

        .session-info {
            span {
                max-width: 100%;
                font-size: var(--font-size-small);
            }

            .title {
                font-size: var(--font-size-large);
                margin-bottom: 3px;
            }
        }
    }
}
