//
//  Quicksearch element on dashboard
//  - used mainly above content overviews
//
.dashboard-quicksearch {
    input {
        padding: 7px 7px 7px 37px;
        line-height: 28px;
        border-color: var(--f5);
        background-color: var(--f5);
        border-radius: 4px;

        &:focus { background-color: var(--white); }
    }

    .icn {
        left: 0px;
        top: 2px;
        right: auto;
        color: var(--soft-grey);
        width: 40px;
        line-height: 40px;
        height: 40px;
    }
}
