//
//  Mandate
//  - modal for selecting a user to mandate, confirm the start and confirm the stop
//

.modal-mandate {

    // Hide elements based on view class
    --_list: block;
    --_start: none;
    --_stop: none;
    --_loading: none;

    .imp-users { display: var(--_list); }
    .imp-confirm-start { display: var(--_start); }
    .imp-confirm-stop { display: var(--_stop); }

    &.confirm-select {
        --_list: none;
        --_start: block;
    }

    &.confirm-stop {
        --_list: none;
        --_stop: block;
    }

    // Show loading-placeholder when is-loading
    &.is-loading {
        --_list: none;
        --_start: none;
        --_stop: none;
        --_loading: block;
    }

    // Style elements in modal
    li { border-top: 1px solid var(--e4); }
    strong:not(.user-name) { color: var(--alt-grey); display: block; }

    .filtered { display: none; }

    .btn-holder {
        margin-top: 50px;
        text-align: center;
    }

    .selected-user {
        border-top: 1px solid var(--e4);
        border-bottom: 1px solid var(--e4);
        margin-bottom: 20px;
        padding: 10px;

        .fa-chevron-right { display: none; }
    }

    .imp-user button, .selected-user {
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
        align-items: center;
        text-align: left;
        gap: 10px;

        .imp-user-info { flex: 1 1 auto; }

        .name {
            font-weight: bold;
            display: block;
            color: var(--dark-grey);
        }

        .email {
            font-size: var(--font-size-small);
            color: var(--alt-grey);
        }
    }

    .loading-placeholder {
        display: var(--_loading);
        min-height: 200px;

        .loading-txt {
            position: absolute;
            inset: 50% auto auto 50%;
            transform: translate(-50%, -50%);
        }

        .icn {
            font-size: 24px;
            color: var(--soft-grey);
        }
    }
}
