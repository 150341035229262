//
//  Votings
//  - styling for voting elements in agenda
//

ul.votings-list {
    margin: 0px;
    padding: 0px;

    .item-row {
        padding: 5px;

        :not(button) { pointer-events: none; }

        .row-title {
            flex: 1 1 auto;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        .row-icn { margin-top: 0px; }

        .vote-status {
            flex: 0 0 160px;
            margin-right: 10px;

            @media( max-width: ( $mobileLandscape - 1px )) {
                flex-basis: auto;

                .label { display: none; }
            }
        }
    }
}
