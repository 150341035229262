//
//  Upcoming / Last meetings per group
//  - this is the old view/css, new CSS is in: html/site/default2020/css/sass/views/dmu-meetings.scss
//
.komendevergadering, .archiefvergadering {
    display: flex;
    flex-flow: row nowrap;
    background-color: $white;
    margin-bottom: 10px;
    max-width: 600px;
    align-items: center;
    text-decoration: none;
    box-shadow: 0px 0px 3px rgba( 0, 0, 0, 0.1 );
    transition: all 0.15s linear;
    border: 1px solid $softGrey;

    &:hover {
        color: $white;
        background-color: $focus-outline-color;
    }

    .date { flex: 0 0 23ch; }
    .time { flex: 0 0 15ch; }
    .tags { flex: 1 1 auto; }

    .date, .time {
        padding: 5px 5px 5px 12px;
        font-style: normal;
        color: inherit;
    }

    &.has-group {
        .group { flex: 1 1 auto; }
        .time { flex: 0 0 auto; }
    }

    .pointer {
        flex: 0 0 32px;
        text-align: center;
        align-self: center;
    }

    @media ( max-width: ( $mobileLandscape - 1px )) {
        .time {
            font-size: var(--font-size-small);
            flex-basis: 80px;
        }
    }
}

.komendevergadering:last-of-type {
    margin-bottom: 40px;
}

.archiefvergaderingen {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
