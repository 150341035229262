//
//  Account / Profile dropdown
//  - contains the styling of the elements in the header account/profile dropdown
//  - this dropdown contians the login options or the options of the logged in account
//  - dropdown depends on hover-box (v1) component
//  - it has a light and dark theme based on attached header, default is light
//
$account-breakpoint: $portrait;

.my-account {

    // Generic elements
    .button {
        display: block;
        text-align: center;
    }

    .full-button {
        font-weight: bold;
        text-transform: uppercase;
    }

    .fold-out {
        padding: 0px;
        min-height: 290px;

        @media ( min-width: $account-breakpoint ) {
            width: 400px;
        }
    }
}

.account-options, .account-actions {
    padding: 20px;

    @media ( min-width: $account-breakpoint ) {
        padding: 35px;
    }
}

.account-card {
    text-align: center;
    padding: 35px 10px 30px 10px;
    color: inherit;
    position: relative;
    transition: all 0.3s linear;
    background-image: linear-gradient( -45deg, rgba( $black, 0.2 ), rgba( $white, 0.2 ));

    .name, .account { display: block; }
    .name { font-size: var(--font-size-larger); }
    .account {
        font-size: var(--font-size-small);
        margin-top: 5px;
    }

    .account-info {
        margin-top: 20px;
        line-height: 1;
    }

    .button {
        margin-top: 20px;
        display: inline-block;
    }

    .clean-button {
        background-color: $white;
        color: inherit;

        &:hover {
            background-color: rgba( $black, 0.05 );
            border-color: transparent;
        }
    }

    @media ( min-width: $account-breakpoint ) {
        display: flex;
        flex-flow: row nowrap;
        text-align: left;
        padding: 35px 24px 30px 25px;

        .profile-icon { margin-right: 20px; }
        .clean-button { float: right; }

        .account-info {
            margin-top: 10px;
            flex: 1 1 auto;
        }

        .no-account {
            align-self: center;
            margin-top: 0px;
        }
    }
}

.account-options {
    color: inherit;
    padding-bottom: 10px;

    h3 {
        font-size: var(--font-size);
        font-weight: bold;
        color: inherit;
        text-transform: initial;
    }
}

.account-menu {
    border-top: 1px solid $softGrey;
    border-bottom: 1px solid $softGrey;

    a {
        display: block;
        color: inherit;
        font-size: var(--font-size-small);
        padding: 7px 24px 7px 12px;
        transition: all 0.15s linear;
        position: relative;
        line-height: 20px;

        &:hover { background-color: $f5; }

        .icn-right {
            position: absolute;
            right: 5px;
            top: 7px;
            width: 24px;
            text-align: center;
            line-height: 20px;
            color: var(--account-icn-color, var(--primary) );
        }
    }
}

// Toolbar containing login, logout and forgot-password links
.account-actions {
    text-align: center;

    @media ( min-width: $account-breakpoint ) {
        display: flex;
        flex-flow: row-reverse nowrap;
        align-items: center;
        justify-content: space-between;
    }

    .forget-password {
        display: block;
        margin-top: 20px;
        font-size: var(--font-size-small);
        color: inherit;
        text-decoration: none;

        &:hover { text-decoration: underline; }

        @media ( min-width: $account-breakpoint ) {
            margin-top: 0px;
        }
    }
}

// Dark color scheme
.main-header {
    .fold-out {
        background-color: $header-background;
        border-color: $header-background;

        &:before { border-color: transparent; }

        .clean-button {
            background-color: $header-background;

            .icn { color: inherit; }

            &:hover {
                background-color: rgba( $black, 0.5 );
                border-color: transparent;
            }
        }

        .account-menu {
            a:hover { background-color: rgba( 255, 255, 255, 0.1 ); }
        }
    }

    .my-account:hover .opt, .my-account.active .opt { background-color: rgba( 255, 255, 255, 0.1 ); }
    .my-account:focus-within .opt { background-color: rgba( 255, 255, 255, 0.1 ); }

    .left-aligned .fold-out:before { left: 16px; }
    .right-aligned .fold-out:before { right: 16px; }
}
