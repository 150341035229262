//
//  Recent dossiers
//  - styling for the row with recent dossiers
//

.dossiers-page {
    .dossiercards-section {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(260px, 1fr));
        grid-gap: 24px;

        .dossiercard {
            height: 140px;
            padding: 15px;
            background: var(--white);
            box-shadow: 0 0 15px rgba(0, 0, 0, .08);
            text-decoration: none;
            color: inherit;
            transition: all 0.15s linear;
            transform-origin: center;
            display: grid;
            grid-template-columns:
                [start] calc( 100% - 70px )
                [profile] 50px
                [orb] 10px;
            grid-template-rows: auto min-content;
            grid-gap: 5px;

            &:hover {
                transform: scale(1.05);
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

                .dossiercard-title { color: var(--focus-color); }
            }

            .color-orb { margin: 0px; }
            .dossiercard-information { grid-column: start/orb; }

            .dossiercard-profile {
                grid-column: profile / span 2;
                text-align: right;
            }

            .dossiercard-title {
                font-weight: bold;
                overflow-wrap: break-word;
                line-height: normal;
                transition: all 0.15s linear;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow-wrap: break-word;
            }

            .dossiercard-domain, .dossiercard-details {
                font-size: 0.9rem;
                color: var(--alt-grey);
            }
        }
    }
}
