.visual-holder {
    width: 100%;
    position:relative;
	overflow:hidden;
	z-index:3;
	margin: 0px;

    @media ( min-width: $landscape ) {
        float: left;
        width: calc( 66.666666% - 20px );
        border-bottom: 3px solid #e4e4e4;
        height: 360px;
    }

    @media ( min-width: $desktop ) {
        height: 441px;
    }

    @media ( max-width: ( $landscape - 1px )){
        &:before {
            content: " ";
            display: block;
            padding-top: 56.25%;
        }
    }

    img {
        object-fit: cover;
        object-position: top left;
    }

    .visual {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }

    @media print {
	    display: none;
    }
}

.visual {
    background:url("../images/home_visual.jpg") no-repeat left top;
	background-size: cover;
}

// Fix IE11
.is-ie {
    img.visual { height: auto; }
}
