//
//  Double menu
//  - variant showing an extra menu before/above the site menu
//  - extends / overwrite the style of the basic header
//

.menu-before-holder {
    @media ( max-width: ( $portrait - 1px )){
        display: none;
    }

    ul {
        display: flex;
        flex-flow: row nowrap;
        margin: 0px;
    }
    
    a {
        display: block;
        padding: 12px 20px;
        font-size: var(--font-siz-large);
        line-height: 30px;
        cursor: pointer;
        text-decoration: none;
        transition: 0.2s linear all;
        border: 1px solid $e4;
        background-color: $f5;
        position: relative;
    
        &:hover { background-color: $white; }
    
        &.active {
            background-color: $white;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    
            &:after {
                content: " ";
                background-color: inherit;
                display: block;
                bottom: -2px;
                height: 6px;
                left: 0px;
                right: 0px;
                position: absolute;
                z-index: 4;
            }
        }
    }
    
    .rounded a { border-radius: 12px 12px 0px 0px; }
}

.header-basic  .double-mobile-header { display: none; }

.double-menu .menu-holder .menu-ul-holder {
    background-color: $white;
    
    a:hover { background-color: $f5; }
}
