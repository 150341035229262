//
//  Styling for the domain overview on smaller screens / mobile devices
//

// Hide unwanted elements
.term-agenda-overview-head,
.month-grid,
.duration-bar,
.term-agenda-holder .overlays {
    display: none;
}

// Hide header elements and show quicksearch full width
.term-agenda-header {
    margin-bottom: 20px;

    .center, .right, .filter-menu { display: none; }
    .left {
        flex-basis: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 5px;

        .quicksearch { flex: 1 1 auto; }
        .button { margin: 0px; }
    }
}

.term-agenda-row {
    --lta-mobile-offset: 10px;
    padding: 40px var(--lta-mobile-offset) var(--lta-mobile-offset) var(--lta-mobile-offset);

    @media (min-width: $mobileSmall){
        --lta-mobile-offset: 20px;
    }

    @media (min-width: $mobileLandscape){
        --lta-mobile-offset: 30px;
    }
}

.main-button {
    &:hover, &:focus-visible {
        border-color: var(--focus-color);
        background-color: var(--focus-color);
        color: var(--white);

        .dossier-status { color: inherit; }
    }
}

.domain-button {
    --lta-orb-size: 15px;
    background-color: transparent;
    padding: 15px;
    border: 0px;
    font: inherit;
    width: 100%;
    text-align: left;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    transition: all 0.15s linear;

    &:before {
        content: " ";
        flex: 0 0 var(--lta-orb-size);
        height: var(--lta-orb-size);
        border-radius: 100%;
        background-color: var(--dr-btn-left);
        border: 1px solid var(--white);
    }

    * { pointer-events: none; }

    .name {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.dossier-button {
    display: block;
    padding: 15px;
    background-color: var(--white);
    border-radius: 5px;
    border: 1px solid var(--soft-grey);
    text-decoration: none;
    color: inherit;
    min-height: 100%;
    word-wrap: break-word;

    .dossier-status {
        display: block;
        color: var(--alt-grey);
        font-size: var(--font-size-small);
    }
}

.term-agenda-overview {
    .dossiers {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat( auto-fit, minmax( 160px, 1fr ));
        padding: 10px;
        background-color: var(--f5);
        border-top: 1px solid var(--soft-grey);
    }

    .domain {
        border: 1px solid var(--soft-grey);
        margin-bottom: 10px;
        border-radius: 5px;
    }

    :not(.active) .dossiers { display: none; }

    // Hide searched elements
    .domain, .dossier-row {
        &.filtered { display: none; }
    }
}
