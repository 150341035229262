//
//  Meeting Tools overview
//  - styling for the meeting tools overview
//
.mt-list-overview {
    --mt-icn-size: 32px;
    --mt-icn-margin: 10px;
    --mt-pointer-size: 32px;
    --mt-row-padding: 15px;
    position: relative;
    color: var(--dark-grey);

    ul { list-style: none; }
    section { position: relative; }

    // Toggleable description
    &:not(.show-description) .mt-description { display: none; }

    // Toggleable no-content message
    &:not(.show-no-content) {
        .no-content { display: none }
    }

    &.show-no-content .pagination-holder { display: none; }
    .pagination-holder { margin-top: 40px; }

    // Toggle loading placeholder
    .loading-placeholder {
        position: absolute;
        background-color: var(--white);
        text-align: center;
        top: 30px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all 0.15s linear 0.15s;

        .loading-txt {
            position: absolute;
            top: 40%;
            left: 0px;
            width: 100%;
        }
    }

    &:not(.is-loading) .loading-placeholder {
        pointer-events: none;
        opacity: 0;
    }


    @media ( min-width: $mobileLandscape ) {
        .show-mobile { display: none; }
    }

    @media ( max-width: ($mobileLandscape - 1px ) ) {
        .hide-mobile { display: none; }
    }

}

.mt-list-head, .meeting-tool-row a {
    display: flex;
    flex-flow: row nowrap;

    .date { flex: 0 0 100px; }

    .open-trigger {
        flex: 0 0 32px;
        text-align: center;
        color: var(--primary);
        margin-right: -10px;
    }

    .tool-info {
        flex: 1 1 auto;
        margin-right: 20px;
    }
}

.mt-list-head {
    font-weight: bold;
    align-items: flex-end;
    padding-right: var(--mt-row-padding);
    border-bottom: 1px solid $e4;
}

// Styling for the row
.meeting-tool-row {
    --mt-status-bg: transparent;
    background-color: var(--mt-status-bg);
    border-bottom: 1px solid var(--e4);
    transition: all 0.15s linear;

    a {
        align-items: flex-start;
        padding: var(--mt-row-padding);

        &:hover {
            background-color: var(--f5);
        }
    }

    .tool-name { font-weight: bold; }
    .tag { font-weight: normal; }
    .date, .open-trigger { align-self: center; }
    .date { font-weight: bold; }

    .number {
        display: block;
        color: var(--alt-grey);
        line-height: 1.1;
        font-size: var(--font-size-small);
        margin-bottom: 10px;
    }

    .tool-icn {
        flex: 0 0 32px;
        text-align: center;
        position: relative;
        margin-top: 7px;
    }

    .mt-icn {
        --icn-color: var(--dark-grey);
        --icn-display: none;
        position: relative;
        text-align: center;
        font-size: calcEmFontSize(22pt);
        align-self: center;
        line-height: 1;

        .icn {
            color: var(--icn-color);
        }

        .fa-paper-plane {
            font-size: 0.31em;
            position: absolute;
            top: 50%;
            width: 100%;
            left: 0px;
        }

        .fa-lock {
            position: absolute;
            bottom: -5px;
            right: -5px;
            color: var(--orange);
            font-size: 0.63em;
            display: var(--icn-display);
        }

        &.confidential {
            --icn-color: var(--light-grey);
            --icn-display: block;
        }
    }

    .meta-lint {
        display: block;
        margin: 0px;
        font-size: var(--font-size-small);

        .fa-paper-plane { color: var(--blue); }
    }

    .mt-description {
        margin: 10px var(--mt-pointer-size) 20px calc(var(--mt-pointer-size) + var(--mt-icn-margin));
        font-size: var(--font-size-small);
        background-color: var(--f5);
        border-radius: 5px;
        padding: 10px 15px;
        border: 1px solid var(--f5);

        p:last-child { margin: 0px; }
    }

    // Status colors
    &.done {
        --mt-status-bg: transparent;

        .due {
            text-decoration: none;

            span {
                color: var(--soft-grey);
                position: relative;

                &:after {
                    content: " ";
                    border-top: 2px solid currentColor;
                    position: absolute;
                    inset: 50% 0px 50% 0px;
                }
            }
        }
    }

    &.due {
        --mt-status-bg: transparent;
    }

    &.overdue {
        --mt-status-bg: var(--back-red);
    }

    &.soon-due {
        --mt-status-bg: var(--back-yellow);
    }

    // Overwrite when row has no-notification-bg class, when meeting tool type has colors disabled
    &.no-notification-bg {
        --mt-status-bg: transparent;
    }

    &.archived {
        .mt-icn {
            --icn-color: var(--light-grey);
        }
    }
}
