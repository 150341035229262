//
//  Footer
//

.main-footer {
    --_footer-dark: color-mix( in srgb, var(--footer-bg) 92%, black );
    position: relative;
    background-color: var(--footer-bg);
    color: var( --footer-color );

    * { color: inherit; }

    h3 {
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    a:not(.button) {
        text-decoration: none;
        color: inherit;

        &:hover, &:focus {
            text-decoration: underline;
            color: inherit;
        }
    }

    .row {
        padding: 30px 0 70px 0;

        @media ( min-width: $portrait ) {
            padding-top: 50px;
        }
    }

    .logo {
        background-position: top center;
    }

    .buttons {
        clear: both;
        width: 200px;
        padding-top: 20px;
    }

    .clean-button {
        border: 1px solid currentColor;
        color: inherit;
        display: block;
        margin: 5px 5px 10px 5px;
        padding-left: 0px;
        padding-right: 0px;

        &:hover, &:focus {
            border-color: lighten($white, 30%);
            background-color: var(--_footer-dark);
        }
    }

    .social {
        .links a {
            display: block;
            float: left;
            border: 1px solid transparent;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 5px;
            transition: all .2s ease;
            margin: 5px;

            &:hover { border-color: currentColor; }
            .icn { font-size: var(--font-size-larger); }
        }
    }
}

// Copy bar
.copy {
    background-color: var( --copybar-bg, var(--_footer-dark) );
    color: var( --copybar-txt );
    clear: both;
    font-size: var(--font-size-smaller);
    margin-top: 24px;

    .container { padding: 15px 0px; }
    .right { float:right; }
    .left { display: inline; }
}
