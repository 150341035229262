//
//  Search
//  - styling for the searchpage
//

$radio-border: $input-border;
$radio-active: #488325;
$radio-size: 20px;

@import "info";

.gos {
    @import "_css-variables";
    @import "base";
    @import "containers";

    // Search form and filters
    @import "radio";
    @import "form";
    @import "tabs";
    @import "filters";
    @import "amount-and-tools";
    @import "select-period";

    // Results
    @import "results";
    @import "pages";
}

// RIS Hacks & fixes
@import "fixes";
