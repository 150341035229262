//
//  Row inherit
//  - collection of styling to extend the existing color classes and make the elements in these classes selectively inherit the colors
//  - for now, this CMS Raad2021 specific
//  - contains styling for image-rows, grey-shade backgrounds
//

$extraColors : (
    'light-grey'	( 'f5' ),
    'medium-grey'	( 'medium-grey' ),
    'grey'			( 'light-grey' ),
    'dark-grey'		( 'dark-grey' ),
    'image'		    ( 'dark-grey' ),
    'go'			( 'orange' ),
    'black'			( 'black' )
);

// For rows (mostly widgets)
.row {
    @each $color in $extraColors {
        $name: nth($color, 1 );
        $value: nth($color, 2 );

        &.#{$name} {
            --row-bg: var(#{"--"+$value});
        }
    }

    // Background images
    &.image {
        &.darker:before,
        &.lighter:before {
            content: " ";
            display: block;
            z-index: 0;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
        }

        &.darker:before { background-color: rgba( 0, 0, 0, 0.5 ); }
        &.lighter:before { background-color: rgba( 255, 255, 255, 0.5 ); }
    }
}

//
//	Inherit background setting for rows
//
.inherit-bg {
    background-color: inherit;

    svg & {
        fill: $white;
    }
}

// Loop through colors for SVG fill
@each $color in $colors {
    $name: nth($color, 1 );
    $value: nth($color, 2 );

    // Background classes
    .#{""+$name} svg .inherit-bg {
        fill: getVar( #{"--"+$name}, nth($value , 1));
    }
}

// Extra shades
.white {
    svg & { fill: $white; }
}

.lightGrey {
    svg & { fill: $f5; }
}

.mediumGrey {
    svg .inherit-bg { fill: $mediumGrey; }
}

.grey {
    svg .inherit-bg { fill: $lightGrey; }
}

.go {
    svg .inherit-bg { fill: $orange; }
}

.darkGrey, .row.image {
    svg .inherit-bg { fill: $darkGrey; }
}
