//
//  Mixins
//
// overwrite nonvisual
@mixin overwrite-nonvisual() {
    left: auto;
    position: relative;
}

// Create equal width, height, line-height
// PARAMS
// - $size          dimension, defines size of element
// - $borderSize    dimension, is substracted from the line-height for better equal height
@mixin equalAll( $size, $borderSize:0px ) {
    height: $size;
    line-height: ($size - ($borderSize * 2));
    width: $size;
}

// Clear floats after / at end of element
@mixin clearAfter() {
    &:after {
        content: " ";
        clear: both;
        display: block;
    }
}

// Hide overflowing text by small dots
@mixin overflowEllipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
