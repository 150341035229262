//
//  Load adaptive SCSS
//

// Settings
$adaptive-break: $landscape - 1px;

// Basics
@import "_layout";

// Hide annotate buttons
@media ( max-width: ( $portrait - 1px ) ) {
    .annotate-button { display: none }
}


// Load
@media ( max-width: $adaptive-break ) {

    // Modules
    @import "home";

}

@media ( min-width: $landscape ) and ( max-width: 1023px ) {
    #live_player {
        width:  calc(100% - 295px);
    }
}


// Other modules
@import "employees";
@import "themes";
@import "meeting";
@import "search";
@import "subscription";

// Upcoming / Last meetings per group
@import "groupMeetings";

