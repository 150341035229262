//
//  Domain Agenda overview loading spinner
//

.term-agenda {
    .loading-overlay {
        position: absolute;
        inset: 0px;
        z-index: 10;
        background-color: var(--white);
        text-align: center;
        transition: all 0.15s linear;
        pointer-events: none;

        .spinner {
            position: absolute;
            inset: 20% 0px auto 0px;
        }

        .icn {
            font-size: 32px;
            color: var(--soft-grey);
        }
    }

    &:not(.is-loading) .loading-overlay {
        opacity: 0;
    }
}
