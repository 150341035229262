//
//  Period agenda activities
//  - styling of the activitie buttons and details pop-ups
//
.term-agenda {
    --lta-act-left: 0px;

    // Styling of the buttons
    .act-button {
        position: relative;
        margin: var(--lta-act-offset);
        margin-right: 0px;
        border: 1px solid var(--dr-btn-left);
        background-color: var(--dr-btn-left);
        font: inherit;
        color: var(--dr-color);
        width: calc( var(--lta-month-size) - (var(--lta-act-offset) * 2 ));
        overflow: hidden;
        z-index: 1;
        border-radius: 5px;
        text-align: left;
        padding: 12px 13px;
        cursor: pointer;
        transition: background-color 0.15s linear;
        display: flex;
        flex-flow: row nowrap;

        * { pointer-events: none; }

        &:hover, &:focus-visible {
            --dr-btn-left: var(--focus-color);
            color: var(--white);
        }

        &.highlight-add {
            --dr-btn-left: var(--green);
            --dr-color: var(--white);
        }

        &.inline-button {
            --dr-btn-left: var(--medium-grey);
            margin: 0 0 10px 0;
            width: 100%;
            text-decoration: none;
            color: var(--white);

            &:hover, &:focus-visible { --dr-btn-left: var(--dark-grey); }
            &:last-child { margin-bottom: 0px; }
        }

        &.nonvisual { display: none; }

        .title-wrapper {
            flex: 1 1 auto;
            position: relative;
            min-width: 0;
            line-height: 1;
        }

        .act-icn {
            flex: 0 0 48px;
            align-self: center;
            text-align: center;
            font-size: var(--lta-font-larger);
            margin-right: -10px;
        }

        .title {
            display: block;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            padding-bottom: 3px;
        }

        .type {
            font-size: var(--lta-font-small);

            .icn { margin-right: 2px; }
        }
    }

    // Hide activity buttons when there are more in a period grid tile
    .multiple-activities {
        .act-button:not(.multi) { display: none; }
    }
}
