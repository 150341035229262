//
//  Extra styling to apply the filters
//
.term-agenda {

    // Filter by CSS
    &.hide-meetings {
        .strip, .act-button {
            &.meeting { display: none; }
        }
    }

    &.hide-events {
        .strip, .act-button {
            &.event { display: none; }
        }
    }

    &.hide-meetingTools {
        .strip, .act-button {
            &.meetingtool { display: none; }
        }
    }

    .hide-domain { display: none; }

    // Personalize view by CSS

    // Hide the multiple activities button and show all activities (useful for debug, but maybe als useful for other users)
    &.hide-multi {
        .act-button:not(.multi) { display: flex; }
        .multi { display: none; }
    }
}
