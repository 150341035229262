//
//  Search
//
@media ( max-width: ( $landscape - 1px )){

    // Filter foldout as sidebar
    #gosFoldout {
        position: fixed;
        right: 0;
        top: 0px;
        background-color: $white;
        bottom: 0px;
        width: 70vw;
        z-index: 60;
        padding: 20px;
        overflow-y: auto;
        height: auto;
        margin: 0px;
        max-height: 100%;
        transition: all 0.3s linear;
        transform: translateX(100%);

        @media ( max-width: ( $mobileLandscape - 1px )) {
            width: calc( 100vw - 50px );

            .container {
                max-width: 100%;
                min-width: 0px;
                width: auto;
            }

            .gosButton {
                display: block;
                width: 100%;
                max-width: 100%;
                margin-bottom: 5px;
                padding: 5px 15px;
                font-size: var(--font-size);

                &.reset {
                    color: $lightGrey;
                    background-color: $f5;
                }
            }
        }

        .gosClose {
            &:after {
                display: none;
            }
        }
    }

    // Tweaks for search form and results
    #gosearch {
        #gosFoldout {
            &.active, &:target {
                transform: translateX(0);
                box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.7);
            }
        }

        .tabs { border-bottom: 1px solid $e4; }

        .clone input[type="radio"]:checked + label, .gos .clone input[type="checkbox"]:checked + label {
            position: relative;

            &:after {
                position: absolute;
                width: 100%;
                bottom: -3px;
                height: 6px;
                content: " ";
                display: block;
                left: 0px;
                right: 0px;
                background-color: $white;
            }
        }

        .numberofresults {
            margin: 20px 0px;
            color: $mediumGrey;
        }
    }
}

// Search help
@media ( max-width: ( $landscape - 1px)) {
    #page.zoeken .search-info-button { top: 0px; }
}
