//
//  Content
//  - styling for content and sidemenu (aside) columns
//
.content-columns {
    position: relative;
    box-sizing: border-box;
    min-height: 400px;
    
    @media ( min-width: $landscape ) {
        display: flex;
        flex-flow: row-reverse nowrap;
        
        .content {
            flex: 1 1 auto;
            max-width: calc( 100% - ( var(--submenu-width) + var(--submenu-offset) ) );
        }

        .content-sidebar {
            flex: 0 0 var(--submenu-width);
            margin-right: var(--submenu-offset);
        }
    }
}

.reverse-columns .content-columns {
    flex-direction: row;

    .content-sidebar {
        margin-right: 0px;
        margin-left: var(--submenu-offset);
    }
}

.ris-columns .content-columns {
    flex-direction: row-reverse;

    .content-sidebar {
        margin-right: var(--submenu-offset);
        margin-left: 0px;
    }
}
