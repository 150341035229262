//
//  Hover menu
//  - styling for the hoverable menu
//  - activated via SETUP setting: menu_hoverable
//  - extends default menu styling
//

.menu-holder.hoverable {
    overflow: visible;

    .menu > li {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .menu-item {
        position: relative;
    }

    .focus-trigger {
        width: 24px;
        display: none;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;

        .user-is-tabbing & {
            display: block;
        }
    }

    .menu-children {
        position: absolute;
        background-color: var(--menu-children-bg);
        border: 5px solid var(--menu-children-border);
        color: var(--menu-children-color);
        padding: 10px 5px 5px 5px;
        visibility: hidden;
        opacity: 0;
        transition:
            opacity 0.3s linear,
            max-height 0.3s linear;
        max-height: 0px;
        z-index: 1;
        overflow: hidden;
        left: 0px;
        top: 100%;

        &.two-columns {
            column-count: 2;
            column-gap: 20px;
        }

        li { break-inside: avoid; }

        a {
            display: block;
            color: inherit;
            text-decoration: none;
            padding: 10px 0px 10px 15px;
            font-weight: bold;
            width: 230px;
            font-size: var(--font-size);
            position: relative;
            z-index: 3;

            &:hover, &:focus {
                background-color: getVar( --menu-children-hover-bg, $white);
                color: getVar( --menu-children-hover-txt, $primary);
            }
        }
    }

    li:hover, li.active {
        .menu-children {
            opacity: 1;
            max-height: 1000px;
            visibility: visible;
        }
    }
}

.header-basic .hoverable {
    .menu-children {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15 );
        border: 0px;
        border-bottom: 3px solid var(--header-active);
    }

    .has-children {
        &:after {
            content: " ";
            display: block;
            background-color: inherit;
            position: absolute;
            left: 0px;
            right: 0px;
            height: 10px;
            bottom: -7px;
            opacity: 0;
            pointer-events: none;
        }
    }

    li {
        &:hover, &:focus, &:focus-within {
            .has-children {
                z-index: 2;
                box-shadow: -3px 0px 5px rgba(0, 0, 0, 0.15);
                color: $darkGrey;

                &:after { opacity: 1; }
            }
        }
    }
}
