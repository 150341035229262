//
//  Base styling of notification box and generic elements
//

@use 'sass:math';

.notifications {
    position: fixed;
    right: 0px;
    bottom: 0px;
    transform: translateY( calc(100% + 40px) );
    transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition-delay: 0.2s;
    width: 100%;
    max-width: 400px;
    background-color: $white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    margin-right: $offset;
    margin-bottom: $offset;
    visibility: hidden;

    &.active {
        transform: translateY( 0% );
        visibility: visible;
    }

    .opt {
        display: inline-block;
        text-align: center;
        border-radius: 3px;
        font-size: var(--font-size-large);
        color: inherit;
        transition: all 0.15s linear;

        @include equalAll(32px);

        * { pointer-events: none; }
        &:hover { background-color: $f5; }
    }

    .noti-footer {
        padding: 5px;
        text-align: center;

        .button { min-width: 50%; }
    }

    @media ( max-width: ( $mobileLandscape - 1 )) {
        margin-right: 0px;
        right: math.div( $offset, 2 );
        left: math.div( $offset, 2 );
        bottom: (math.div( $offset, 2 ) * -1);
        max-width: calc(100% - #{$offset});
    }
}
