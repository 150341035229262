//
//  Base row
//  - contains the generic, basic styling of row elements
//  - used as base for document-row and voting-row
//

@use 'sass:math';

$row-offset: 10px;
$row-hover: $e4;

.item-row {
    
    // Reset
    list-style: none;
    margin: 0px;
    box-sizing: border-box;
    border-top: 1px solid $e4;
    
    // Flex
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    align-items: center;
    width: 100%;
    
    // Style & offset
    color: inherit;
    text-decoration: none;
    transition: all 0.15s linear;
    padding: $row-offset math.div($row-offset, 2) $row-offset $row-offset;
    
    &:hover { background-color: $f5; }
    
    .row-title {
        display: block;
        font-size: var(--font-size);
        color: $darkGrey;
        min-width: 0;
        overflow: hidden;
        word-break: break-word;
        line-height: 1.4;
    }
    
    // Icon at front of row
    .row-icn {
        flex: 0 0 24px;
        margin-right: 5px;
        margin-top: 5px;
        color: $altGrey;
        font-size: var(--font-size);
        
        @media ( min-width: $portrait ) {
            font-size: var(--font-size-large);
        }
        
        @media ( min-width: $landscape ) {
            flex-basis: 30px;
            font-size: var(--font-size-larger);
        }
        
        &:not(.private) .fa-file-pdf { color: var(--pdf-red); }
    }
    
    .row-pointer {
        order: 4;
        flex: 0 0 auto;

        .icn { color: $primary; }
        
        @media ( min-width: $landscape ) {
            .label { display: inline; }
        }
    }
}
