//
//  Layout
//  - contains styling for the generic containers, header, footer, mobile menu and etc..
//

// Containers

// Footer
@media ( max-width: ( $mobileLandscape - 1px ) ) {
    .main-footer {
        .footer-links .block {
            text-align: center;
            margin: 15px auto;
        }

        .logo {
            padding-top: 35%;
            background-position: center center;
        }

        .website-button {
            margin-left: auto;
            margin-right: auto;
            float: none;
        }

        .block:last-of-type {
            ul {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;

                li { flex: 0 0 auto; }
            }
        }
    }

    .copy {
        text-align: center;

        .right { float: none; }
    }
}

// Content column + submenu
@media ( max-width: ( $adaptive-break - 1px ) ) {
    .mijnprofiel {
        float: none;
        width: 100%;
        clear: both;
        margin: 50px 0px;
    }
}

// Tabs component
@media ( max-width: ( $mobileLandscape - 1px ) ) {
    .tabs {
        margin-bottom: 20px;

        li, a {
            float: none;
            display: block;
            padding: 7px 15px;
        }

        li {
            border-radius: 0px;
            border: 1px solid $e4;
            box-shadow: none;
            margin-bottom: -1px;
        }
    }
}

@media ( max-width: ( $landscape - 1px )) {
    li.private { display: none; }
}
