//
//  Dossier person
//  - small CSS partial for the styling of a involved person in the dossier view
//
.dossier-person {
    display: flex;
    flex-flow: row nowrap;
    text-decoration: none;
    color: inherit;
    align-items: center;
    padding: 10px;
    border-radius: 3px;
    transition: all;
    margin: 0px -10px;

    &:hover, &:focus-visible { background-color: var(--f5); }

    .name {
        font-weight: bold;
        display: block;
    }

    .emp-info {
        margin-left: 10px;
        line-height: 1.4;
    }
}
