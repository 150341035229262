//
//  Base form elements
//

// Form elements
fieldset {
    padding: 15px 20px 20px 20px;
    border: 1px solid $fieldset-border;
    margin-bottom: 20px;
    clear: both;

    p, br { clear: both; }
}

legend {
    font-weight: bold;
    bottom: 10px;
}

label, span.question, span.result, input, textarea {
    display: inline-block;
    margin-bottom: 5px;
}

label, span.question, form span.label {
    clear: left;
    cursor: pointer;
    -webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

form span.label {
    display: inline-block;
    float: left;
}

label.inline { clear: none; }

input {
    font-family: inherit;
}

input[type=text], input[type=date], input[type=password], input[type=email], textarea {
    border: 1px solid $input-border;
    padding: 5px;
    width: 300px;
}

// Radio & checkboxes
input[type=radio], input[type=checkbox] {
    clear: left;
    height: 20px;
    margin-right: 10px;
    top: 3px;
    position: relative;

    & + label {
        float: left;
        clear: none;
        width: 100%;
    }
}

textarea {
    max-height: 300px;
    max-width: 500px;
    min-height: 80px;
    width: 100%;
}

select {
    line-height: 25px;
    padding: 5px;
    margin-bottom: 5px;
    min-width: 50px;
}

// Default text like fields
input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, textarea:focus,
input[type=radio]:focus, input[type=checkbox]:focus {
    border-color: $hover-blue;
    box-shadow: 0px 0px 3px rgba( 0, 0, 0, 0.25 );
    outline: 0px;
}

@media print {
    form, fieldset { display: none; }
}

span.label + .radio {
    float: left;

    &:after {
        content: " ";
        clear: both;
        display: block;
    }
}

.vergadering .note {
    label { float: none; }

    textarea {
        box-sizing: border-box;
        height: 175px;
        max-width: 100%;
        width: 100%;
    }
}

.form-group {
    display: block;
    clear: both;
}


// IE11, hide clear
.is-ie ::-ms-clear {
    display: none;
}
