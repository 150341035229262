//
// Header / toolbar above the term-agenda
//
.term-agenda-header .toolbar {
    border-bottom: 1px solid var(--lta-border);
    padding: 10px;

    .left, .right {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        flex: 0 0 40%
    }

    .right {
        justify-content: flex-end;
    }

    .center {
        flex: 1 1 auto;
        text-align: center
    }

    .button, .field-group {
        margin-top: 0px;
        margin-bottom: 0px;
        white-space: nowrap;
    }

    .quicksearch, .select { margin-right: 5px; }

    .select {
        border-color: var(--ee);
        background-color: var(--ee);
    }

    .menu-item[data-view-opt] {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .icn { color: var(--e4); margin-right: 7px; }
        .label { flex: 1 1 auto; }

        &.selected {
            .icn { color: var(--green) }
        }
    }
}
