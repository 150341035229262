$border-color: $e4;

//
//  Tabs
//
.tabs, .simpleTabs {
    border-bottom: 1px solid #E4E4E4;

    &, ul {
        list-style: none;
        clear: both;
        padding: 0px;
        margin: 0px;
    }

    &:after { display:block; clear: both;content: " "; }

    li {
        display: block;
        float: left;
        line-height: 20px;
    }

    a {
        color: inherit;
        display: block;
        padding: 7px 12px;
        line-height: 20px;
        text-decoration: none;
        border: 1px solid transparent;
        background-color: $white;
        transition: all 0.15s linear;
    }
}

// Tabs / yearbar
.tabs {
    li {
        border: 1px solid transparent;
        background: $white;
        z-index:3;
        margin-right: 1px;

        &:hover {
            background-color: $f5;
        }

        // Private / protected tab
        &.private { background: $e4; }

        &.active, a.active {
            border-color: var(--tab-active-border) var(--tab-active-border) $white var(--tab-active-border);
            background-color: var(--tab-active-bg);
            font-weight: bold;
            color: var(--tab-active-txt);
        }

        a, button {
            color: inherit;
            display: block;
            padding: 7px 12px;
            line-height: 20px;
            text-decoration: none;
            border: 1px solid transparent;
            background-color: $white;
            transition: all 0.15s linear;

            span { pointer-events: none; }

            &.active {
                font-weight: bold;
                color: var(--secondary);
            }

            &:hover { background-color: $f5; }
        }
    }

    .sub, .sub.active {
        text-align: center;
        cursor: pointer;
        position: relative;
        color: $mediumGrey
    }

    .more-tabs {
        min-width: 70px;
        padding-right: 12px;
        color: $mediumGrey;
    }
}

// submenu for tabs
// TODO: Dit kan beter
.tabs .sub {
    ul {
        position: absolute;
        left: -9999px;
        border-top: 0px;
        background: $white;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        z-index: 5;
        top: 100%;
        width: 100%;
        display: none;
    }

    &.active {
        ul {
            left: auto;
            display: block;
        }
    }

    li {
        border-radius: 0px;
        box-shadow: none;
        float: none;
        color: $mediumGrey;

        &.active a, a.active { color: getVar(--primary, $primary); }
    }

    a {
        font-size: var(--font-size-small);
        text-align: center;
        display: block;
        width: 100%;
    }

    ul a {
        &:active, &:hover {
            background: $f5;
            text-decoration: underline;
        }
    }
}

// Simple tabs / monthbar
.simpleTabs {
    a {
        font-size: var(--font-size);

        &:hover { color: getVar(--primary, $primary); text-decoration: none; }
    }

    .active {
        &, a {
            color: var(--secondary);
            font-weight: bold;
        }

        abbr { border-bottom: none;}
    }
}

// Print views
@media print {
    .tabs li, .simpleTabs a { display: none; }
    .tabs li.active, .simpleTabs .active {
        display: block;
        box-shadow: none;
        background: transparent;
        padding-left: 0px;
    }

    .tabs li.active a, .simpleTabs .active {
        color: #444444;
        padding-left: 0px;
    }

    .tabs li.active a:before, .simpleTabs .active:before {
        content: "Geselecteerd: ";
        font-weight: bold;
    }
}
