//
//  Filtermenu
//

.filter-menu {
    --hm-width: 500px;

    header {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--e4);
        align-items: center;

        .title { flex: 1 1 auto; font-size: var(--font-size-large); }
        .button { margin: 0px; }
    }

    .filter-group {
        h4 { margin: 0px 0px 5px 0px; }
    }

    .filter-holder {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax( 200px, 1fr) );
    }
}
