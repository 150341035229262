//
//  term-agenda Layout
//  - basic layout styling
//

// Container / holder of the agenda widget
.term-agenda-row {
    border-top: 1px solid var(--e4);
    background-color: var(--f5);
    padding: var(--lta-padding);
}

// Layout of the widget
.term-agenda-holder {
    min-height: 50px;
    background-color: var(--lta-bg);
    box-shadow: var(--lta-shadow);
    position: relative;
    border-radius: var(--lta-radius);
    overscroll-behavior: contain;

    * { box-sizing: border-box; }

    .high-contrast & {
        border: 1px solid var(--lta-border);
    }

    .dossier-row {
        &:hover, &:focus-within {
            .month {
                background-color: var(--back-blue);

                &.odd { background-color: var(--back-blue); }
            }

            .main-button { background-color: var(--back-blue); }
        }
    }

    .term-agenda-header {
        top: 0px;
        position: sticky;
        z-index: 5;
        border-radius: var(--lta-radius) var(--lta-radius) 0px 0px;
        background-color: var(--lta-bg);
    }

    .term-agenda-overview-head {
        display: flex;
        overflow-x: hidden;
        position: relative;
    }

    .term-agenda-overview {
        min-height: 90px;
        overflow: auto;

        &:before {
            position: absolute;
            inset: 0px auto 30px 0px;
            width: var(--lta-left-col);
            z-index: 3;
            background-color: var(--lta-bg);
            content: " ";
            border-radius: 5px 0px 0px 5px;
        }

        .month-grid {
            display: flex;
            flex-flow: row nowrap;
            position: relative;
            flex: 1 1 auto;

            &:last-child { border-right: 0px; }
        }

        .month {
            flex: 0 0 var(--lta-month-size);
            border-left: 1px solid var(--lta-border);

            &.odd { background-color: var(--lta-odd-bg); }

            &.selected {
                outline: 1px solid var(--focus-color);
                z-index: 1;
            }
        }

        .activities-holder {
            position: absolute;
            inset: 0px;
        }
    }

    .domain-row, .dossier-row {
        width: var(--lta-row-width);
        border-top: 1px solid var(--lta-border);
        display: flex;
        position: relative;
    }

    .main-button {
        background-color: var(--white);
        padding: 15px 20px;
        border: 0px;
        border-right: 2px solid var(--lta-border);
        flex: 0 0 auto;
        width: var(--lta-left-col);
        position: sticky;
        left: 0;
        z-index: 3;
        display: block;
        font: inherit;
        line-height: 20px;
        text-align: left;
        color: var(--dark-grey);
        border-top: 1px solid var(--lta-border);
        text-decoration: none;
    }

    // Only allow smooth scrolling when the JS is done
    &:not(.is-loading) {
        .term-agenda-overview {
            scroll-behavior: smooth;
        }
    }

    &.is-loading {
        .term-agenda-overview {
            overflow: hidden;
        }
    }

    // Hide searched elements
    .domain, .dossier-row {
        &.filtered { display: none; }
    }
}
