//
//  Live player
//  - styling of elements in the live-player and attached elements
//

// Pause message
.live-message {
    background-color: rgba( 0, 0, 0, 0.5 );
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;

    &, .message {
        position: absolute;
        z-index: 6;
    }

    .message {
        width: 80%;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 45%;
        box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.5 );
        margin: 0px;
    }
}

// Current live-stream bar
.current-live-stream {
    margin-bottom: 30px;

    @media ( min-width: $landscape ) {
        display: flex;
        flex-flow: row nowrap;
    }

    .block {
        margin: 0px;
        height: 100%;
    }

    .current-live-meta {
        flex: 0 0 33.333333%;
        min-width: 0;

        @media ( max-width: ( $landscape - 1px )) {
            width: 100%;
        }
    }
}

.broadcast {

    // Forcefull hide player and live-meta when there is no stream
    &:not(.is-streaming ) {
        .current-live-meta, #live_player {
            display: none;
        }
    }

    // Hide visual and next-meeting block when there is a live-stream
    &.is-streaming {
        .visual-holder, #next_meeting {
            display: none;
        }
    }

    .live-player { flex: 1 1 auto; }
}

// Current live agenda
.current-agenda {
    box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.15 );
    background-color: $white;
    padding: 15px;
    margin: 30px;

        // Different Margin for tablet landscape
    @media ( min-width: $portrait ) and ( max-width: ( $desktop - 1px )) {
        margin: 0px 30px 20px 30px;
    }

    h4 {
        font-size: var(--font-size-small);
        font-weight: normal;
        line-height: 1;
        margin: 0px;
    }

    .current-item {
        display: flex;
        flex-flow: row nowrap;
        font-weight: bold;

        .title {
            flex: 1 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .nr {
            flex: 0 0 auto;
            margin-right: 5px;
            color: getVar(--primary, $primary);
        }
    }
}
