//
//  Meeting tools details
//  - styling for the meeting tools modal(s) or the seperate details page
//
.mt-details-page article {
    --mt-details-margin: 20px;
    --mt-details-profile: 50px;
    --mt-details-block-padding: 20px;
    --mt-details-block-bg: #{ $f5 };
    --mt-details-grid-margin: var(--mt-details-margin);

    @media ( max-width: ( $portrait - 1px )) {
        --mt-details-margin: 10px;
        --mt-profile-size: 30px;
    }

    h3 {
        font-size: var(--font-size-large);
        margin: 0px;
        line-height: 1.2;
        color: $darkGrey;

        span { color: $mediumGrey; }
    }

    [data-tab]:not(.active) { display: none; }

    .share-menu {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .page-title {
        padding-right: 50px;
    }

    // Styling of the tabs
    .tabs {
        display: flex;
        flex-flow: row nowrap;
        margin: 30px 0px 20px 0px;

        button {
            background-color: var(--f5);
            border: 1px solid var(--soft-grey);
            font-weight: bold;
            font-size: 1.1rem;
            padding: 12px 25px;
            text-align: center;
            position: relative;
            margin: 0px -1px -1px 0px;
            color: var(--medium-grey);
            cursor: pointer;
            transition: all 0.15s linear;

            &:hover { background-color: var(--e4); }

            &.active {
                z-index: 1;
                color: var(--dark-grey);
                background-color: var(--white);
                border-bottom: 1px solid $white;
            }
        }
    }

    // Responses timeline
    .mt-timeline {
        padding: 15px 0px;

        display: grid;
        grid-gap: var(--mt-details-margin);
        grid-template-columns: var(--mt-details-profile) auto var(--mt-details-profile) ;

        .mt-content-block, .no-content { grid-column: 2; }
        .mt-subject-author { grid-column: 1; }
        .mt-response-author { grid-column: 3; }

        // For debugging
        .sf-dump { grid-column: 2; }
    }

    .mt-content-block .profile-icon {
        width: var(--mt-details-profile);
        height: var(--mt-details-profile);
        line-height: calc( var(--mt-details-profile) - 4px );
        font-size: calc( var(--mt-details-profile) / 2 );
    }

    // Content block
    .mt-metadata {
        @media ( min-width: $mobileLandscape ) {
            .meta-block {
                column-count: 2;
                column-gap: var(--mt-details-grid-margin);
            }
        }
    }

    // Extra offset below documents / timeline tabs and back button
    .back-button-holder { margin-top: 20px; }
}
