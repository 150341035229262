//
//  Voting
//  - Extra styling for the voting graph and legend in the details pop-up
//
.small-voting-graph {

    // Define variables default values
    --_graph: 80px;
    --_graph-inner: 47px;
    --_graph-inner-bg: var(--white);
    --_graph-disagree: 0;
    --_graph-abstain: 0;
    --_graph-absent: 0;
    --_graph-right-offset: 20px;

    margin: 20px 0px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;

    ul {
        margin: 10px 0;
    }

    .act-button {
        grid-column: 1 / -1;
        pointer-events: none;
    }

    .graph {
        position: relative;
        width: var(--_graph);
        line-height: var(--_graph);
        height: var(--_graph);
        transition: background 0.15s linear;
        margin-right: var(--_graph-right-offset);

        // Use conic gradient to create a circular graph
        background: conic-gradient(
                        var(--red) 0% var(--_graph-disagree),
                        var(--blue) var(--_graph-disagree) var(--_graph-abstain),
                        var(--grey) var(--_graph-abstain) var(--_graph-absent),
                        var(--green) var(--_graph-absent) 100%
        );

        &, .perc {
            display: block;
            border-radius: 100%;
        }
    }

    // Use perc as background hider over the conic gradient to make the graph look like a line
    // Als has the largest percentage as text
    .perc {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--_graph-inner-bg);
        text-align: center;
        font-weight: bold;
        width: var(--_graph-inner);
        line-height: var(--_graph-inner);
        height: var(--_graph-inner);
        font-size: var(--font-size-large);
    }

    // Result label above legend
    .result-label {
        --_color: var(--green);
        display: inline-block;
        border-radius: 5px;
        border: 1px solid var(--_color);
        background-color: color-mix(in srgb, var(--_color), var(--white) 75%);
        color: color-mix(in srgb, var(--_color), var(--black) 50%);
        font-weight: bold;
        padding: 5px 15px;
        text-align: center;

        .icn {
            color: var(--_color);
        }

        &.rejected {
            --_color: var(--red);
        }

        &.no-result {
            --_color: var(--gray);
        }
    }

    // Legend
    .orb {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        background-color: var(--green);
        margin-right: 5px;

        &.disagree {
            background-color: var(--red);
        }

        &.abstain {
            background-color: var(--blue);
        }

        &.absent {
            background-color: var(--light-grey);
        }
    }

    // Show legend on a single line
    &.inline-legend li {
        display: inline-block;
        margin-right: 10px;
    }

    // Boxed / card styling
    &.boxed {
        border-radius: 7px;
        border: 1px solid var(--soft-grey);
        padding: 20px;
    }
}

// Hide the details element because these are duplicate with the meeting element below the voting graph
.pa-pop:has(.voting-graph) {
    .pa-details {
        display: none;
    }
}
