//
//  Type link
//  - clickable block element, used in a grid as a tile
//
$box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.15 );
$transition: all 0.15s linear;

.type-link {
    padding: 20px;
    box-shadow: $box-shadow;
    background-color: var(--white);
    color: inherit;
    text-decoration: none;
    transition: $transition;
    border: 1px solid transparent;

    @media ( max-width: ( $desktop - 1px )) {
        display: flex;
        flex-flow: row nowrap;
    }

    @media ( min-width: $desktop ) {
        text-align: center;
    }

    &:focus, &:hover {
        .type-icn { transform: scale( 1.2 ); }
        .title { color: var(--secondary); }
    }

    &:focus {
        .type-icn { background-color: var(--focus-color); }
    }
}

.type-icn {
    background-color: var(--primary);
    border-radius: 100%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    color: var(--white);
    text-align: center;
    display: block;
    flex: 0 0 auto;
    margin-right: 20px;
    transform-origin: center center;
    transition: $transition;

    @media ( min-width: $desktop ) {
        margin: 0px auto 15px;
    }

    .icn, .icn-stack { color: inherit; }
    .private .fa-lock {
        text-shadow: -1px -1px rgba( 0, 0, 0, 0.15 );
        color: inherit;
    }
}

.type-info {
    flex: 1 1 auto;

    .title {
        display: block;
        font-weight: bold;
        line-height: 1.4;
        transition: $transition;
        word-break: break-word;
    }

    .label { display: none; }
    .meta-lint { font-size: var(--font-size-small); }

    .amount {
        display: inline-block;
        margin-right: 15px;
    }

    @media ( min-width: $desktop ) {
        .meta-lint {
            justify-content: center;
        }
    }
}
