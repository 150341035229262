//
//  My profile
//  - my profile bar, containing the info of the logged in useraccount
//  - shown at the top of the dashboard
//
.my-profile {
    display: block;
    clear: both;
    padding-bottom: 30px;

    .container, .profile-card {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .container {
        border-bottom: 1px solid $e4;
        padding: 20px 0px;
    }

    .profile-card, .profile-content {
        flex: 1 1 auto;
        margin: 0px;
        text-align: left;
    }

    .profile-icon {
        margin-right: 15px;
        box-shadow: 0px 1px 5px rgba( 0, 0, 0, 0.08 );

        @media ( min-width: $portrait ) {
            margin-right: 30px;
            width: 70px;
            height: 70px;
            line-height: 68px;
            font-size: 28px;
        }
    }

    .profile-content {
        line-height: 1.2;

        a { color: inherit; }

        & > span {
            display: block;

            @media ( max-width: ( $mobileLandscape - 1px )) {
                font-size: var(--font-size-small);
            }
        }

        .name {
            font-size: var(--font-size-larger);
            font-weight: 300;
            margin-bottom: 5px;
        }
    }

    .profile-options {
        flex: 0 0 auto;

        @media ( max-width: ( $sidebar-breakpoint - 1px )) {
            .button:not(.mobile-menu) {
                display: none;
            }
        }

        @media ( min-width: $sidebar-breakpoint ) {
            .mobile-menu { display: none; }
        }

        .hover-menu { top: 1px; }
    }
}
