//
//  Mandate bar
//  - styling for the mandate bar above the page when user is mandate someone
//
.imp-bar {
    font-size: 1rem;
    background-color: var(--imp-bar-bg);
    color: var(--white);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0px 5px;
    padding: 5px 10px;
    position: sticky;
    top: 0px;
    z-index: var(--imp-bar-index);
    box-shadow: 0px 0px 3px #0003;

    div { flex: 1 1 auto; }

    a {
        color: inherit;
        display: inline-block;
    }

    h2 {
        font-size: inherit;
        margin: 0px;
        display: inline-block;
    }

    button {
        --btn-hover-bg: #0003;
        --btn-bg: transparent;
        --btn-border: currentColor;
        --btn-txt: #fff;
        --btn-hover-txt: #fff;
        margin-left: auto;
        flex: 0 0 auto;
    }

    .button.smaller {
        --btn-padding: 3px 10px;
    }

    @media ( max-width: ($mobileLandscape - 1px) ) {
        font-size: 0.9rem;

        .imp-head-txt { display: none; }
    }
}
