//
//  Subnavigaton | Side menu
//
.sub-navigation {
    float: left;
    width: 320px;
    padding-top: 40px;
    margin-bottom: 50px;

    @media (min-width: $landscape) {
        padding-top: 80px;
    }
    
    @media (min-width: $landscape) {
        padding-top: 80px;
    }
    
    @media print {
        display: none;
    }

    h2 {
        font-size: var(--font-size-large);
        font-weight: bold;

        @media (min-width: $landscape) {
            position: absolute;
            left: -999em;
            font-size: 1px;
            opacity: 0;
        }
    }
    
    ul {
        padding-left: 0px;
        list-style: none;
    }
    
    li { position: relative; }
    
    a {
        color: $text-color-base;
        text-decoration: none;
        font-weight: normal;
        border-bottom: 1px solid $e4;
        display: block;
        line-height: 30px;
        
        &:active, &:hover {
            color: getVar(--primary, $primary);
        }
    }
    
    .active > a {
        color: getVar(--primary, $primary);
        font-weight: bold;
    }
    
    ul ul {
        display: none;
        padding-left: 20px;
        
        li { border:none; }
    }
    
    // Show arrow when folder has child level menu
    .has-children {
        a { padding-right: 24px; }
        
        &:after {
            content: "\f105";
            font-family: $font-awesome;
            font-weight: bold;
            position: absolute;
            right: 0px;
            top: 0px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            color: getVar(--primary, $primary);
            text-align: center;
        }
    }
    
    // Show child level menu, active class is placed with JS
    .has-children.show-children{
        &:after { content: "\f107"; }
        & > ul { display: block; }
    }
}

.content-columns {
    .sub-navigation {
        width: 100%;
    }
}
