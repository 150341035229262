//
//  Add a new proposal
//

.modal-meeting-item-proposals {
    .add-proposal, .edit-proposal {
        margin-top: 0px;

        textarea {
            background-color: var(--ee);
            border: 0px;
            @media (min-width: 640px) {
                padding: 10px 130px 10px 10px;
            }
            resize: vertical;
            max-width: 100%;
            height: 120px;
            max-height: 300px;
            min-height: 60px;
            font-size: var(--font-size-small);
            border-radius: 5px;

            &:focus {
                background-color: var(--white);
                outline: 1px solid var(--focus-color);
                box-shadow: none;
            }
        }

        @media (min-width: 640px) {
            .textarea button {
                position: absolute;
                right: 10px;
                top: 12px;
            }
        }

        .counter {
            font-size: var(--font-size-small);
            color: var(--alt-grey);
            line-height: 20px;
        }

        .edit-toolbar {
            text-align: right;
        }
    }

    .high-contrast & {
        textarea {
            border: 1px solid var(--black);
        }
    }
}
