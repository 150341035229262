//
//  Toolbar
//  - extra styling for the toolbar above the meeting tools
//
.mt-list-overview {

    // Quicksearch
    .quicksearch {
        position: relative;
        margin: 0px;
        transition: flex 0.15s linear;

        &:focus-within { flex: 1 1 400px; }

        .input > .icn {
            top: 2px;
            left: 2px;
            right: auto;
            color: var(--soft-grey);
        }

        input {
            border: 1px solid var(--ee);
            background-color: var(--ee);
            border-radius: 5px;
            padding: 5px 5px 5px 30px;
            margin: 0px;

            &:focus {
                box-shadow: none;
                border-color: var(--focus-color);
                background-color: var(--white);
            }
        }

        button {
            position: absolute;
            inset:  0 1px 0 auto;
            padding: 4px 0px;
            border-radius: 5px;

            .icn {
                color: var(--alt-grey);
            }
        }
    }

    .menu-item[data-toggle-option] {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .icn { color: var(--e4); margin-right: 7px; }
        .label { flex: 1 1 auto; }

        &.selected {
            .icn { color: var(--green) }
        }
    }

    // Extra styling
    .mt-toolbar {
        align-items: center;
        margin-bottom: 20px;

        @media ( max-width: ( $mobileLandscape - 1px )) {
            display: block;

            .options {
                justify-content: space-between;
                margin-top: 10px;
            }
        }
    }

    .sort-holder {
        --icn-size: 30px;
        margin-right: 5px;

        .select {
            border: 1px solid var(--soft-grey);
            border-radius: 3px;
            position: relative;

            select { padding: 0px var(--icn-size) 0px 7px; }

            select, .icn {
                line-height: var(--icn-size);
                margin: 0px;
            }

            .icn {
                position: absolute;
                top: 0px;
                right: 0px;
                width: var(--icn-size);
                text-align: center;
                pointer-events: none;
            }
        }
    }
}

.high-contrast {
    .mt-list-overview {
        .quicksearch input {
            border-color: var(--black);
        }
    }
}
