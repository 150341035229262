//
//  Agenda row (container styling)
//

$gridBreakpoint: 800px;

// Reset
.agenda-row, .agenda-row-content, .agenda-block, .agenda-row-head {
    position: relative;
    box-sizing: border-box;
}

// Agenda row container
.agenda-row {
    --item-row-bg: var(--f5);
    --unread-bg: var(--white);
    clear: both;
    display: block;
    background-color: $row-bg;
    outline: 1px solid $border;
    transition: all 0.15s linear;
    container: agenda-row / inline-size;

    h5, h6 {
        color: $darkGrey;
        font-size: var(--font-size);
        margin-bottom: 5px;
    }
}

// Row content
.agenda-row-content {
    transition: all 0.5s ease-in-out;
    max-height: 0px;
    overflow: hidden;
    visibility: hidden;
}

.agenda-row.active {
    .agenda-row-content {
        max-height: var(--meeting-row-max);
        visibility: visible;
    }

    .document-list {
        max-height: 4000px;
        overflow: auto;
    }
}

// Invisible, hidden by agenda-header toggle
.agenda-row.invisible {
    display: none;
}

// Blocks in fold-out
.agenda-block {
    margin-bottom: 20px;

    .stream-widget { margin-bottom: 0px; }
}

// Make grid of row content
.agenda-row-grid {
    padding: 20px;

    @container agenda-row ( min-width: #{ $gridBreakpoint } ) {
        display: grid;
        grid-template-columns: 1fr 300px;
        grid-gap: 20px;
        grid-template-areas:
            "streaming streaming"
            "main meta";

        .stream-widget-holder { grid-area: streaming; }

        .agenda-row-left-column { grid-area: main; }
        .metadata { grid-area: meta; }
    }
}

// Document related meeting tools
.agenda-row .related-meeting-tools {
    margin-left: 20px;

    @media (min-width: $mobileLandscape) {
        margin-left: 40px;
    }

    &:not(.active) {
        .fa-minus, .meeting-tools-list { display: none; }
    }

    &.active .fa-plus { display: none; }
    [data-toggle-grandparent] { font-weight: bold; }
}
