//
//  Active filters
//  - styling for small container containing the active filters
//  - extends the styling of the tags and default tag containers


// Extra styling for the filters
.active-filters-holder {
    margin: 20px 0px;
    padding: 10px 10px 15px 15px;
    background-color: $f5;
    border-radius: 5px;
    position: relative;

    &:not(.active) { display: none; }
}

.active-filters-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    h3 {
        font-size: var(--font-size-small);
        font-weight: bold;
        margin: 0px;
        flex: 1 1 auto;
    }
}
