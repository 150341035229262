//
//  Breadcrumb
//
.breadcrumb {
    clear: both;
    margin-bottom: 20px;

    ol, li, p {
        display: inline;
        list-style: none;
        padding: 0px;
        margin: 0px;
    }

    .current-page {
        font-weight: bold;
        text-decoration: none;
        color: inherit;
    }

    li:after {
        content: "\f105";
        font-family: $font-awesome;
        font-weight: bold;
        display: inline-block;
        margin: 0px 4px;
        text-align: center;
        color: $lightGrey;
        font-size: var(--font-size-smaller);
    }

    li:last-of-type {
        &:after { display: none; }
    }
}
