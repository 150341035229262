//
//  Styling for the spinner of the dossier overview
//
.dossier-overview {
    position: relative;

    .loading-placeholder {
        position: absolute;
        inset: 0px;
        background-color: var(--white);
        text-align: center;
        transition: all 0.15s linear;
        opacity: 1;
        padding-top: 70px;

        .icn {
            font-size: 1.2rem;
            color: var(--light-grey);
        }

    }

    &:not(.is-loading) {
        .loading-placeholder {
            display: none;
            opacity: 0;
        }
    }
}
