//
// Reactions modal
//

// For reactions modal and noties modal (RaadMobiel)

// Elements used in both modals
.meeting-item-reactions-modal,
.note-modal-content {

    // Submit reaction or add/update note form
    form {
        background-color: var(--f5);
        padding: 20px;
        margin-top: 25px;
        border: 1px solid var(--e4);
    }

    textarea, input {
        border: 1px solid var(--soft-grey);
        background: var(--white);
        padding: 7px;
        border-radius: 2px;
        box-sizing: border-box;
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        resize: vertical;
        max-width: none;

        &:focus {
            border-color: $focus-outline-color;
            outline: 0px;
            box-shadow: 0px 0px 7px #0003;
        }
    }

    .field-group {
        label { font-weight: 600; }
        .counter { display: none; }
        .remaining { color: var(--dark-grey); }
    }

    // User reaction or note
    .profile {
        font-size: 0.8rem;
        color: var(--alt-grey);

        .profile-info {
            display: inline-block;
            margin-right: 5px;
        }

        .icn { color: var(--light-grey); }
    }

    .user-note {
        position: relative;
        display: flex;
        padding: 15px 10px;
        border-top: 1px solid $e4;

        .note {
            flex: 1;

            p {
                word-break: break-word;
                overflow-wrap: break-word;
                margin-bottom: 10px;

                &:last-of-type { margin-bottom: 0px; }
            }
        }
    }
}

// Specific styling for the reactions modal
.meeting-item-reactions-modal {

    .message.nonvisual { display: none; }

    form {
        .side-by-side {
            display: flex;
            margin: 0 -7.5px;

            & > * {
                flex: 1;
                margin: 0 7.5px;
            }
        }

        .toolbar {
            margin-top: 15px;
            justify-content: flex-end;
        }
    }
}

// Specific styling for the notes modal (RaadMobiel)
.note-modal-header {
    padding: 15px 20px;
    background-color: var(--f5);

    .small { display: block; }
}

.note-modal-content {
    min-height: 300px;

    .main-content {
        position: relative;
        margin-top: 30px;

        & > .content-header {
            position: absolute;
            right: 0;
            top: 0;
            margin: 0;
        }
    }
}

.user-note-edit, .user-note-share {
    textarea, .shareable-users {
        width: 100%;
        margin-bottom: 20px;
        max-width: 100%;
    }

    .toolbar {
        width: 100%;
        justify-content: space-between;
    }

    .content-header { margin-top: 0; }
}
