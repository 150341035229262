//
//  Dossier row
//
.dossier-row {
    .dossier-status {
        color: var(--alt-grey);
        font-size: var(--lta-font-tiny);
        display: block;
    }

    .main-button {
        min-height: 90px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
    }

    //SD-54794 breaks too long dossier title
    .btn-name { word-break: break-word; }
}
