//
//  Options in header
//  - containing: menu button, quicksearch and profile drowndown
//

// Options element and icons
.options {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-self: flex-end;
    
    .opt {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin: 0px;
        font-size: var(--font-size);
        text-align: center;
        cursor: pointer;
        color: $white;
    }
    
    .quicksearch label { font-size: 1.1em; }
    
    .divider {
        display: block;
        width: 0px;
        border-left: 2px dotted #fff;
        margin: 7px 5px;
        height: 26px;
    }
}

// Toggle menu / mobile menu button
.toggle-menu {
    padding: 2px 10px;
    
    span {
        pointer-events: none;
        position: relative;
        display: inline-block;
    }
    
    .icn {
        font-size: 1.4em;
        vertical-align:middle;
    }

    .menu-label {
        font-size: var(--font-size-small);
        vertical-align:middle;

        @media ( max-width: ( $mobileLandscape - 1px )) {
            display: none;
        }
    }
}

// Quicksearch
.quicksearch {
    position: relative;
    box-sizing: border-box;
    margin: 0px 0px 0px 5px;
    width: 36px;
    height: 36px;
    align-self: initial;

    form {
        margin: 0px;
        padding: 0px;
        display: inline;
        position: absolute;
        top: 0px;
        right: 0px;
    }
    
    button, label {
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background-color: transparent;
        border-radius: 3px;
        margin: 0px;
        border: 0px;
        color: $white;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    
    label {
        z-index: 1;
        cursor: pointer;
        padding: 0px;
    }
    
    button { cursor: pointer; }
    
    .clear-button {
        left: auto;
        right: 0px;
        opacity: 0;
        transition: all 0.1s linear 0.3s;
        
        &:hover {
            opacity: 0.7;
        }
    }
    
    .search-button {
        z-index: -1;
    }
    
    .search-field {
        width: 36px;
        box-sizing: border-box;
        background-color: $header-background-dark;
        border: transparent;
        padding: 6px;
        opacity: 0;
        border-radius: 3px;
        margin: 0px;
        height: 36px;
        transition: all 0.4s ease-out 0.2s;
        max-width: 600px;
        color: var(--white);

        &::placeholder {
            color: var(--white);
        }
    }
    
    @mixin open-search-field {
        opacity: 1;
        width: 300px;
        padding: 6px 40px 6px 40px;
        
        @media ( min-width: $portrait ) {
            width: 50vw;
        }
    }
    
    &.active .search-field, .search-field:focus {
        @include open-search-field;
    }
    
    &:focus-within .search-field {
        @include open-search-field;
    }
    
    .search-field:focus {
        & + .clear-button {
            opacity: 0.5;
            pointer-events: visible;
        }
    }
    
    &:focus-within .search-button { z-index: 2; }
}

// Quicksearch in main-header
@media (max-width: ($mobileLandscape - 1px)) {
    .quicksearch { display: none; }
}

@media (min-width: $mobileLandscape) {
    .mobile-search-button {
        display: none;
        font-size: 1.1em;
    }
}
