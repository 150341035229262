//
//  Voting Modal Header
//
.voting-modal {
    .voting-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: relative;

        p {
            flex: 0 0 auto;
            margin: 0px 0px 0px 5px;
        }

        h3, h4 {
            flex: 1 1 auto;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow-x: hidden;

            span {
                font-size: var(--font-size-small);
                display: block;
                line-height: 1.2;
                position: relative;
                bottom: -4px;
            }

            margin: 0px;
        }

        button { margin-left: 10px; }

        // Different layout on smaller screens
        @media ( max-width: ( $mobileLandscape - 1px ) ) {
            display: block;
            padding-right: 50px;

            .button {
                position: absolute;
                top: 0px;
                right: 0px;
            }
        }
    }

    // For toggling
    .voting-holder .voting-header {
        .fa-minus { display: none; }
    }

    .voting-holder.active .voting-header {
        .fa-minus { display: inline; }
        .fa-plus { display: none; }
    }
}
