//
//  Default classes
//  - contains some generic default classes
//

// Hide classes
.nonvisual, .hidden, .hide {
    position: absolute;
    left: -9999vw;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.display-none { display: none; }

// Forced clearing
.clearer, .clear-after:after {
    display: block;
    height: 0px;
    font-size: 0px;
    clear: both;
}

.clearer { width: 100%; }
.clear-after:after { content: " "; }

//
//  Skip links
//  - default hidden, shown on focus
//
.skip {
    background-color: $white;
    position: fixed;
    left: -100vw;
    top: 8%;
    padding: 7px 10px;
    border-radius: 3px;
    text-decoration: none;
    z-index: 3;
    box-shadow: 0px 0px 5px rgba( $black, 0.3 );
    
    &:focus {
        left: 10px;
        border: 1px solid $hover-blue;
        background-color: $white;
        color: $hover-blue;
    }

    @media ( max-width: ($landscape - 1px)) { display: none; }
}

// Add newwindow icon
.newwindow {
	&.noImage .icn { display: none; }
}

// Print
.print-only { display: none; }

@media print {
    .newwindow .image, a.more:before, span.more:before,
    #alternate_live_streams a:before, #notitie .back:before
    { display: none; }
    
    .print-only { display: inline-block; }
    .no-print { display: none; }
}

//  Clear list
//  - clears the default UL/OL styling of the element and it's children ul's
.clear-list {
    &, ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
    }
}

// Clear links
//  - clears the anchors in the element of the link styling
.clear-links {
    a {
        color: inherit;
        text-decoration: none;
    }
}

//
//  Some other, generic classes
//
.small:not(.profile-icon) {
    font-size: var(--font-size-smaller);
}

.noContent {
    margin: 20px 0px;
    font-style: italic;
}

a.more, span.more {
    display: inline-block;
    color: var(--dark-grey);
    text-decoration: none;
    margin-top: 20px;

    &:hover, &:active { text-decoration: underline; }
}

// Color classes
.primary-bg { background-color: var(--primary); }
.primary-color { color: var(--primary); }
.secondary-bg { background-color: var(--secondary); }
.secondary-color { color: var(--secondary); }
