//
//  Subject row
//  - shows a row with a toggleable subject block
//  - or shows a button which directs to a different page in the same style
//

.subject-row {
    border: 1px solid var(--soft-grey);
    background-color: var(--white);
    margin-bottom: 5px;
    border-radius: 5px;
    container: subject-row / inline-size;

    [data-toggle-parent] * { pointer-events: none; }
    h3, h4 { font-size: var(--font-size); }
    .icn-holder { font-size: 18px; }
    &:not(.active) .subject-details { display: none; }

    .subject-header {
        margin: 0px;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        padding: 10px 5px 10px 15px;
        text-decoration: none;
        color: inherit;

        &:hover, &:focus { background-color: var(--f5); }
    }

    .title-holder {
        padding-top: 0px;
        font-weight: bold;
    }

    .document-count {
        color: var(--alt-grey);
        flex: 0 0 14ch;
    }
}
