//
//  Extra options
//  - extra styling for the extra-options element in the agenda-row head or fold-out
//  - element can contain buttons for raadmobiel, reactions and votings
//  - on smaller devices the options are shown in the fold-out of an agenda-item
//  - on larger devices the options are shown in the agenda-row head
//

$breakpoint: $mobileLandscape;

// Extra styling
.agenda-row-head {
    .extra-options {
        &:after {
            border-right: 1px dotted $lightGrey;
            content: " ";
            height: 30px;
            margin: 0px 0px 0px 20px;

        }

        .button { pointer-events: visible; }
    }
}

.agenda-row-content {
    .extra-options {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dotted $lightGrey;
    }
}

// When to show
@media ( min-width: $breakpoint ){
   .agenda-row-content .extra-options { display: none; }
}

@media ( max-width: ( $breakpoint - 1px ) ) {
    .agenda-row-head .extra-options { display: none; }
}
