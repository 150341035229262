//
//  Dossiers metadata
//
.dossier-meta {
    line-height: 1.4;

    @media( min-width: $mobileLandscape ) and ( max-width: ($landscape - 1px) ) {
        display: flex;
        flex-flow: row nowrap;
        gap: 20px;
    }

    h3 {
        font-size: var(--font-size-large);
        margin-bottom: 5px;
    }

    .dossier-meta-content {  flex: 1 1 auto; }
    .dossier-visual { flex: 0 0 35% }
    .dossier-involved { margin-bottom: 10px; }

    .dossier-visual {
        margin-bottom: 20px;
        display: block;
    }

    dl {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax( 160px, 1fr ));
    }

    dt { font-weight: bold; }
    dd { overflow-wrap: break-word; }
    .meta-item { margin: 10px 0px; }
}

.right-col:not(.grey-column) {
    .dossier-meta {
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
        background-color: var(--white);
    }
}
