//
//  Window
//  - styling of basic window layout and containing elements
//

$window-padding: 30px;

// Reset en pre-set
.window, .window-header, .window-content, .window-overflow, .window-footer {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}

.window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -53%);
    transition: all 0.15s linear;
    background-color: $white;
    box-shadow: 0px 0px 15px rgba( 0, 0, 0, 0.15 );
    display: flex;
    flex-flow: column nowrap;
    min-width: 300px;
    width: 90vw;
    max-height: 80vh;
    z-index: 12;
    
    .active & {
        transform: translate( -50%, -50% );
    }
    
    @media ( min-width: $mobileLandscape ) {
        width: 90vw;
    }
    
    @media ( min-width: 900px ) {
        width: 800px;
    }
    
    // Size variations
    &.small, &.alert { width: 300px; }
    
    &.medium {
        width: 90vw;
        
        @media ( min-width: 600px ) {
            width: 500px;
        }
    }

    &.medium-large {
        width: 90vw;

        @media ( min-width: 600px ) {
            width: 700px;
        }
    }

    &.medium-large-alt {
        width: 90vw;

        @media ( min-width: 500px ) {
            width: 600px;
        }
    }
}

// Header
.window-header {
    flex: 0 0 auto;
    margin: ($window-padding - 10px ) ($window-padding - 10px ) 0px $window-padding;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    overflow-x: hidden;
    
    .title-holder {
        padding-top: 10px;
        flex: 1 1 auto;
        color: $darkGrey;
        
        h2, h3, h4 {
            margin: 0px;
            color: inherit;
            font-size: var(--font-size-larger);
       }
    }
    
    .options, .close { flex: 0 0 auto; }
    
    .subtitle {
        font-size: var(--font-size-small);
        color: var(--medium-grey);
    }

    .icn-only .icn {
        color: var(--medium-grey);
    }
}


// Content holder
.window-content {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    padding: 5px 5px 5px 0px;
    min-height: 80px;
}

.is-ie .window-content {
    max-height: calc( 80vh - 150px );
}

// Seperate overflow holder, for offset and containment of the scrollbar
.window-overflow {
    flex: 1 1 auto;
    overflow-y: auto;
    max-height: 100%;
    padding: 0px $window-padding;
    
    &:after {
        content: " ";
        display: block;
        clear: both;
        height: $window-padding;
    }
}

// Footer
.window-footer {
    flex: 0 0 auto;
    text-align: right;
    padding: 5px;
    border-top: 1px solid $e4;
    background-color: $f5;
    
    .button { margin-bottom: 0px; }
    .back-button { float: left }
}
