//
//  Readspeaker
//  - styling and fixes for the readspeaker plugin
//

// Fix readspeaker buttons
.readspeaker-holder {
    margin: 10px 0px 20px 0px;
}

#rsbtn_scrollcontrols, .readspeaker-holder {
    button {
        min-width: auto;
        min-height: auto;
        background-color: inherit;
        color: inherit;
        line-height: inherit;
    }
}

#rsbtn_scrollcontrols button { line-height: 26px; }
