$tile-margin: 10px;
$tile-margin-landscape: 20px;

$tile-factor: $tile-margin * 2;
$tile-landscape-factor: $tile-margin-landscape * 2;

// Main grid, based on three or four columns
.grid {
    position: relative;
    box-sizing: border-box;
    margin: 0px ( $tile-margin * -1 );
    
    @media ( min-width: $mobileLandscape ) {
        display: flex;
        flex-flow: row wrap;
    }
    
    @media ( min-width: $landscape ) {
        margin: 0px ( $tile-margin-landscape * -1 );
    }
    
    & + .grid { margin-top: 20px; }
}

// Default tile, 1 column
.tile {
    display: block;
    flex: 0 0 calc( 100% - #{$tile-factor});
    margin: 0px $tile-margin 20px $tile-margin;
    box-sizing: border-box;
    
    @media ( min-width: $portrait ) {
        flex-basis: calc( 50% - #{$tile-factor} );
    }
    
    @media ( min-width: $landscape ) {
        flex-basis: calc( 33.333333% - #{$tile-landscape-factor} );
        margin: $tile-margin-landscape;
    }
}

// Half tile
//  - half of container on portrait and larger
.tile.half {
    @media ( min-width: $portrait ) {
        flex-basis: calc( 50% - #{$tile-factor} );
    }
    
    @media ( min-width: $landscape ) {
        flex-basis: calc( 50% - #{$tile-landscape-factor} );
    }
}

// Quarter tile
//  - half of container on mobile landscape
//  - three-column sized on tablet landscape
//  - quarter sized on desktop and larger
.tile.quarter {
    @media ( min-width: $mobileLandscape ) {
        flex-basis: calc( 50% - #{$tile-factor} );
    }
    
    @media ( min-width: $portrait ) {
        flex-basis: calc( 33.333333% - #{$tile-factor} );
    }

    @media ( min-width: $landscape ) {
        flex-basis: calc( 33.333333% - #{$tile-landscape-factor} );
    }
    
    @media ( min-width: $desktop ) {
        flex-basis: calc( 25% - #{$tile-landscape-factor} );
    }
}

// Auto width tile
.tile.auto-width { flex: 1 1 auto; }

//
//  Alternative grid
//  - 50/50 on mobile landscape
//  - 2/3 - 1/3 on portrait and larger
//
.alt-grid {
    @media ( min-width: $mobileLandscape ) {
        display: flex;
        flex-flow: row wrap;
    }
    
    .tile {
        @media ( min-width: $mobileLandscape ) {
            flex-basis: calc( 50% - #{$tile-factor} );
        }
    
        @media ( min-width: $portrait ) {
            flex-basis: calc( 33.333333% - #{$tile-factor} );
        }
    
        @media ( min-width: $landscape ) {
            flex-basis: calc( 33.333333% - #{$tile-landscape-factor} );
        }
    }
}

//
//  Switch from desktop
//  - Later switching to 3 columns
//  - default tiles switch to 33% from desktop in stead of (tablet) landscape
//  - Useable with grids in content-columns with a sidebar next to the content
//  - bonus tweak, it also switches earlier to 50% column, from mobile landscape
//
.switch-from-desktop {
    .tile {
        @media ( min-width: $mobileLandscape ) {
            flex-basis: calc( 50% - #{$tile-factor} );
        }

        @media ( min-width: $landscape ) {
            flex-basis: calc( 50% - #{$tile-landscape-factor} );
        }

        @media ( min-width: $desktop ) {
            flex-basis: calc( 33.333333% - #{$tile-landscape-factor} );
        }
    }
}
