// Header
.header-basic {
    --menu-children-bg: #fff;
    --menu-children-border: #fff;
    --menu-children-txt: inherit;
    --menu-children-hover-bg: var(--e4);
    --menu-children-hover-txt: var(--secondary);

    z-index: 4;
    border-top: 4px solid var(--primary);
    margin-bottom: 20px;
    background-color: var(--basic-back-bg);

    @media ( max-width: ( $landscape - 1px ) ) {
        box-shadow: 0px 0px 15px rgba( 0, 0, 0, 0.1 );
    }
    
    // Topbar containing logo and options
    .client-top {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: relative;
        box-sizing: border-box;
    
        @media ( min-width: $portrait ) {
            min-height: 100px;
        }
    
        .logo-holder {
            flex: 1 1 auto;
            margin: 20px 0px;
        }
        
        .options {
            flex: 0 0 auto;
            align-self: center;
        }
        
        // Image as logo
        .logo {
            min-width: 100px;
            width: 100%;
            min-height: 90px;
            display: block;
            
            &, img {
                max-width: 300px;
                max-height: 140px;
            }
            
            &.no-background { background-image: none; }
        }
    }
    
    // Navigation bar
    .menu-bar-holder {
        clear: both;
        background-color: var(--basic-bar-bg, transparent);
        
        .menu-ul-holder {
            background-color: var(--basic-bg, var(--f5) );
            border: 1px solid var(--basic-border, var(--e4));
            border-bottom-width: 3px;
            min-height: 20px;
            
            @media ( max-width: ( $landscape - 1px ) ) {
                display: none;
            }
        }
        
        .menu-item, .focus-trigger {
            display: inline-block;
            margin-bottom: -3px;
            border-bottom: 3px solid transparent;
            position: relative;
            
            @media( max-width: ($desktop - 1px ) ) {
                padding: 9px 15px;
            }
            
            &:hover, &:focus {
                background-color: var(--basic-hover-bg);
                border-bottom-color: var(--basic-hover-border);
                color: var(--basic-hover-txt);
            }
            
            &.active {
                background-color: var(--white);
                border-bottom-color: var(--header-active, var(--primary));
            }
        }

        .menu-item-wrapper {
            &:hover, &:focus-within {
                background-color: var(--white);
                border-bottom-color: var(--soft-grey);
                color: var(--dark-grey);

                .menu-item {
                    background-color: var(--white);
                }
            }
        }
    }
}

.basic-bar {
    .header-basic { margin-bottom: 0px; }

    .menu-bar-holder {
        border-top: 1px solid var(--basic-border, var(--e4) );
        border-bottom: 3px solid var(--basic-border, var(--e4) );

        .menu-ul-holder { border: 0px; }
    }
}

#page.user-is-tabbing .menu-item-wrapper {
    .menu-item, .focus-trigger {
        outline-offset: -4px;
    }
}
