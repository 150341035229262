//
//  Voting Modal Results
//
.voting-modal {
    --vote-size: 90px;

    @media (min-width: $landscape) {
        --vote-size: 140px;
    }

    .table-grid {
        display: grid;
        grid-template-columns: 1fr var(--vote-size) var(--vote-size) 9ch;

        & > span {
            border-top: 1px solid $e4;
            padding: 5px 0px;
        }

        button {
            background-color: $white;
            border: 1px solid $e4;
            color: $focus-outline-color;
            font-size: var(--font-size-smaller);
            transition: all 0.15s linear;
            cursor: pointer;
            margin: 0px 0px 0px 10px;
            line-height: 20px;
            padding: 0px 3px;
            text-align: center;
            border-radius: 3px;
            align-self: center;
            flex: 0 0 auto;
            white-space: nowrap;

            * { pointer-events: none; }

            &:focus-visible {
                outline: 3px dotted $focus-outline-color;
                outline-offset: 3px;
            }

            &:hover, &:focus-visible {
                color: $white;
                background-color: $focus-outline-color;
                border-color: $focus-outline-color;
            }
        }

        .head {
            font-weight: bold;
            border-top: 0px;

            &.votes-agree { padding-right: 5px; }
            &.votes-disagree { padding-left: 5px; }
        }

        .name { padding-left: 10px; }
    }

    .details-foldout {
        grid-column: span 4;
        min-height: 60px;
        background-color: $f5;
        border-radius: 3px;
        margin: 10px;
        padding: 15px;

        ul {
            list-style: none;
            padding: 0px;
        }

        &.columns ul {
            column-count: 2;
            column-gap: 20px;
        }

        &:not(.active) { display: none; }
    }

    .bar { display: inline-block; }

    .votes-agree, .votes-disagree {
        position: relative;

        .bar {
            color: $white;
            font-weight: bold;
            padding: 0px 3px;
            box-sizing: border-box;
        }
    }

    .votes-agree {
        text-align: right;
        border-right: 1px solid $e4;

        .bar {
            background-color: var(--green);
            text-align: left;
        }
    }

    .votes-disagree {
        .bar {
            background-color: var(--red);
            text-align: right;
        }
    }

    // Change interface with bars to only numbers on smaller sceens
    @media ( max-width: ( $mobileLandscape - 1px )) {
        .table-grid { grid-template-columns: 1fr auto auto 30px; }

        .votes-agree, .votes-disagree {
            width: auto;
            padding-left: 5px;
            padding-right: 5px;

            .bar {
                width: auto;
                text-align: center;
                background-color: transparent;
                padding: 0px;
            }
        }

        .votes-agree .bar {
            color: var(--green);
            padding-right: 5px;
        }

        .votes-disagree .bar {
            color: var(--red);
            padding-left: 5px;
        }
    }

    @media ( max-width: ($portrait - 1px )) {
        .table-grid button .lbl { display: none; }
    }
}
