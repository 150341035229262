//
//  Basic styling and layout of header
//
.main-header {
    position: relative;
    box-sizing: border-box;
    background: $header-background;
    font-size: var(--font-size);
    height: 70px;
    z-index: 10;
    color: $white;
    
    .container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        height: inherit;
    }
    
    // Logo
    .logo-holder {
        background-color: $header-logo-background;
        flex: 0 0 $header-logo-width;
        margin-bottom: getVar( --logo-bottom, -15px );
        margin-right: 15px;
        padding: getVar( --logo-padding, 15px );
        align-self: stretch;
        position: relative;

        @media ( max-width: ( $mobileLandscape - 1px )) {
            margin-right: 5px;
        }
        
        a {
            position: relative;
            background-position: center center;
            background-repeat: no-repeat;
            display: block;
            height: 100%;
            min-height: 100%;
            text-indent: -9999px;
            overflow: hidden;
            color: $black;
        }
    }
    
    // Main menu
    .menu-holder {
        align-self: flex-end;
        display: none;
        height: 50px;
        flex: 1 1 auto;
        
        @media ( min-width: $landscape ) {
            display: block;
        }
    }
    
    // Options
    .options {
        flex: 0 0 auto;
    }
}
