//
//  Timeline other
//  - styling for several small elements in the timeline
//

// Styling for the small button-like header elements in the timeline, used to signal a change of year or a today
.timeline-marker {
    margin: 30px 0px;
    line-height: 30px;

    span, button {
        display: block;
        text-align: center;
        width: 80px;
        border-radius: 5px;
        background-color: var(--tl-marker-bg);
        color: var(--tl-marker-col);
        border: 0px;
        font: inherit;
        font-weight: bold;
        transition: all 0.15s linear;

        @container timeline ( min-width: #{ $breakpoint } ) {
            margin: auto;
        }
    }

    button {
        cursor: pointer;

        &:hover, &:focus-visible { background-color: var(--focus-color); }
    }

    &.today { --tl-marker-bg: var(--primary); }

    // Sort button
    [data-timeline="sort"] {
        &:after {
            content: "\f885";
            display: inline-block;
            margin-left: 5px;
            font-family: $font-awesome;
            font-weight: bold;

            .reverse-sorting & { content: "\f160"; }
        }
    }
}

// Styling for the icon
.timeline-icn {
    display: inline-block;
    line-height: var(--tl-icn-size);
    border-radius: 100%;
    background-color: var(--tl-icn-bg );
    color: var(--tl-icn-col );
    box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.08 );
    font-size: var(--font-size-large);
}

// Visually hide first marker in the list
.timeline-list-holder li:first-of-type .timeline-marker {
    opacity: 0;
    height: 0px;
    width: 0px;
    position: absolute;
    overflow: hidden;
}

// No-content message in timeline holder
.timeline-holder .no-content {
    text-align: center;
    font-size: var(--font-size-small);
    color: var(--alt-grey);

    .icn {
        color: var(--light-grey);
        display: block;
        font-size: var(--font-size-larger);
        margin-bottom: 10px;
    }
}

// Type filter
.timeline-type-filter {
    position: absolute;
    inset: 80px 0px auto auto;
    margin: 0px;
    z-index: 5;
}

.tab-content:has(.is-loading){
    .timeline-type-filter {
        display: none;
    }
}
