//
//  Year view
//  - alternate, zoomed out, view of the term-agenda view
//
.term-agenda.year-view {
    --lta-month-size: 40px;
    --lta-act-offset: 10px;

    // Redefine the width of the row, assuming we limit the LTA to 48 months (4 years), will be overwritten (recalculated) with JS
    //--lta-row-width: calc( (48 * var(--lta-month-size)) + var(--lta-left-col) );

    // Set different column sizes
    .term-agenda-overview-head {
        .quarter-name, .month {
            padding-left: var(--lta-act-offset);
            font-size: var(--font-size-small);
        }

        .month {
            padding-left: 0px;
            text-align: center;
        }
    }

    .dossier-button {
        padding: var(--lta-act-offset);

        .dossier-status { display: none; }
    }

    // Hide vertical grid lines
    .month:not(:nth-child(3n+4)),
    .quarter:not(:nth-child(4n+1)):after {
         border-color: transparent;
    }

    // Hide horizontal grid lines
    .dossier-row, .dossier-button {
        border-bottom: 0px;
        border-top: 0px;
    }

    // Hide quarter names excep the first
    .quarter:not(:nth-child(3)):not(.first) .quarter-name { opacity: 0; }

    // Change height of dossier rows
    .dossier-row .main-button { min-height: auto !important; }

    // Change activity button to circle element
    .act-button:not(.inline-button) {
        border-color: var(--white);
        width: var(--lta-circle-size);
        height: var(--lta-circle-size);
        padding: 0px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0px;

        * { display: none; }
    }

    // Add background line / bar
    .duration-bar {
        width: var(--lta-dur-width);
        height: var(--lta-dur-height);
        background-color: var(--dr-btn-left);
        position:absolute;
        top: 50%;
        left: var(--lta-dur-left);
        transform: translateY(-50%);
        margin-left: calc( var(--lta-left-col) + var(--lta-act-offset) + 1px );
        border-radius: var(--lta-dur-height);
    }

    // Hide bundel button
    .fold-out {
        .view-header, [data-view-opt="hideMulti"] { display: none; }
        h3 { margin-top: 0px; }
    }
}
