//
//  Tags
//  - generic styling for a small tag element
//

// Extra CSS vars for tags
:root {
    --tag-focus: var(--focus-color);
    --tag-color: var(--dark-grey);
    --tag-bg: var(--e4);
    --tag-btn-hover-bg: rgba(255, 255, 255, 0.5);
    --tag-font-size: var(--font-size-smaller)
}

.tag {
    display: inline-block;
    border-radius: 13px;
    background-color: var(--tag-bg);
    color: var(--tag-color);
    border: 1px solid var(--tag-bg);
    padding: 0px 7px;
    font-family: inherit;
    font-size: var(--tag-font-size);
    font-weight: bold;
    line-height: 18px;
    text-decoration: none;
    text-align: center;
    margin: 0px 2px;
    transition: all 0.2s linear;
    break-inside: avoid;

    &:focus-within {
        background-color: var(--tag-focus);
        color: var(--white);
    }

    &:first-child { margin-left: 0px; }

    .icn {
        display: inline-block;
        line-height: 18px;
        font-size: var(--font-size-small);
    }

    button {
        padding: 0px;
        background-color: transparent;
        border: 0px;
        margin: 0px;
        border-radius: 3px;
        color: inherit;
        transition: all 0.15s linear;
        cursor: pointer;

        &:hover {
            background-color: var(--tag-btn-hover-bg);
        }

        .icn { pointer-events: none; }
    }

    .icn-left {
        margin-right: 4px;
    }

    .icn-right {
        margin-left: 4px;
        margin-right: -2px;
    }

    .tag-name {
        min-width: 40px;
    }

    &.label {
        background-color: transparent;
        border-color: transparent;
        font-weight: bold;
        color: $darkGrey;
        padding-left: 0px;
    }

    &.click {
        cursor: pointer;
        &:hover {
            background-color: darken($e4, 10);
        }
    }

    // Extra styling for a button withing the tags
    &.tag-button {
        border-color: $blue;
        background-color: transparent;
        color: $blue;

        &:hover, &:focus-visible {
            border-color: $focus-outline-color;
            background-color: $focus-outline-color;
            color: $white;
        }

        * { pointer-events: none; }
    }

    // Extra styling to hightlight a newly added tag
    &.new-tag {
        background-color: $backGreen;
        border-color: $backGreen;
    }
}

// Extra styling for when a tag is also a button
button.tag {
    cursor: pointer;

    &:hover, &:focus {
        background-color: $backBlue;
    }

    &:focus {
        outline: 2px dotted $focus-outline-color;
        outline-offset: 2px;
    }
}

.medium-tags, .filter-bar {
    .tag {
        padding: 0px 10px;
        font-size: var(--font-size-small);
        line-height: 26px;
        font-weight: normal;
        margin: 0px 4px;

        &:first-child { margin-left: 0px; }

        .icn {
            display: inline-block;
            line-height: 18px;
        }

        &.label { font-weight: bold; }
    }
}

.small-tags {
    .tag { font-weight: normal }
}

// Colors

// Color schemes
@mixin tagColor( $name, $value ) {
    .tag {
        &.#{""+$name} {
            --tag-bg: var(#{"--"+$name});
            --tag-color: var(--white);

            .icn { color: inherit; }
            &.click {
                &:hover {
                    --tag-bg: var(--dark-grey);
                }
            }
        }
    }
}

@each $color in $colors {
    $name: nth($color, 1 );
    $value: nth($color, 2 );

    @include tagColor( $name, $value );
}

// Extra colors

// Tabsign
@include tagColor( "purple", #975ab7 );

