//
//  Show more grid
//  - tweaks the existing grid(s) to show a limited amount of tiles.
//  - other tiles are hidden behind a 'show-more-button'
//

// Amount variables, defines which items are hidden
$maxMobile : 5;
$maxTablet : 7;
$maxDesktop : 9;

// Different amount for switch-from-desktop grids
$desktopMaxMobile : 5;
$desktopMaxDesktop : 7;

// Function for hiding tile after specific amount($n)
@mixin hideFrom( $n ) {
    .type-link:nth-of-type( n+#{$n} ) {
        display: none;
    }
}

@mixin hideMoreButton( $n ) {
    .type-link:nth-of-type( n+#{$n} ) ~ .show-more-button {
        display: block;
    }
}

// Grid CSS
.show-more-grid {

    // Hide tiles
    &:not(.active){
        @media (max-width: ( $mobileLandscape - 1px )) {
            @include hideFrom( $maxMobile );
        }

        @media ( min-width: $mobileLandscape ) and ( max-width: ( $desktop - 1px )) {
            @include hideFrom( $maxTablet );
        }

        @media( min-width: $desktop ) {
            @include hideFrom( $maxDesktop );
        }
    }

    // Show more-button
    @media (max-width: ( $mobileLandscape - 1px )) {
        @include hideMoreButton( $maxMobile );
    }

    @media ( min-width: $mobileLandscape ) and ( max-width: ( $desktop - 1px )) {
        @include hideMoreButton( $maxTablet );
    }

    @media( min-width: $desktop ) {
        @include hideMoreButton( $maxDesktop );
    }

    // Hide different for switch-from-desktop grid
    &.switch-from-desktop:not(.active) {
        @media ( max-width: ( $landscape - 1px ) ){
            @include hideFrom( $desktopMaxMobile );
            @include hideMoreButton( $desktopMaxMobile );
        }

        @media ( min-width: $landscape ){
            @include hideFrom( $desktopMaxDesktop );
            @include hideMoreButton( $desktopMaxDesktop );
        }
    }

    // Show more button
    .show-more-button {
        flex: 1 1 100%;
        display: none;

        @media ( max-width: ( $desktop - 1px ) ) {
            background-color: $f5;
        }

        @media ( min-width: $desktop ) {
            border-radius: 0px;
            border-top: 1px solid $e4;
        }

        .less-label { display: none; }

        .icn {
            transition: all 0.3s linear;
            transform-origin: center center;
        }
    }

    &.active {
        .show-more-button {
            .less-label { display: inline-block; }
            .more-label { display: none; }
            .icn { transform: rotate(180deg) }
        }
    }
}
