//
//  Old list
//  - styling for a simple list component
//  - this is used in several old modules/components
//  - for example, this is used on the home and meetings overview page in the blocks for upcomming or previous meetings
//
.meta, .list ul, ul.list, ul.listextra {
    list-style: none;
    padding-left: 5px;

    li {
        padding-left: 10px;
        position: relative;

        &:before {
            content: " ";
            border-width: 4px;
            border-style: solid;
            border-color: transparent transparent transparent var(--primary);
            position: absolute;
            left: 0px;
            font-size: 1px;
            display: block;
            float: left;
            top: 8.5px;
        }
    }
}

.list ol, ol.list, ol.listextra {
    color: var(--primary);
    padding-left: 20px;

    li { color: var(--primary); }
}

.list li a, .listextra li a {
    color: var(--dark-grey);
    text-decoration: none;

    &:hover, &:active { text-decoration: underline; }
}
