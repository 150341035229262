//
//  CMS (inwonersportaal) like header
//

// header
@import "base";

.main-header {
    --menu-children-bg: #{$header-background-dark};
    --menu-children-border: var(--menu-children-bg);
    --menu-children-txt: #{$white};
    --menu-children-hover-bg: var(--header-active);
    --menu-children-hover-txt: #{$white};

    @import "menu";
    @import "options";

    // JS Hide / show when processing
    &:not(.processed) {
        .menu-holder { opacity: 0; }
    }
}
