//
//  Visual and content layout template
//  - template with image header (visual) above the content
//  - also contains a two column layout for the first container
//  - and styling for  the first-container
//
:root {
    --ih-top-offset: 100px;
    --ih-shadow-size: 15px;
    --ih-padding: 30px;
    --ih-blur: 5px;
    --ih-bg: var(--ee);

    @media( min-width: $portrait ) {
        --ih-padding: 50px;
        --ih-top-offset: 200px;
    }
}

// Styling of the image header element
.image-header {
    position: absolute;
    inset: 0px 0px auto 0px;
    width: 100%;
    height: 80vh;
    max-height: 550px;
    margin-top: calc( var(--ih-top-offset) * -1);
    overflow: hidden;
    background-color: var(--ih-bg);
    z-index: -1;

    picture {
        position: absolute;
        inset: calc( var(--ih-blur) * -2);
    }

    img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        max-width: initial;
        filter: blur(var(--ih-blur));
    }

    // Loop through domain colors

    @each $color in $colors {
        $name: nth($color, 1 );
        $value: nth($color, 2 );

        &.#{""+$name} {
            --ih-bg: #{getHSLA($name, 20%)};
        }
    }

    &.grey { --ih-bg: #{getHSLA("grey", 20%)}; }
    &.brown { --ih-bg: #{getHSLA("brown", 20%)}; }
    &.pink { --ih-bg: #{getHSLA("pink", 20%)}; }
    &.turquoise { --ih-bg: #{getHSLA("turquoise", 20%)}; }
}

// Styling for the main containers, overwiting the main-container attributes and style the first container box
.has-image-header {

    // Reset offset and sizes of containers
    #wrapper { padding-top: var(--ih-top-offset); }

    .container {
        max-width: 1300px;
        padding-left: var(--ih-padding);
        padding-right: var(--ih-padding);
    }

    // Style the first container making it have a fading drop shadow
    .first-container {
        background-color: var(--white);
        position: relative;
        padding-top: var(--ih-padding);

        &:after {
            border-radius: 0 0 25px 25px / 250px;
            box-shadow: 0 0 var(--ih-shadow-size) #0002;
            content: '';
            display: block;
            height: 550px;
            max-height: calc(100% - var(--ih-shadow-size));
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
    }
}

// Main content columns
.main-content-columns {

    @media(min-width: $landscape) {
        display: flex;
        flex-flow: row nowrap;
        gap: 100px;
    }

    .left-col { flex: 1 1 auto; min-width: 0px; }
    .right-col { flex: 0 0 460px; }

    .left-col, .right-col {
        position: relative;
        margin-bottom: var(--ih-padding);
    }

    .grey-column {
        background-color: var(--f5);
        padding: var(--ih-padding);

        @media ( min-width: $landscape ) {
            margin-right: calc(var(--ih-padding) * -1);
            margin-top: calc(var(--ih-padding) * -1);
        }
    }

}
