//
//  Dashboard votings
//  - styling for externally loaded HTML for votings
.tab-voting {

    // Clear stuff
    .row {
        padding: 0px;
        margin: 0px;

        @media ( max-width: ( $mobileLandscape - 1px )) {
            overflow-x: auto;
            white-space: nowrap;
            max-width: 100%;
        }
    }

    .tab-content:not(.active), .tab-controls, .voting-title { display: none; }

    // Clear table style
    table, tr {
        background-color: $white;
        margin: 0px;
    }

    // Set new table style
    td, th {
        border: 0px;
        border-bottom: 1px solid $row-border;
        padding: 7px 5px;
        background-color: transparent;
    }

    thead {
        outline: 0px;

        td, th {
            border: 0px;
            border-bottom: 2px solid $row-border;
            font-weight: bold;
            color: $darkGrey;
        }
    }

    td, th {
        &:first-of-type {
            width: 150px;
        }

        &:nth-child( 3n ), &:nth-child( 4n ) {
            text-align: center;
            width: 54px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .date { font-style: normal; }

    // Color schemes
    .color- {
        &green { color: $green; }
        &red { color: $red; }
        &grey-300 { color: $lightGrey; }
    }

    .tab-content h3 { margin: 30px 0px; }

    // Legenda
    .voting-legenda {
        clear: both;
        margin-bottom: 20px;

        .legenda-label {
            float: left;
            width: 60px;
        }

        .result-item {
            display: block;
            float: right;
            width: calc( 100% - 60px );
            text-align: left;
        }

        &:after {
            content: " ";
            display: block;
            clear: both;
        }
    }
}
