//
//  Settings group on smaller devices
//  - shows checkmarks and a (hover)menu for selecting
//
$typeSize: 24px;

.my-preferences {
    .label, .select-all { display: none; }
    .title { flex: 0 0 200px; }

    .type-label, .setting {
        width: $typeSize;
        line-height: $typeSize;
    }

    label {
        display: block;
        line-height: 30px;

        * { pointer-events: none; }
    }


    .toolbar .options {
        display: flex;
        flex-flow: row nowrap;

        .button { flex: 0 0 50%; }
    }
}

.settings-group { margin-bottom: 50px; }

.setting-holder {
    padding: 7px 0px;

    .title { font-size: var(--font-size-small); }
    .select-all { display: none; }
    .icn { color: $softGrey; }
    .types { position: relative; }

    .title, .type-mobile, .opt { pointer-events: none; }
    .pointer { pointer-events: visible; }

    .type-menu {
        position: absolute;
        pointer-events: none;
        transform-origin: center center;
        transition: all 0.15s linear;
        padding: 20px;
        background-color: $white;
        box-shadow: 0px 0px 3px rgba( 0, 0, 0, 0.15 );
        width: 190px;
        top: 50%;
        left: -70px;
        opacity: 0;
        transform: translateY( -50% ) scale( 0.3 );
        border-radius: 3px;
        z-index: 1;

        .label { display: inline-block; }
        .check { margin-right: 10px; }

        .setting {
            display: block;
            width: 100%;
            text-align: left;
            margin: 4px 0px;
        }

        &:before {
            content: " ";
            display: block;
            position: absolute;
            left: -2px;
            top: 50%;
            transform: rotate(-45deg) translateY(-50%);
            width: 16px;
            height: 16px;
            background-color: $white;
        }
    }

    &.active {
        .type-menu {
            transform: scale( 1 ) translateY( -50% );
            opacity: 1;
            pointer-events: visible;
        }
    }
}
