//
//  List of proposals
//
.modal-meeting-item-proposals .proposals-list {
    margin-bottom: 40px;

    & > li {
        display: flex;
        flex-flow: row nowrap;
        padding: 20px 0px;
        border-bottom: 1px solid var(--e4);
    }

    .prop-icon, .prop-vote { flex: 0 0 auto; }

    .prop-info {
        flex: 1 1 auto;
        margin: 0px 25px 0px 15px;
    }

    .prop-vote, .prop-menu {
        position: relative;
        flex: 0 0 100px;
        align-self: flex-start;
        text-align: right;
    }

    .prop-vote {
        white-space: nowrap;
    }

    .prop-menu .fold-out {
        text-align: left;
    }

    [type="radio"] {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 1px;
        height: 1px;
        opacity: 0;
    }

    label {
        float: none;
        width: 44px;
        line-height: 44px;
        text-align: center;
        color: var(--vbtn-color);

        .icn {
            font-weight: var(--vbtn-weight);
            font-size: var(--vbtn-size);
            transition: all 0.15s linear;
            pointer-events: none;
        }

        &:hover {
            .icn {
                transform: rotate(-10deg);
                transform-origin: bottom left;
            }

            &.label-deny .icn {
                transform: rotate(10deg);
                transform-origin: top left;
            }
        }
    }

    :checked + label {
        --vbtn-weight: bold;
        --vbtn-size: 22px;
        --vbtn-color: var(--vbtn-for);

        &.label-deny { --vbtn-color: var(--vbtn-deny); }
    }

    .author-info {
        color: var(--alt-grey);
        font-size: var(--font-size-small);
        display: flex;
        flex-flow: row nowrap;
    }

    .description { word-break: break-word; }

    .author { flex: 1 1 auto; }
    .modified { text-align: right; }
    .description, .author-info { margin: 5px 0px; }

    // Vote results
    .prop-votes {
        font-size: var(--font-size-small);

        .prop-for, .prop-deny {
            margin-right: 15px;
            color: var(--alt-grey);
        }

        .square {
            background-color: var(--sq-bg);
            display: inline-block;
            border-radius: 3px;
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }

        .prop-for { --sq-bg: var(--vbtn-for); }
        .prop-deny { --sq-bg: var(--vbtn-deny); }

        button {
            font: inherit;
            border: 0px;
            background: transparent;
            color: var(--focus-color);
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }

        &.active button .icn {
            transform: rotate(180deg);
            top: -2px;
        }
    }


    // Vote details (fold-out)
    .prop-details {
        padding: 15px 20px;
        background-color: var(--f5);
        border-radius: 5px;
        display: flex;
        flex-flow: row nowrap;
        font-size: var(--font-size-small);

        h5 {
            margin-bottom: 0px;
        }
    }

    .prop-votes:not(.active) .prop-details { display: none; }

    // Show / hide elements when item is a user own proposal

    // Show / hide the 'my proposal' tag
    li:not(.my-proposal) {
        .tag { display: none; }
            .prop-menu { display: none; }
    }

    li.my-proposal {
        .prop-vote { display: none; }
        .prop-menu { display: block; }
    }
}
