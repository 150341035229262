//
//  Message
//  - generic message / alert component
//
.message {
    padding: 15px;
    background: $f5;
    position: relative;
    clear: both;
    min-width: 300px;
    border-radius: 3px;
    margin: 10px auto;
    display: flex;
    flex-flow: row nowrap;
    
    @media print {
        display: none;
    }
    
    &:after {
        display: block;
        clear: both;
        content: " ";
    }
    
    p { margin: 5px 0px; }
    
    .close {
        color: var(--black);
        font-size: var(--font-size-smaller);
        display: block;
        position: absolute;
        right: 10px;
        top: 10px;
        padding-right: 20px;
        line-height: 14px;
        min-height: 15px;
        cursor: pointer;
        text-decoration: none;
        
        &:hover, &:active { text-decoration: underline; }
    }
    
    .icn {
        font-size: var(--font-size-large);
        flex: 0 0 24px;
        text-align: center;
        display: block;
        margin-right: 10px;
        margin-top: 1px;
    }
    
    .message-content {
        flex: 1 1 auto;
        line-height: 1.4;

        h2, h3 {
            margin-bottom: 0px;
            font-size: var(--font-size);
            line-height: 1.4;
            color: inherit;
        }

        p { margin-top: 0px; }
    }
    
    //
    //  Color classes
    //
    &.error, &.red {
        background-color: $backRed;
        border-color: $red;
        
        h3, a {
            color: $txtRed;
            border-color: $red;
        }
        
        .icn { color: $red; }
    }
    
    &.notice, &.blue {
        background-color: $backBlue;
        border-color: $blue;
        
        h3, a {
            color: $txtBlue;
            border-color: $blue;
        }
        
        .icn { color: $blue; }
    }
    
    &.green, &.succes {
        background-color: $backGreen;
        border-color: $green;
        
        h3, a {
            color: $txtGreen;
            border-color: $green;
        }
        
        .icn { color: $green; }
    }
    
    &.warning, &.yellow {
        background-color: $backYellow;
        border-color: $yellow;
        
        h3, a {
            color: $txtYellow;
            border-color: $yellow;
        }

        .icn { color: $orange; }
    }
    
    &.grey {
        background-color: $f5;
        border-color: #cccccc;
    }
}
