//
//  New filter modal
//  - modal used to add filters to the meeting tools overview
//
.modal-add-new-filter {
    --sl-col: 1;
     --lbl-icn: var(--e4);
    --lbl-bg: var(--f5);
    --lbl-txt: var(--dark-grey);

    [data-toggle-parent] {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        margin: 0px;
        text-align: left;
        background-color: var(--f5);
        border: 0px;
        font-weight: bold;

        .name { flex: 1 1 auto; }
        .icn { color: var(--light-grey); }
        .amount { font-weight: normal; }
    }

    form .toolbar { margin-top: 50px; }

    .filter-group {
        margin: 10px 0px;
        border: 1px solid var(--soft-grey);
        border-radius: 5px;
        transition: all 0.15s linear;
        background-color: var(--white);

        .fold-out {
            padding: 15px;
            margin-top: 10px;
        }

        &:not(.active) {
            .fold-out, .fa-minus { display: none; }
        }

        &.active .fa-plus { display: none; }
    }

    .select-list {
        list-style: none;
        margin: 0px;
        padding: 0px;
        column-gap: 20px;
        column-count: var(--sl-col);
        column-fill: balance;

        --webkit-perspective: 1;
        min-height: 100px;

        li {
            position: relative;
            margin: 0px;
        }

        label {
            break-inside: avoid-column;
        }
    }

    .select-list, .period-filter .presets {
        label {
            background-color: var(--lbl-bg);
            padding: 5px 10px;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            border-radius: 3px;
            color: var(--lbl-txt);
            margin-bottom: 10px;
            float: none;

            .name { flex: 1 1 auto; }

            .icn {
                flex: 0 0 auto;
                align-self: center;
                color: var(--lbl-icn);
            }
        }

        [type="radio"], [type="checkbox"] {
            position: absolute;
            left: -20px;
            opacity: 0;
            width: 1px;
            height: 1px;
        }

        :checked + label {
            --lbl-txt: var(--white);
            --lbl-icn: var(--lbl-color);
            --lbl-bg: var(--green);
        }

        .filter-label-wrapper {
            position: relative;
        }
    }

    .no-content {
        text-align: center;
        margin: 50px 0px;
        font-style: italic;
    }

    @media ( min-width: $mobileLandscape ) {
        .has-columns { --sl-col: 2; }
    }


    // Styling for period filter in the modal
    .period-filter {

        @media( min-width: $mobileLandscape ) {

            display: grid;
            grid-template-columns: 50% 50%;

            .period-type-filter {
                grid-column: 1 / span 2;
            }
        }

        p { font-size: var(--font-size-small); margin-bottom: 0px; }

        h4, .question {
            font-size: var(--font-size);
            margin-bottom: 0px;
        }

        .field-group + .field-group { margin-top: 20px; }

        .presets {
            @media( min-width: $mobileLandscape ) {
                flex: 0 0 200px;
                margin-right: 20px;
            }
        }

        .presets .field-group {
            padding-top: 0px;
            margin-top: 0px;
        }

        .fields {
            @media( max-width: ( $mobileLandscape - 1px ) ) {
                clear: both;
                padding-top: 20px;
            }

            @media( min-width: $mobileLandscape ) {
                max-width: 200px;
            }

            label {
                font-weight: bold;
                flex-basis: 40px;
            }
        }
    }

    // SD-52946 Sticky cancel / apply buttons
    form > .toolbar {
        position: sticky;
        bottom: 0px;
        padding: 15px;
        background-color: var(--white);
        margin: 0px -15px;

        // Fix position issue in chrome.
        // When you're zoomed in it (occasionally) creates a single pixel transparant space below
        // the toolbar where you can see the selected items scrolling behind
        outline: 1px solid var(--white);
    }

    .window-overflow:after {
        display: none;
    }

    @media
        ( min-width: $mobileLandscape ) and
        ( max-width: ( $portrait - 1px )) {
         form > .toolbar {
            position: relative;
         }
    }
}
