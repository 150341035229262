//
//  Sidebar
//

$sidebar-breakpoint: $mobileLandscape;

.sidebar {
    --panel-txt: var(--dark-grey);
    --panel-bg: var(--white);
    --panel-border: var(--e4);
    --panel-offset: 20px;
    --panel-hightlight: var(--f5);

    h3 {
        font-size: var(--font-size-larger);
        margin-top: 30px;
    }

    &.dark {
        --panel-txt: var(--white);
        --panel-bg: var(--dark-grey);
        --panel-border: var(--medium-grey);
        --panel-hightlight: var(--medium-grey);
    }

    position: fixed;
    right: 0px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    pointer-events: none;
    visibility: hidden;
    z-index: 10;
    color: var(--panel-txt, var(--white));

    .panel {
        width: 600px;
        max-width: calc( 100vw - 50px );
        height: 100%;
        overflow: hidden;
        position: absolute;
        right: 0px;
        z-index: 12;
        transform: translateX(100%);
        transition: all 0.2s linear;
        box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.25 );
    }

    .overflow {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        background-color: var(--panel-bg, var(--dark-grey));
        box-shadow: 0px 0px 15px rgba( 0, 0, 0, 0.15 );
        padding: var(--panel-offset);
        position: relative;

        // Create extra space for scrolling to bottom elements to the center of the screen
        &:after {
            content: " ";
            display: block;
            clear: both;
            height: 150px;
        }
    }

    .close-overlay {
        opacity: 0;
        transition: all 0.3s linear;
        background-color: rgba( 255, 255, 255, 0.7 );
    }

    // Options
    &.from-left .panel {
        transform: translateX(-100%);
        left: 0px;
        right: auto;
    }

    &.dark-bg .close-overlay { background-color: rgba( 0, 0, 0, 0.6 ); }

    // Active | open panel
    &.active {
        pointer-events: visible;
        visibility: visible;

        .panel { transform: translateX(0); }
        .close-overlay { opacity: 1; }
    }
}

.sidebar-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .title-holder { flex: 1 1 auto; }
    .options { flex: 0 0 auto; }

    .title {
        font-size: var(--font-size-larger);
        margin: 0px;
        line-height: 1.2;
    }
}

.sidebar-fixed-bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 20px;
    background: linear-gradient( to bottom , rgba( $white, 0 ), rgba( $white, 0.7 )) ;
    min-height: 80px;

    .button { display: block; }

    // Flex on larger screens
    @media ( min-width: $sidebar-breakpoint ) {
        display: flex;
        flex-flow: row wrap;

        .button { flex: 1 1 auto; }
    }
}

// Default elements in sidebar
.sidebar {
    h3 {
        font-size: calcFontSize(16pt);
        margin-top: 30px;
    }
}
