//
//  Search Suggestions
//  - small list with search suggestions
//  - fulled/updated with JS
//

:root {
    --suggestions-bg: var(--white);
    --suggestions-odd: var(--f5);
    --suggestions-txt: var(--text-color);
    --suggestions-hover: var(--back-blue);
    --suggestions-focus: var(--focus-color);
    --suggestions-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
    --suggestions-border: var(--suggestions-bg);
}

.suggestions-dropdown {
    position: absolute;
    top: 100%;
    left: 0px;
    border: 1px solid var(--suggestions-border);
    background-color: var(--suggestions-bg);
    border-radius: 0px 0px 5px 5px;
    box-shadow: var(--suggestions-shadow);
    width: 100%;
    min-height: 30px;
    z-index: 1;
    display: none;

    .spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: var(--alt-grey);
        font-size: 20px;
    }

    &.opened, &:focus-within {
        display: block;
    }

    &.loaded {
        .spinner { display: none; }
    }

    .no-content {
        font-style: italic;
        color: var(--alt-grey);
        font-size: var(--font-size-small);
    }

    .clear-list li {
        border-top: 1px solid color-mix( in srgb, var(--suggestions-odd) 90%, black );
        font-size: 1.1em;
        line-height: 30px;
        color: var(--suggestions-txt);
        padding: 2px;
        text-align: left;
        word-break: break-word;

        button {
            all: unset;
            cursor: pointer;
            display: block;
            width: 100%;
            padding: 5px;
            box-sizing: border-box;

            * { pointer-events: none; }

            &:hover {
                background-color: var(--suggestions-hover);
            }
        }

        &.focused button {
            outline: 2px solid var(--suggestions-focus);
            background-color: var(--suggestions-hover);
        }

        &:nth-of-type(even) {
            background-color: var(--suggestions-odd);
        }

        &:first-child {
            border-top: 0px;
        }
    }
}
