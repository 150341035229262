//
//  Tabs
//  - styling for tab controls and content
//
.tab-widget {
    --tab-border: #cdd6de;
    --tab-padding: 50px;

    // Tab controls
    .tab-controls {
        margin-top: 50px;
        border-bottom: 1px solid var(--tab-border);
        display: flex;
        flex-flow: row nowrap;

        @media ( min-width: $landscape ) {
            justify-content: flex-start;
        }

        .tab {
            background-color: transparent;
            border: 1px solid transparent;
            border-bottom-color: var(--tab-border);
            text-align: center;
            padding: 8px;
            display: inline-block;
            font: inherit;
            font-size: var(--font-size-large);
            font-weight: bold;
            color: inherit;
            margin-bottom: -1px;
            margin-right: 2px;
            transition: all 0.15s linear;
            flex: 1 1 auto;
            cursor: pointer;

            * { pointer-events: none; }

            @media ( min-width: $landscape ) {
                padding: 8px 20px;
                flex: 0 0 auto;
            }

            @media ( min-width: $desktopLarge ) {
                padding: 8px 40px;
            }

            &:hover { background-color: $f5; }
            .label { display: none; }

            @media ( min-width: $mobileLandscape ) {
                .label { display: inline-block; }
                .icn { display: none; }
            }

            &.active {
                background-color: $white;
                border: 1px solid var(--tab-border);
                border-bottom-color: $white;

                .icn { color: var(--primary); }
            }
        }
    }

    // Tab content
    .tab-content {
        position: relative;
        box-sizing: border-box;
        padding-top: var(--tab-padding);
        min-height: 200px;

        &:not(.active) {
            display: none;
            visibility: hidden;
            pointer-events: none;
        }
    }

    .loading-placeholder {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: -2px;
        right: -2px;
        width: auto;
        background-color: $white;
        text-align: center;
        display: none;
        z-index: 1;

        .loading-txt {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

            .icn {
                font-size: 36px;
                color: $softGrey;
            }
        }

        .loading-label {
            font-size: var(--font-size-small);
            display: block;
            margin-top: 10px;
        }
    }

    .is-loading {
        .loading-placeholder { display: block }
    }

    // More menu, when there are to many tabs
    .more-menu {
        margin-left: auto;

        .active { background-color: $backGreen; }
        .fold-out { overflow-y: auto; }
    }

    // Features
    &.bordered {
        .tab-content {
            border: 1px solid var(--tab-border);
            border-top: 0px;
            padding: var(--tab-padding);
        }
    }
}
