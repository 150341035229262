//
//  Form elements
//  - contains styling of several generic form elements
//  - gradually replaces formulier.scss
//
$field-break: $mobileLandscape;

// Reset class
.clear-form {
    &, fieldset {
        background: none;
        border: 0px;
        padding: 0px;
        margin: 0px;
    }
    
    fieldset { margin-bottom: 10px; }
}

// Label & input container
.field-group {
    position: relative;
    clear: both;
    margin: 10px 0px;
    font-size: var(--font-size);
    font-family: $open-sans;
    color: $darkGrey;
    line-height: 16px;
    
    @media ( max-width: ( $field-break - 1px ) ) {
        label, .question, h4, input, textarea, .select, .answers, .input, button, .button, .icn, .textarea {
            display: block;
            width: 100%;
        }
    }
    
    // Flex layout
    @media ( min-width: $field-break ) {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        label, .question { flex: 0 0 130px; }
        h4, input, textarea, .select, .answers, .input, .textarea { flex: 1 1 auto; }
        button, .button, .icn { flex: 0 0 auto; }
    }
    
    // Reset elements in field-group
    label, input, textarea, select, .select, .answers {
        display: block;
        font: inherit;
        color: inherit;
        line-height: inherit;
        box-sizing: border-box;
        float: none;
    }
    
    // Break layout
    &.break {
        display: block;
        
        h4, input, textarea, .select, .answers, .input { width: 100%; }
        label, .question { font-weight: bold; }
    }
    
    //
    // Restyle elements
    //
    
    // Labels
    h4, label, .question {
        font-size: var(--font-size);
        line-height: inherit;
        padding: 7px 0px;
        color: $darkGrey;
        border: 1px solid transparent; // Counter offset created by input border
        margin: 0px;
    }
    
    label { cursor: pointer; }
    
    // Input elements
    input, textarea, select, .select {
        border-radius: 3px;
        border: 1px solid $input-border;
        line-height: inherit;
    }
    
    input, textarea, select {
        padding: 7px;
        transition: all 0.15s linear;
        appearance: none;
        -moz-appearance: none;
    }

    // Change padding of select and increase line-height to prevent clipping of lowercase 'g' characters
    select {
        padding: 5px;
        line-height: 19px;
    }
    
    .delimiter {
        flex: 0 0 14px;
        text-align: center;
        padding: 0px 5px;
        align-self: center;
    }
    
    .icn {
        display: inline-block;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 3px;
        transition: all 0.15s linear;
        align-self: center;
        cursor: pointer;
    }
    
    .button:last-of-type {
        margin-right: 0px;
    }
    
    .button .icn {
        width: auto;
        height: auto;
        line-height: inherit;
    }
    
    .hover-menu, & > .button {
        margin: 0px 5px;
    }
    
    // Icons in labels
    label, .question {
        .icn {
            line-height: inherit;
            height: auto;
            width: 24px;
        }
        
        &:hover .icn { background-color: transparent; }
    }
    
    // IE11 hide clear
    .datepicker::-ms-clear {
        display: none;
    }
}

//
//  Fancy form elements
//

// Select
.select {
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: $white;
    
    select {
        border: 0px;
        background-color: transparent;
        width: 100%;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        padding-right: 30px;
        cursor: pointer;
        font-family: inherit;
        outline: 1px solid transparent;
        outline-offset: 0px;
        margin: 0px;
            
        // FF hack
        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
            transition: none;
        }

        &:focus { outline: 3px solid var(--focus-color); }
    }
    
    // IE11 Hack
    select::-ms-expand { display: none; }

}

// Input holder, for floating (overlaying ) icons and limited fields
.input {
    position: relative;
    box-sizing: border-box;
    
    input {
        width: 100%;
        padding-right: 30px;
    }
}

// Icon positioning
.select > .icn, .input > .icn {
    position: absolute;
    right: 2px;
    top: 2px;
    pointer-events: none;
    color: getVar(--primary, $primary);
}

// Fancy button-like radio or checkbox
.checker {
    flex: 0 0 110px;
    margin-right: 10px;
    
    input[type="radio"], input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        pointer-events: none;
    }
    
    label {
        border-radius: 3px;
        border: 1px solid $softGrey;
        transition: all 0.15s linear;
        background-color: $f5;
        padding: 7px 10px;
        color: $lightGrey;
        user-select: none;
        -moz-user-select: none;
        
        .icn { color: $softGrey; }

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }
    
    input:checked + label {
        background-color: $backGreen;
        border-color: $green;
        color: $txtGreen;
        
        .icn { color: $green; }
    }
    
    &.false input:checked + label {
        background-color: $backRed;
        border-color: $red;
        color: $txtRed;
        
        .icn { color: $red; }
    }
}

// Predefined field-group input sizes
.field-group {
    .datepicker { max-width: 125px; }
    .time { max-width: 55px; }
    .short { max-width: 100px; }
    .medium { max-width: 200px; }
    .long { max-width: 300px; }
}

// Custom time input
.input.time {
    input { padding-right: 20px; }
    
    .controls {
        position: absolute;
        right: 0px;
        top: 2px;
        display: flex;
        flex-flow: column nowrap;
        
        .icn {
            height: 10px; // Icon in input style is 28x28
            line-height: 10px;
            color: $softGrey;
            padding-top: 4px;
            
            &:hover {
                color: getVar(--primary, $primary);
                cursor: pointer;
            }
            
            & + .icn {
                padding-top: 0px;
                padding-bottom: 4px;
            }
        }
    }
}


// Fancy checkbox
.checkbox {
    position: relative;

    &, * { transition: all 0.15s linear; }

    input {
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    label {
        display: flex;
        flex-flow: row nowrap;
        cursor: pointer;
        border-radius: 3px;
        padding: 2px;

        * { pointer-events: none; }

        &:hover { background-color: var(--f5); }
    }

    & + .checkbox { margin-top: 10px; }

    .icn {
        flex: 0 0 auto;
        display: block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        background-color: $white;
        border: 1px solid $input-border;
        border-radius: 3px;
        color: $white;
        margin-right: 10px;
        font-size: var(--font-size-small);
    }

    :checked + label .icn {
        background-color: var(--green);
        border-color: var(--green);
    }

    .label {
        display: block;
        flex: 1 1 auto;
        line-height: 1.2;
        padding-top: 2px;
        word-break: break-word;
    }

    input + label { float: none; }
    .color-orb { align-self: center; }
}

// File input tricks
input[type="file"] {
    &:focus + label.button { outline: 2px dotted blue; }
}
