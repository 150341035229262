//
//  Metadata
//  - contains styling for the metadata elements in the agenda-row content fold-out
//
.agenda-row-content {

    // Metadata columns and finetuning
    .metadata {
        padding-left: 0px;
        padding-right: 0px;

        .col {
            margin-bottom: 20px;
        }

        .meta-block {
            padding: 10px 15px;
            background: var(--f5);
            border-radius: 5px;
            color: #6f6f6f;

            .high-contrast & {
                color: var(--black);
            }
        }

        dt {
            flex-basis: 100px;
        }

        a {
            display: block;
            position: relative;
            padding: 0px 0px 0px 2px;
            border-radius: 2px;

            .date {
                display: block;
                font-size: var(--font-size-smaller);
            }

            &:hover {
                background-color: var(--f5);
                .icn { color: var(--light-grey); }
            }
        }
    }

    .agenda-items {
        display: block;

        a {
            display: block;
            padding: 5px;
            border-radius: 3px;
            color: inherit;
            text-decoration: none;
            background-color: var(--f5);
            border-top: 1px solid var(--e4);
            padding-right: 30px;

            &:hover { background-color: var(--white); }

            .icn-right {
                position: absolute;
                inset: 10px 1px auto auto;
            }
        }
    }
}
