//
//  Basic layout of the timeline
//

// Define timeline-holder as container for container queries
.timeline-holder {
    container: timeline / inline-size;
}

// Grid layout for a row / activity
.timeline-row {
    display: grid;
    gap: var(--tl-gap);
    margin-bottom: 15px;
    position: relative;

    // Default layout, icons in the left column icons and the info in the right column
    grid-template-columns: 40px auto;
    grid-template-areas: "icn left";
    align-items: flex-start;

    .timeline-icn { grid-area: icn; z-index: 2; }
    .timeline-act { grid-area: left; }

    @container timeline (min-width: #{ $breakpoint } ) {
        --tl-gap: var(--tl-gap-larger);
        grid-template-columns: 1fr var(--tl-icn-size) 1fr;
        grid-template-areas: "left icn right";
        margin-bottom: 30px;

        &.documents, &.status-update {
            .timeline-act { grid-area: right; }
        }
    }

    &.filtered { display: none; }
}

// Place a line behind the icons using a after
// Has an extra after to hide the end part of the line
.timeline-list-holder {
    position: relative;

    // Create line, hider line and position both left half-way of the icon
    &, li:last-of-type .timeline-row:last-of-type {
        &:after {
            content: " ";
            position: absolute;
            transform: translateX(-50%);
            width: var(--tl-line-width);
            inset: 10px auto 10px calc(var(--tl-icn-size) / 2);
        }
    }

    &:after {
        background-color: var(--tl-line-bg);
        z-index: -1;
    }

    li:last-of-type .timeline-row:last-of-type {
        --tl-line-width: 20px;

        &:after {
            bottom: -10px;
            background-color: var(--white);
            z-index: 1;
        }
    }


    // On larger screens the layout changes to three columns with the icons in the center, so place the line also in the center
    @container timeline ( min-width: #{ $breakpoint } ) {
        &, li:last-of-type .timeline-row:last-of-type {
            &:after {
                left: 50%;
            }
        }
    }
}
