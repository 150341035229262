//
//  Dashboard agenda
//
.tab-agenda {
    h3 {
        font-size: var(--font-size-large);
        font-weight: bold;
    }

    .dashboard-tab-content {

        @media ( min-width: $landscape ) {
            display: flex;
            flex-flow: row nowrap;
        }

        .event-holder {
            flex: 1 1 auto;
            min-width: 0;
        }

        .filters {
            flex: 0 0 260px;
        }
    }

    .month-group { margin-bottom: 40px; }
    .month-header {
        font-weight: bold;
        text-transform: capitalize;
    }

    .activity {
        display: flex;
        flex-flow: row nowrap;
        box-shadow: 0px 0px 3px rgba( 0, 0, 0, 0.08 );
        transition: all 0.15s linear;
        background-color: var(--white);
        border: 1px solid var(--soft-grey);
        margin-bottom: 15px;
        padding: 10px;

        &:hover {
            background-color: var(--back-blue);
            border-color: var(--blue);
        }

        .daynr {
            flex: 0 0 2ch;
            text-align: right;
            font-size: calcEmFontSize(24pt);
            color: var(--primary);
            font-weight: bold;
            line-height: 1.4;
        }

        .pointer {
            flex: 0 0 24px;
            text-align: center;
            color: var(--primary);
        }

        .info {
            flex: 1 1 auto;
            margin: 0px 15px;
            min-width: 0;
            position: relative;
        }

        .title {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
        }
    }

    .meta-lint {
        margin: 0px;
        font-size: var(--font-size-small);

        .icn { font-size: var(--font-size); }
    }

    .meeting-activity {
        .daynr { color: var(--secondary); }
    }

    .past-event {
        --tag-bg: var(--f5);
        &, .daynr, .pointer, .tag {
            color: var(--light-grey);
        }
    }

    // Hide events or past (used with JS)
    .hide-past {
        .past-event { display: none; }
    }

    .hide-events {
        .event-activity { display: none; }
    }
}
