//
// Extra color schemes
//

// Color variations
.primary-footer {
    --footer-bg-color: var(--hsl-primary-color);
    --footer-bg-lum: var(--hsl-primary-lum);
    --footer-bg: var(--primary);
}

.secondary-footer {
    --footer-bg-color: var(--hsl-secondary-color);
    --footer-bg-lum: var(--hsl-secondary-lum);
    --footer-bg: var(--secondary);
}

.light-footer {
    --footer-bg-color: 204, 9%;
    --footer-bg-lum: 89%;
    --footer-color: var(--medium-grey);
    --footer-bg: #e0e3e5;
    --copybar-txt: var(--footer-color);

    .high-contrast & {
        --footer-color: var(--black);
        --footer-bg: var(--white);

        footer {
            border-top: 1px solid var(--black);
        }
    }
}
