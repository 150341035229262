//
//  Content headers
//  - contains styling for content-header, toolbar and options element
//
$top-offset: 5px; // Should match button padding top

// For all
.content-header, .toolbar, .options, .block-header {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    box-sizing: border-box;
    
    h2, h3, h4, h5, .title { margin: 0px; }
    h2, h3, h4 { font-weight: bold; }
    h4, h5 { font-size: var(--font-size); }
    
    h2 { font-size: var(--font-size-large); }
    h3 { font-size: var(font-size-larger); }

    .title, .title-holder, .spacer { flex: 1 1 auto; }
    .options, .pointer { flex: 0 0 auto; }
    
    .title-holder { min-width: 0; }

    // Overwrite position relative for nonvisual elements
    &.nonvisual {
        position: absolute;
    }

    &.right-aligned {
        justify-content: flex-end;
    }

    .field-group {
        margin: 0px;
        line-height: 15px;
    }
}

//
//  Content header
//  - contains title
//  - optional subtitle and options
//  - optional toggle pointer
//
.content-header {
    margin: 10px 0px 10px 0px;
    
    .title-holder { padding-top: $top-offset; }
}

//
//  Toolbar
//  - contains options elements
//  - but no titles or pointer
//
.toolbar {
    justify-content: space-between;
    margin-bottom: 10px;
}

//
//  Options
//  - generic options element, used in toolbars or content-headers
//  - wrapps buttons and options
//
.options, .quicksearch {
    align-self: flex-start;
    align-items: center;
}

//
//  Pointer element
//  - toggle-able element / button, used in content-headers to suggest show / expand / contract interaction
//
.pointer {
    align-self: flex-start;
    
    .icn {
        transform-origin: center center;
        transition: all 0.15s linear;
    }
    
    .label.in { display: none; }
}

.active {
    .content-header, .agenda-row-head {
        .pointer {
            .icn { transform: rotate(90deg); }
            .label.in { display: inline-block; }
            .label.out { display: none; }
        }
    }
}

//
//  Block header
//  - content-header look-a-like
//  - boxed header with background and border
//
.block-header {
    border: 1px solid $softGrey;
    background-color: $white;
    align-items: center;
    padding: 2px 2px 2px 10px;
    min-height: 40px;
    color: $darkGrey;
    
    .options { align-self: center; }
    
    .title-holder {
        padding: 8px 0px;
    }
    
    .title {
        color: var(--content-header-color);
        font-weight: bold;
        overflow: hidden;
        word-wrap: break-word;
        text-overflow: ellipsis;
        line-height: 20px;
    }
    
    .meta-lint, .subtitle {
        margin: 0px;
        font-size: var(--font-size-small);
    }
}

//
//  Split mobile
//  - different layout for mobile portrait, placing the options below the title
//  - and making options flexible (field-group has auto grow)
//
@media (max-width: ($mobileLandscape - 1px)) {
    .split-mobile {
        display: block;

        .options { display: flex; }
        .field-group { flex: 1 1 auto; }
    }
}
