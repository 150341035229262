//
//  Tweaks for district views
//
.district-holder {
    @media ( min-width: $landscape ) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .overview-intro, .theme-tab { flex: 0 0 49%; }
    }
}
