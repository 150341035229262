//
//  Dossier details
//
.dossier-page {

    .timeline-and-attachments {
        padding-top: 0px;
        position: relative;
        background-color: var(--white);
        z-index: 1;
    }

    .involved-row { padding-bottom: 0px; }

    // Less padding on smaller screens for tabs and timeline/documents
    @media (max-width: ($mobileLandscape - 1px )) {
        .involved-row, .timeline-and-attachments {
            --ih-padding: 0px;
        }
    }
}
