//
//  Details modal
//  - extra styling for the details modal
//

.activity-details-modal {

    .icn.red { color: var(--red); }
    .icn.green { color: var(--green); }

    // Loading placeholder
    .window-content { min-height: 120px; }

    .loading-placeholder {
        text-align: center;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        transform: translateY(-50%);

        .icn {
            font-size: 24px;
            color: $softGrey;
        }
    }

    &.is-loading {
        .loading-placeholder {
            opacity: 1;
            pointer-events: visible;
        }

        .info-holder { display: none; }
    }

    // For voting
    .voting .row { padding-top: 0px; }
    .voting-header { margin-top: 30px; }
}
