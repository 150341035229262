//
//  Color orb / circle
//  - small colored circle with extra classes for the standard colors
//

.color-orb, .color-circle {
    --brown: #9e5726;
    --blue: #0067A3;
    --co-size: 10px;
    --co-bg: var(--ee);
    --co-txt: var(--white);
    width: var(--co-size);
    height: var(--co-size);
    border-radius: var(--co-size);
    display: inline-block;
    margin: 0px 10px 0px 7px;
    flex: 0 0 auto;
    border: 1px solid var(--co-bg);
    box-sizing: border-box;
    font-size: 0.85rem;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;

    // Color classes
    @each $color in $colors {
        $name: nth($color, 1);
        $value: nth($color, 2);

        &.#{""+$name} {
            --co-bg: var(#{"--"+$name});
        }
    }

    // Darker color text
    &.yellow { --co-txt: var(--dark-grey); }
    &.brown { --co-bg: var(--brown); }

    // Sizes
    &.size-20 { --co-size: 20px; }
    &.size-30 { --co-size: 30px; }
    &.size-5 { --co-size: 5px; }
}

.color-orb {
    background-color: var(--co-bg);
    color: var(--co-txt);
    outline: 1px solid var(--white);
}
