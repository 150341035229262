//
//  Styling of menu and items
//
.menu-holder {

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
    }

    li {
        position: relative;
        box-sizing: border-box;

        &.invisible {
            visibility: hidden;
            opacity: 0;
            background-color: red;
            pointer-events: none;
            display: none;
        }

        &:hover, &:focus-within {
            .menu-item {
                background-color: $header-background-dark;
            }
        }
    }
    
    .menu {
        display: flex;
        flex-flow: row wrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    .menu-item {
        text-decoration: none;
        font-size: 1.1em;
        color: inherit;
        padding: 15px 25px;
        line-height: 20px;
        display: block;
        border-radius: 3px 3px 0px 0px;
        margin-left: 0px;
        transition: all 0.2s linear;
        font-weight: bold;
    
        &:hover { color: rgba(255, 255, 255, 0.7); }
    
        &.active {
            background-color: getVar( --header-active, $primary);
            color: inherit;
            font-weight: bold;
            text-shadow: 0px 2px rgba( 0, 0, 0, 0.15 );
        
            &:hover { color: rgba( 255, 255, 255, 0.7 ); }
        }
    }
}

