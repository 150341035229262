//
//  LTA
//  - styling for LTA iframe
//
.lta-button {
    @media ( min-width: $portrait ) {
        display: none;
    }
}

.lta-iframe-holder, .lta-iframe {
    box-sizing: border-box;
    width: 100%;
    height: 70vh;
    background-color: $white;
    position: relative;
}

.lta-iframe {
    border: 0px;
    display: none;
    z-index: 1;
}

.lta-iframe-holder {
    outline: 1px solid $softGrey;
    margin-bottom: 50px;
    display: block;
    position: relative;

    @media ( max-width: ( $portrait - 1px ) ){
        display: none;
    }
    
    .spinner {
        pointer-events: none;
        transition: all 0.15s linear 0.15s;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        z-index: 2;
        background-color: inherit;
        
        .icn {
            font-size: 36px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform-origin: center center;
            transform: translate(-50%, -50%);
            color: $softGrey;
        }
    }
    
    &.iframe-loaded {
        .lta-iframe { display: block; }
        .spinner { opacity: 0; }
    }
}

.lta-iframe-page {
    #content {
        display: flex;
        flex-flow: column nowrap;
        
        & > div {
            flex: 0 0 auto;
            width: 100%;
        }
    }
}

// Wide page variant
.lta-iframe-page.lta-wide {
    .content-sidebar { display: none; }
    
    #content {
        float: none;
        width: 100%;
        max-width: 100%;
    }
    
    .lta-frame-content { order: 2; }
}
