//
//  Download
//  - styling for the download document views
//

// Download secured document warning
.document-secured {
    .secure-doc-info {
        background-color: var(--f5);
        border: 1px solid var(--e4);
        padding: 15px;
        margin: 30px 0px;

        h2 {
            font-size: var(--font-size-small);
            margin: 0px;
            line-height: 1;
            margin-bottom: 5px;
        }
    }

    @media ( max-width: ( $mobileLandscape - 1px ) ) {
        .button { width: 100%; }
    }
}

// Download all documents
.download-all-documents {
    position: relative;

    ul {
        margin: 5px 0px;
        padding: 20px 0px;
        border-bottom: 3px dotted var(-soft-grey);
        border-top: 3px dotted var(--soft-grey);
    }

    .toolbar, li {
        clear: both;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .toolbar h2 {
        flex: 1 1 auto;
        font-size: var(--font-size-large);
    }

    .options a {
        color: inherit;
        text-decoration: none;
        line-height: 20px;
        padding: 2px 5px;
        border-radius: 3px;
        display: block;

        &:hover { background-color: var(--f5); }
        .icn { color: var(--blue); }
    }

    li {
        line-height: 24px;
        position: relative;
        margin-bottom: 2px;
    }

    label {
        flex: 1 1 auto;
        cursor: pointer;
        &:hover, &:focus {
            background-color: var(--back-blue);
        }
    }

    input, .options, .button {
        flex: 0 0 auto;
    }

    .file-size {
        font-size: var(--font-size-small);
    }

    $checkbox-green: #488325;

    input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;

        & + label {
            padding:  2px 2px 2px 34px;
            margin: 2px 0px;
            text-overflow: ellipsis;
            overflow: hidden;

            &:before {
                display: block;
                content: "\f00c";
                font-family: $font-awesome;
                font-weight: bold;
                text-align: center;
                color: var(--white);
                transition: all 0.15s linear;
                background-color: var(--white);
                width: 24px;
                height: 24px;
                border-radius: 3px;
                border: 1px solid $input-border;
                position: absolute;
                left: 2px;
            }
        }

        &:checked + label {
            font-style: italic;
            &:before {
                border-color: $checkbox-green;
                background-color: $checkbox-green;
                font-style: normal;
            }
        }

        &:disabled + label {
            color: var(--light-grey);
            cursor: not-allowed;

            &:before {
                background-color: $f5;
                color: $f5;
                border-color: var(--soft-grey);
            }
        }
    }

    // Toolbar en (de)select all options on mobile portrait devices
    @media ( max-width: ( $mobileLandscape - 1px )) {
        .toolbar {
            flex-flow: row wrap;

            h2 { flex: 0 0 100%; }
            .button { flex: 0 1 48%; }
        }

        .download-bottom-toolbar {
            flex-direction: column;

            button {
                display: block;
                width: 100%;
            }
        }
    }
}
