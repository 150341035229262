//
//  History (toggleable block)
//  - shows an overview of previous functions
//

.tab-general {
    .history { margin-top: 50px; }


}


