//
//  Today
//  - styling for the today marker in the term-agenda
//

.term-agenda {
    // Today line in domain/dossier rows
    .today {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        box-shadow: 0px 0px 2px rgba( 0, 0, 0, 0.15 );
        position: absolute;
        inset: auto auto 0px var(--lta-today-offset);
        background-color: var(--lta-today);
        margin-left: calc( var(--lta-left-col) - 5px);
    }

    // Today line in the quarters/months overview head
    .month-grid {
        &:after {
            content: " ";
            position: absolute;
            inset: 0px auto 0px var(--lta-today-offset);
            width: 2px;
            margin-left: -1px;
            background-color: var(--lta-today);
        }
    }
}
