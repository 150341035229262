//
//  Dashboard navigation
//  - styling for the tab-like navigation in the dashboard module
//  - navigation is shown
//      - in sidebar on mobile
//      - from tablet: in a minified tabbar besides content, toggleable between icons only and icon and label
//

.dashboard {
    &-navigation {
        transition: all 0.15s linear;
        overflow-x: hidden;

        ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
            border-bottom: 1px solid var(--e4);
            width: 200px;
        }

        a {
            color: inherit;
            text-decoration: none;
            position: relative;
            line-height: 20px;
        }

        // Tab, icn and label layout
        .tab, .view-link {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .tab-icn, .square-holder {
            position: relative;
            flex: 0 0 40px;
            text-align: right;
            margin-right: 15px;
            line-height: 20px;
        }

        // Main tab style
        .tab {
            padding: 10px 0px;
            line-height: 20px;
            font-size: var(--font-size-large);
            border-left: 3px solid transparent;
            font-weight: 600;
            margin-bottom: 5px;
            position: relative;

            * {
                pointer-events: none;
                display: inline-block;
            }

            &, .icn { transition: all 0.15s linear; }

            .icn {
                color: var(--soft-grey);
                font-size: var(--font-size-larger);
            }

            &:hover, &:focus {
                border-left-color: var(--secondary);

                .icn { color: var(--secondary); }
            }

            &.active {
                border-left-color: var(--primary);
                font-weight: bold;
            }
        }

        .square {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 2px;
            background-color: var(--light-grey);

            &.primary { background-color: var(--primary); }
            &.secondary { background-color: var(--secondary); }
        }

        .toolbar { text-align: center; }

        .toggle-link {
            display: inline-block;
            text-align: center;
            margin-top: 20px;
            font-size: var(--font-size-small);
        }
    }

    &-home-link {
        padding-bottom: 20px;
        border-bottom: 1px solid var(--e4);
        margin-bottom: 10px;

        .tab {
            font-size: var(--font-size-larger);

            .icn { font-size: calcEmFontSize(24pt); }
        }
    }

    &-views {
        margin-bottom: 30px;

        ul { border: 0px; }

        .view-link {
            position: relative;
            padding: 3px 0px 3px 5px;
            border-radius: 3px;
            transition: all 0.15s linear;
            margin-left: 20px;

            &:hover { background-color: var(--e4); }
            .square-holder { flex-basis: 20px; }

            * {
                pointer-events: none;
                display: inline-block;
            }

            &.active {
                font-style: italic;
                background-color: var(--f5);
            }
        }
    }
}

// Styling for minimize menu (toggle)
.navigation-minimized {
    .dashboard {
        &-navigation {
            width: 60px;

            .tab-icn {
                text-align: center;
                flex-basis: 60px;
                margin-right: 0;
            }

            .dashboard-views, .label { display: none; }
            .toggle-link .icn { transform: rotate(180deg); }
        }
    }
}
