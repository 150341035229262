//
//  Dashboard sidebar
//  - mobile sidebar navigation, containing the tabs and the profile/account options
//
.dashboard-sidebar {

    // Hide default header
    .sidebar-header { display: none; }
    .overflow { padding: 0px 0px 50px 0px; }

    .dashboard-sidebar-head, .menu-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        span { flex: 1 1 auto; }
    }

    .dashboard-sidebar-head {
        background-color: var(--f5);
        border-bottom: 1px solid var(--e4);
        padding: 5px 5px 5px 15px;
        margin: 0px;

        .icn {
            flex: 0 0 32px;
            color: var(--primary);
            text-align: center;
        }
    }

    .menu-item {
        display: block;
        border-bottom: 1px solid var(--e4);
        padding: 7px;
        transition: all 0.15s linear;

        span { pointer-events: none; }

        .icn {
            flex: 0 0 24px;
            margin-right: 5px;
            color: var(--soft-grey);
            text-align: center;
        }

        &:hover {
            background-color: var(--f5);
            font-weight: bold;

            .icn { color: var(--secondary); }
        }
    }
}
