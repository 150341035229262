//
//  Document row
//  - row like element for showing document-info and options
//

@use 'sass:math';

.documents-overview-container {
    position: relative;
}

.document-list {
    list-style: none;
    padding-left: 0px;
}

.item-row.document-row {
    padding: 0px;
    
    &:hover { background-color: transparent; }
    &, .doc-link { align-items: flex-start; }
    
    // Flex box
    .doc-link, .doc-meta {
        display: flex;
        flex-flow: row nowrap;
        position: relative;
    }
    
    .doc-info, .doc-link, .doc-meta, .doc-meta .type {
        flex: 1 1 auto;
        min-width: 0;
        width: 100%;
    }

    // IE11 fix for overflow
    .doc-info {
        flex: 1 1 100%;
    }

    // Link holder
    .doc-link {
        text-decoration: none;
        color: inherit;
        border-radius: 3px;
        transition: all 0.15s linear;
        padding: $row-offset math.div($row-offset, 2);

        &:hover { background-color: var(--item-row-hover-bg); }
    }
    
    // Custom meta element
    .doc-meta {
        font-size: var(--font-size-small);

        .icn { color: var(--alt-grey); }
        
        .type {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 20px;
            max-width: calc( 100% - 120px );
            
            @media ( min-width: $portrait ) {
                max-width: 340px;
            }

            @media( max-width: ($mobileLandscape - 1px) ) {
                width: auto;
                flex: 0 0 auto;
            }
        }
        
        .file-info {
            flex: 0 0 15ch;

            @media( max-width: ($mobileLandscape - 1px) ) {
                flex: 0 0 auto;
            }
        }
    }
    
    // Options at end of row
    .options {
        flex: 0 0 auto;
        margin: $row-offset 0px;
        
        & + .options {
            border-left: 1px solid $e4;
            padding-left: 5px;
            margin-left: 5px;
        }
    }
    
    // Fix voting button size for alignment
    .vote-button { min-width: 40px; }
    
    @media ( min-width: $landscape ) {
        .vote-button { width: 160px; }
    }

    // Unread (SD-47826
    &.unread {
        font-weight: bold;
        border-left: 2px solid var(--pdf-red);
        background-color: $f5;

        &:hover {
            background-color: $white;

            .doc-link { background-color: transparent; }
        }
    }

    &:not(.unread) .unread-text {
            display: none;
    }

    &.private {
        .row-icn .icn { color: var(--light-grey); }
        .row-icn .fa-lock {
            color: var(--orange);
            text-shadow: -2px -2px white;
        }
        .confidential-text {
            display: none;
        }
    }

    &:not(.private) {
        .row-icn .fa-lock {
            display: none;
        }
    }

    &:not(.show-annotate) .annotate-button {
        display: none;
    }
}
