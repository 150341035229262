//
//  Styling for the employee / organisation overview
//
.employees-overview {
    @media ( max-width: ( $show-members-breakpoint - 1px )) {
        .groups-grid {
            display: grid;
            grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );
            row-gap: 30px;
            margin-top: 30px;
        }

        .members-grid, .tab-controls { display: none; }

    }

    section:not(:first-of-type).organisation-type-holder h3 { margin-top: 40px; }

    .group {
        border-top: 1px solid $e4;
        scroll-margin-top: 20vh;

        @media ( min-width: $show-members-breakpoint ) {
            display: flex;
            flex-flow: row nowrap;
            padding: 30px 0px;

            .group-card {
                flex: 0 0 190px;
                margin-right: 30px;
            }

            .members-grid { flex: 1 1 auto; }
        }

        .no-content {
            flex: 1 1 auto;
            text-align: center;
            margin-top: 50px;
            font-size: var(--font-size-small);
            color: #666;

            .icn {
                display: block;
                color: $e4;
                font-size: 40px;
                margin-bottom: 20px;
            }
        }

        &:target {
            background-color: $backBlue;
            outline: 10px solid $blue;
            outline-offset: 0px;
            animation: groupTargetOut 0.5s 0.8s linear forwards
        }
    }
}

@keyframes groupTargetOut {
    from {
        background-color: $backBlue;
        outline-color: $blue;
    }

    to {
        background-color: transparent;
        outline-color: transparent;
    }
}

.group-card {
    display: block;
    text-decoration: none;
    color: inherit;
    border-radius: 5px;
    padding: 15px 0px;
    position: relative;
    text-align: right;

    &, * { transition: all 0.15s linear; }

    .group-name { font-size: var(--font-size); }
    .logo {
        background-image: none;

        &, img {
            min-height: 80px;
            max-height: 140px;
            display: block;
        }

        img {
            object-position: center right;
            object-fit: contain;
        }
    }

    .button { margin: 30px 0px 0px 0px; }

    @media ( max-width: ( $show-members-breakpoint - 1px )) {
        text-align: left;
        display: flex;
        align-items: center;
        padding-left: 10px;

        .group-info {
            margin: 0px 15px;
        }

        .group-name { font-size: var(--font-size); }
        .meta { font-size: var(--font-size-small); }

        .logo {
            flex: 0 0 100px;
            max-height: 60px;
        }

        &:hover, &:focus { background-color: $f5; }
        .button {
            position: absolute;
            inset: 0px;
            margin: 0px;
            opacity: 0;
        }

        &:after {
            content: "\f054";
            font-family: $font-awesome;
            font-weight: bold;
            margin-right: 10px;
            margin-left: auto;
            font-size: var(--font-size-large);
            color: $softGrey;
        }
    }

    @media ( min-width: $show-members-breakpoint ) {
        &:after {
            content: " ";
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: -20px;
            width: 1px;
            border-right: 1px solid $e4;
        }
    }
}
