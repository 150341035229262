//
//  Color schemes
//  - creates predefined color classes
//

// Loop through colors
@each $color in $colors {
    $name: nth($color, 1 );
    $value: nth($color, 2 );

    // Background classes
    .#{""+$name}-bg {
        background-color: getVar( #{"--"+$name}, nth($value , 1));
    }

    // Text classes
    .#{""+$name}-txt {
        color: getVar( #{"--"+$name}, nth($value , 1));
    }

    // Colored rows
    .row.#{""+$name} {
        --row-bg: var(#{"--"+$name});
    }
}
