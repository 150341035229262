//
//  Session header
//
.session-header {
    border: 0px;
    border-bottom: 1px solid $border;
    
    @media ( min-width: $mobileLandscape ) {
        .title-holder {
            display: flex;
            flex-flow: row nowrap;
            box-sizing: border-box;
    
            .meta-lint { flex: 1 1 auto; }
    
            .title {
                flex: 0 0 auto;
                padding-right: 30px;
                min-width: 0;
                max-width: 50%;
            }
        }
    }
    
    @media ( min-width: $landscape ) {
        padding: 5px 10px 5px 15px;
    }
}

.multi-session-header {
    .options { padding-right: 10px; }
    
    .button { display: none; }
    
    @media ( min-width: $landscape ) {
        .options { padding-right: 0px; }
        .button { display: inline-block; }
        .subtitle { display: none; }
    }
}

.part-session .session-header {

    @media ( max-width: ( $landscape - 1px ) ) {
        padding-left: 0px;
    }
    
    .close-button {
        span { display: none; }
        .mobile-icon { display: inline-block; }

        @media ( max-width: ( $portrait - 1px ) ) {
            padding-left: 0px;
            padding-right: 0px;
        }

        @media ( min-width: $portrait ) {
            span { display: inline-block; }
            .mobile-icon { display: none; }
        }
    }

    @media ( min-width: $landscape ) {
        .options { display: none; }
    }
}
