//
//  Base
//  - contains styling for the base layout of the meeting page
//  - contains CSS to make the general documents/votings toggleable
//

// Extra short titles on mobile
@media ( max-width: ( $mobileLandscape - 1px ) ){
    .hide-mobile { display: none; }
}

// General documents/votings toggleable
.general-documents,
.general-votings,
.vote-results{
    margin: 40px 0px;

    &.nonvisual { display: none }
    
    @media ( min-width: $portrait ) {
        margin: 70px 0px;
    }
    
    .fold-out {
        max-height: 1px;
        overflow: hidden;
        transition: all 0.3s linear;
        position: relative;
        visibility: hidden;
    }
    
    &.active .fold-out {
        max-height: 4000px;
        overflow: auto;
        visibility: visible;
    }
}

.meeting-page [role="main"] .loading-placeholder {
    text-align: center;
    display: block;
    width: 100%;
    padding: 30px;
    border: 1px solid $softGrey;

    .icn {
        display: block;
        font-size: 48px;
    }

    .loading-label { color: $mediumGrey; }
}

.meeting-page .preview-message {
    margin-top: 25px;
    margin-bottom: 25px;
}
