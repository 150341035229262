//
//  Employee fragments
//

$dateSize: 80px;

.tab-fragments {
    .employee-fragments-list {
        border-top: 1px solid $e4;
        list-style: none;
        margin: 0px 0px 30px 0px;
        padding: 0px;

        ul {
            margin: 0px 20px 20px 20px;
            padding: 0px;

            @media ( min-width: $mobileLandscape ) {
                margin-left: $dateSize;
            }
        }

        & > li { border-bottom: 1px solid $e4; }

        .meeting-link, .meeting-fragments li {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .meeting-link {
            padding: 5px 10px;
            color: inherit;
            text-decoration: none;

            * { pointer-events: none; }

            &:hover { background-color: $backBlue; }
        }

        .date, .time { flex: 0 0 $dateSize; }

        .group { flex: 0 0 300px; }

        .item, .amount { flex: 1 1 auto; }
        .time, .date, .amount { font-weight: bold }

        .toggle {
            background-color: $lightGrey;
            color: $white;
            border-radius: 100%;
            line-height: 20px;
            text-align: center;
            flex-basis: 20px;
            font-size: var(--font-size-smaller);

            .fa-minus { display: none; }
        }

        .icn {
            flex: 0 0 24px;
            text-align: center;
        }

        .item {
            max-width: 260px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .meeting-fragments {
            max-height: 0px;
            overflow: hidden;
            transition: all 0.3s ease-in;
            pointer-events: none;
            visibility: hidden;
        }

        li.active {
            .meeting-fragments {
                max-height: 400px;
                overflow-y: auto;
                pointer-events: visible;
                visibility: visible;
            }

            .toggle {
                .fa-plus { display: none; }
                .fa-minus { display: inline-block; }
            }
        }

        @media ( max-width: ( $mobileLandscape )) {
            .amount { flex: 0 0 100px; font-size: var(--font-size-small); }
            .group { flex: 1 1 auto; }
        }
    }
}
