//
//  Theme tabs & tab-content
//  - contains theme specific styling for the tabs and related tab-content
//  - shows tabs on mobile devices, switches to side-by-side columns on tablet landscape & desktop

.mobile-theme-tabs {
    margin: 30px 0px;
    border-bottom: 1px solid $border-color;

    @media ( max-width: ( $mobileLandscape - 1px )){
        .button {
            display: block;
            margin: 20px 0px;
        }
    }

    @media ( min-width: $mobileLandscape ) {
        display: flex;
        flex-flow: row-reverse nowrap;

        .tabs { flex: 1 1 auto; }

        .button {
            flex: 0 0 auto;
            align-self: center;
        }
    }

    @media ( min-width: $landscape ) {
        display: none;
    }
}

.theme-tabs-holder {
    @media ( max-width: ( $landscape - 1px )) {
        .theme-tab {
            display: none;

            &.active { display: block; }
        }
    }

    @media ( min-width: $landscape ) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .theme-tab {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - 20px);
        }
        .news-active .theme-tab {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(33% - 20px);
        }
        .last-modified-themes { flex: 0 0 280px; }

        .main-themes {
            flex: 1 1 auto;
            margin-right: 40px;
        }

        &.news-active .theme-tab {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(33.3333333% - 20px);
        }
    }
}

// Stying of the tabs
.mobile-theme-tabs .block-tabs {
    flex: 1 1 auto;

    ul {
        list-style: none;
        display: flex;
        flex-flow: row nowrap;
        margin: 0px;
        padding: 0px;
        margin-bottom: -1px;
    }

    li {
        display: block;
        position: relative;
        flex: 1 1 auto;
        margin-right: -1px;

        @media ( min-width: $mobileLandscape ) {
            flex: 0 0 auto;
        }
    }

    .tab {
        display: block;
        padding: 14px 20px;
        background-color: $f5;
        color: inherit;
        text-decoration: none;
        border: 1px solid $softGrey;
        text-align: center;

        span { pointer-events: none; }

        &:hover, &.active{ background-color: $white; }

        &.active {
            border-bottom-color: $white;

            .icn { color: $primary; }
        }
    }

    .hide-mobile {
        margin-left: 10px;
        color: $darkGrey;

        @media ( max-width: ( $mobileLandscape - 1px ) ) {
            display: none;
        }
    }
}
