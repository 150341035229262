//
//  Styling for the other elements on the term-agenda page
//
.term-agenda-page {
    .page-content {
        max-width: 80ch;
    }

    #wrapper {
        padding-bottom: 0px;
    }
}
