//
//  Base style of default row
//
.default-row {
    border-radius: 5px;
    margin: 5px 0px;
    border: 0 solid transparent;
    border-left: 5px solid var(--item-row-bg);
    background: var(--item-row-bg);
    padding: 10px 10px 10px 5px;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    text-align: left;
    width: 100%;
    transition: all .15s linear;
    container: default-row / inline-size;
    text-decoration: none;

    & > p:last-of-type {
        margin-bottom: 0;
    }

    // Add unread style
    &.unread {
        font-weight: normal;
        --item-row-bg: var(--unread-bg);
        border-left-color: var(--unread-border);
    }

    // Add hover and pointer when button or link
    &:is( button, a ) {
        cursor: pointer;
    }

    &:hover {
        --item-row-bg: var(--item-row-hover-bg);
    }

    // Disable click events on all children everything except buttons
    *:not(a, button, .button) {
        pointer-events: none;
    }

    a, button {
        pointer-events: visible;
    }

    // Flexbox for row and default link or description in row
    &, .default-link {
        display: flex;
        align-items: center;
        gap: var(--item-row-gap);
    }

    // Allow elements to grow
    .description, .row-title, .default-link {
        flex: 1 1 auto;
        min-width: 0px;
    }

    .default-link {
        color: inherit;
        text-decoration: none;
        border-radius: 3px;
    }

    & > .description:first-child {
        padding-left: 5px;
    }

    // Elements in row or in default-link
    .row-title {
        font-weight: bold;
        overflow-wrap: break-word;
    }

    // Icon before row
    .row-icn {
        --_min-size: 16px;
        font-size: calcFontSize(18pt);
        margin: 0 10px;
        color: var(--alt-grey);

        &, .icn-stack { min-width: var(--_min-size); }

        @media (min-width: $mobileLandscape) {
            --_min-size: 24px;
            font-size: calcFontSize(20pt);
        }

        @media (min-width: $portrait) {
            font-size: calcFontSize(24pt);
        }
    }

    // Color PDF icon in PDF red
    &:not(.private) .fa-file-pdf {
        color: var(--pdf-red);
    }

    // Meta element in(below) main title
    .meta {
        color: var(--alt-grey);
        font-size: 0.9rem;
        padding: 0px;
    }

    // Date
    .modified, .date, .short-date, .status {
        margin: auto 5px;
        font-weight: bold;
        color: var(--alt-grey);
        text-align: right;
    }

    .date {
        flex: 0 0 12ch;
    }

    .short-date {
        flex: 0 0 7ch;
    }

    // Hide date in smaller containers (except the short-date)
    @container default-row ( max-width: #{$mobileLandscape - 1px} ) {
        .modified, .date, .type {
            display: none;
        }
    }

    // Status, label is hidden in smaller containers
    .status {
        flex: 0 0 160px;
        margin-left: 5px;

        @container default-row ( max-width: #{$mobileLandscape - 1px} ) {
            flex: 0 0 auto;

            .label {
                display: none;
            }
        }
    }

    .type {
        flex: 0 0 18ch;
    }

    // Options
    .options, .users {
        align-self: center;
    }

    // Buttons
    .button {
        flex: 0 0 auto;
    }

    // Users
    .users {
        flex: 0 0 70px;
    }

    // Hide and show class for hidcen (or showing) elements in smaller containers
    @container default-row ( max-width: #{$mobileLandscape - 1px} ) {
        .hide-small {
            display: none;
        }
    }

    @container default-row ( min-width: #{$mobileLandscape} ) {
        .show-small {
            display: none;
        }
    }
}
