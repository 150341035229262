//
//  Hoverbox
//  - handles a hoverable element which shows a fold-out on hover
//  - replaces old hoverbox_src and uses new semanticly improved classnames
//
.hover-box {
    $border-color: $e4;

    position: relative;
    display: inline-block;

    .fold-out {
        width: 260px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        background-color: $white;
        z-index: 5;
        padding: 20px;
        box-sizing: border-box;
        transition: all 0.2s linear;
        border: 1px solid $border-color;
        pointer-events: none;

        // Not visible
        opacity: 0;
        max-height: 0px;

        // Pointer
        &:before {
            content: " ";
            position: absolute;
            width: 16px;
            height: 16px;
            transform-origin: center center;
            transform: rotate(-45deg);
            left: 13px;
            top: -9px;
            background-color: inherit;
            border-style: solid;
            border-width: 1px;
            border-color: $border-color $border-color transparent transparent;
        }

        .overflow {
            width: 100%;
            height: 100%;
            min-height: 100px;
            position: relative;
            box-sizing: border-box;
            overflow: hidden;
        }
    }


    @mixin openFoldOut() {
        .fold-out {
            max-height: 80vh;
            opacity: 1;
            pointer-events: auto;
        }

        .overflow { overflow: auto; }
    }

    &.active {
        @include openFoldOut();
    }

    &:focus-within {
        @include openFoldOut();
    }

    // Position / alignment of fold-out box

    // Default ( small mobile position
    @media ( max-width: ( $mobileLandscape - 1px ) ){
        .fold-out {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            &:before { display: none; }
        }

        &:after {
            content: " ";
            position: fixed;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;
            background-color: rgba( 255, 255, 255, 0.7 ) ;
            z-index: 1;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.2s linear;
        }

        &.active {
            .fold-out { top: 45vh; }
            &:after { opacity: 1; }
        }

        &:focus-within {
            .fold-out { top: 45%; }
            &:after { opacity: 1; }
        }
    }

    @media ( min-width: $mobileLandscape ) {
        .fold-out {
            position: absolute;
            top: 100%;
        }

        &.right-aligned, &.center-aligned {
            .fold-out {
                right: 0px;
                left: auto;

                &:before {
                    left: auto;
                    right: 13px;
                }
            }
        }
    }

    @media ( min-width: $portrait ) {
        &.center-aligned {
            .fold-out {
                left: 50%;
                transform: translateX( -50% );

                &:before {
                    left: 50%;
                    margin-left: -8px;
                }
            }
        }
    }
}

// Fix tabbing index
.user-is-tabbing {
    .hover-box {
        .fold-out { display: none; }
        &.active .fold-out { display: block; }
    }
}
