//
//  Meeting title & info/metadata
//

// Meeting title
.meeting-title {
    position: relative;
    box-sizing: border-box;
    padding-left: $date-size-mobile + 10px;

    .group { font-size: var(--font-size-large); }
    .time { font-size: var(--font-size-small); }
    .tag { vertical-align: middle; }

    .icn {
        color: $altGrey;
        margin-right: 5px;
    }

    .group, .time {
        display: block;
        line-height: 1.2;
        margin-bottom: 5px;
    }

    .date {
        position: absolute;
        left: 0px;
        top: 0px;
        color: $white;
        font-size: var(--font-size);
        font-style: normal;
        font-weight: bold;
        text-align: center;
        width: $date-size-mobile;
        line-height: 40px;
    }

    @media ( min-width: $portrait ) {
        padding-left: $date-size-portrait + 20px;

        .time { font-size: var(--font-size); }

        .date {
            width: $date-size-portrait;
            font-size: var(--font-size-larger);
            line-height: 45px;
        }

        .group {
            font-size: var(--font-size-h1);
            line-height: 1;
        }
    }
}

.meeting-description {
    margin: 20px 0px;
    text-align: justify;
    text-align-last: left;

    @media ( min-width: $landscape ) {
        font-size: var(--font-size-large);
        line-height: 1.8;
    }
}
