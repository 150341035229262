//
//  Hovermenu
//  - Shows button/icon
//  - when hovered, shows a submenu
//  - can also be uses as an replacement of helpIcon.scss
//

$transition: all 0.15s linear;
$pointer-buffer: 7px;
$mobile-breakpoint: $portrait;
$tablet-breakpoint: $landscape;

:root {
    --hm-padding: 20px;
    --hm-bg: var(--white);
    --hm-width: 240px;
}

.hover-menu {
    display: inline-block;
    position: relative;
    
    .hover-trigger, .hover-trigger:after, .fold-out { transition: $transition }
    
    .hover-trigger {
        z-index: 1;
        margin: 0px;
        display: block;

        &:after {
            content: " ";
            background-color: var(--hm-bg);
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: -10px;
            height: 15px;
            z-index: 3;
            opacity: 0;
        }
        
        .pointer { color: $softGrey; }
    }
    
    .fold-out {
        position: absolute;
        background-color: var(--hm-bg);
        box-shadow: 0px 0px 7px rgba( 0, 0, 0, 0.15 );
        padding: var(--hm-padding);
        border-radius: 3px;
        width: var(--hm-width);
        box-sizing: border-box;
        max-height: 1px;
        opacity: 0;
        top: 100%;
        z-index: 2;
        font-size: var(--font-size);
        visibility: hidden;
    }

    @mixin openFoldOut() {
        .hover-trigger {
            background-color: var(--hm-bg);
            box-shadow: 0px 0px 7px rgba( 0, 0, 0, 0.15 );
            border-color: var(--hm-bg);
            color: $mediumGrey;
            z-index: 3;
            
            &:after { opacity: 1; }
        }
    
        .fold-out {
            max-height: 700px;
            opacity: 1;
            pointer-events: visible;
            visibility: visible;
        }
    }
    
    &.active {
        @include openFoldOut();
    }

    // Alignment
    &.left-aligned, &.right-aligned {
        &.active {
            .hover-trigger {
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }
    }
    
    &.left-aligned {
        .fold-out {
            left: 0px;
            border-top-left-radius: 0px;
        }
    }
    
    &.right-aligned {
        .fold-out {
            right: 0px;
            left: auto;
            border-top-right-radius: 0px;
        }
    }
    
    // Default | center-aligned
    &:not(.left-aligned):not(.right-aligned) {
        .fold-out {
            left: 50%;
            right: auto;
            transform: translateX( -50% );
        }
    }

    .spinner, .error-message {
        position: absolute;
        background-color: $white;
        opacity: 0;
        pointer-events: none;
        inset: 0 0;
        text-align: center;
        width: 100%;
        height: 100%;
        span {
            position: relative;
            inset: 50% 0 0 0;
        }
    }

    &.is-loading .spinner {
        opacity: 0.5;
        pointer-events: visible;
    }

    &.is-error .error-message {
        opacity: 1;
        pointer-events: visible;
    }

}

// Styling of elements in fold-out
.hover-menu .fold-out {
    h3, h4 {
        margin: 30px 0px 5px 0px;
        font-size: var(--font-size-small);
        font-weight: bold;
    }
    
    .button {
        display: block;
        margin: 5px 0px;
    }
    
    .menu-item {
        display: block;
        padding: 10px 5px 10px 15px;
        border: 0px;
        background: transparent;
        border-top: 1px solid $e4;
        transition: $transition;
        cursor: pointer;
        text-decoration: none;
        width: 100%;
        color: inherit;
        box-sizing: border-box;
        font: inherit;
        text-align: left;
        
        * {
            pointer-events: none;
            display: inline-block;
        }
        
        &:hover {
            background-color: $f5;
            color: inherit;
        }
        
        &.disabled {
            pointer-events: none;
            color: $lightGrey;
            
            .icn { color: $softGrey; }
        }
    }
    
    & > :first-child:not(.button) { margin-top: 0px; border-top: 0px; }
}

// Show pointer
.hover-menu.show-pointer {
   .hover-trigger {
       &:after {
           width: 26px;
           height: 26px;
           left: 50%;
           transform: translate(-50%, 50%) rotate(-45deg);
           transform-origin: center center;
           box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.07);
       }
   }
   
   .fold-out {
        margin-top: $pointer-buffer;
        
        &:before {
            top: $pointer-buffer * -1;
            height: $pointer-buffer;
            content: " ";
            display: block;
            position: absolute;
            left: 0px;
            right: 0px;
        }
   }
   
   // Clean-button different hover
   &:hover .clean-button {
        background-color: $e4;
        box-shadow: none;
   }
}

//
// Mobile & portrait menu
//  - shows hover-menu on smaller devices and full menu on larger devices

// Switch to full CSS
@mixin switchToFull() {
    .hover-trigger { display: none; }

    .fold-out {
        position: relative;
        opacity: 1;
        max-height: 100%;
        background-color: transparent;
        box-shadow: none;
        padding: 0px;
        transition: none;
        z-index: 0;
        visibility: visible;
    }

    .menu-item {
        border-radius: 3px;
        border: 1px solid transparent;
    }
}

// Mobile menu
// - switches from portrait
@media ( min-width: $mobile-breakpoint ){
    .mobile-menu {
        @include switchToFull();
    }
}

// Tablet menu
//  - switches from landscape
@media ( min-width: $tablet-breakpoint ){
    .tablet-menu {
        @include switchToFull();
    }
}
