//
//  Base HTML
//  - contains styling base of html elements
//
html {
    //font-size: 87.5%; // should be 14px
    font-size: getVar( --root-font-size, 87.5% );
    position: relative;
    min-height: 100vh;
    background-color: $white;
    scroll-behavior: smooth;
}

body {
    font-size: var( --font-size, 1rem );
    font-family: var( --font-family, $open-sans);
    line-height: var( --line-height, 1.8rem);

    &.modal-opened {
        overflow: hidden;
    }
}

html, body {
    height: 100%;
    color: getVar( --text-color, $text-color-base);

    @media print {
        height: auto;
    }
}


body, div, p, td, th {
    vertical-align: top;
}

@media print {
    body { color: #444444; }
    @page { margin: 10mm; }
}

// Default link
a {
    color: getVar( --link-color, $secondary);
    
    &:active, &:hover, &:focus {
        text-decoration: none;
        color: var(--link-color-hover);
    }
    
    &:active { outline: 0px; }
    
    img { border: none; }
}

// Paragraphs
p {
    margin-bottom: 20px;
    clear: both;
}

// Default list
ul, ol { margin-bottom: 10px; }
ul { padding-left: 15px; }
ol { padding-left: 25px; }

// Headings
h1, h2, h3, h4, h5 {
    font-weight: bold;
    margin: 0px;
    clear: both;
}

h1, h2 {
    line-height: 30px;
    margin-bottom: 10px;
}

h3, h4, h5 {
    margin-bottom: 5px;
}

h1 { font-size: var(--font-size-h1); }
h2 { font-size: var(--font-size-h2); }
h3 { font-size: var(--font-size-h3); }
h4 { font-size: var(--font-size-h4); }
h5 { font-size: var(--font-size-h5); }

// Address element
address {
    font-style: normal;
    
    p { margin: 0px; }
    p + p { margin-top: 20px; }
    
    a {
        display: block;
        text-decoration: none;
    }
    
    span { display: block; }
    h4 { margin: 0px; }
}

// Code elements
code {
    background-color: var(--ee);
    padding: 0px 5px;
    display: inline-block;
    border-radius: 3px;
}
