//
//  (Agenda) Header
//  - styling for header in agenda, between agenda-items
//
.agenda-header {
    border-width: 1px 0px 0px 0px;

    h3 { padding-left: 5px; }
    .nr + h3 { padding-left: 0px; }

    // Styling agenda item nr
    .nr {
        font-weight: bold;
        text-align: center;
        font-size: var(--font-size-large);
        border-right: 1px dotted $nr-border;
        min-width: $nr-width-mobile;
        position: relative;
        margin-right: 10px;
        margin-left: -10px;

        @media (min-width: $portrait) {
            min-width: $nr-width-portrait;
            font-size: calcEmFontSize(22pt);
        }
    }

    .label.out { display: inline-block; }

    &.active {
        .label.in { display: inline-block; }
        .label.out { display: none; }
        .pointer .icn { transform: rotate(90deg); }
    }
}
