//
//  Domain colors schemes for the term-agenda
//

.term-agenda-page {

    // Domain row colors
    .domain {
        --dr-color: var(--white);
        &.blue {
            --dr-bg: #{getHSLA("blue", 10%)};
            --dr-bg-odd: #{getHSLA("blue", 20%)};
            --dr-btn: #{getHSLA("blue", 35%)};
            --dr-btn-left: #0067A3;
        }

        &.green {
            --dr-bg: #{getHSLA("green", 10%)};
            --dr-bg-odd: #{getHSLA("green", 20%)};
            --dr-btn: #{getHSLA("green", 35%)};
            --dr-btn-left: #{getHSLShade("green", -20%)};
        }

        &.yellow {
            --dr-bg: #{getHSLA("yellow", 10%)};
            --dr-bg-odd: #{getHSLA("yellow", 20%)};
            --dr-btn: #{getHSLA("yellow", 35%)};
            --dr-btn-left: var(--yellow);
            --dr-color: #3d2f06;
        }

        &.purple {
            --dr-bg: #{getHSLA("purple", 10%)};
            --dr-bg-odd: #{getHSLA("purple", 20%)};
            --dr-btn: #{getHSLA("purple", 35%)};
            --dr-btn-left: var(--purple);
        }

        &.orange {
            --dr-bg: #{getHSLA("orange", 10%)};
            --dr-bg-odd: #{getHSLA("orange", 20%)};
            --dr-btn: #{getHSLA("orange", 35%)};
            --dr-btn-left: var(--orange);
            --dr-color: #3a1907;
        }

        &.red {
            --dr-bg: #{getHSLA("red", 10%)};
            --dr-bg-odd: #{getHSLA("red", 20%)};
            --dr-btn: #{getHSLA("red", 35%)};
            --dr-btn-left: var(--red);
        }

        &.brown {
            --dr-bg: #{getHSLA("brown", 10%)};
            --dr-bg-odd: #{getHSLA("brown", 20%)};
            --dr-btn: #{getHSLA("brown", 35%)};
            --dr-btn-left: #{getHSL("brown")};
        }

        &.grey {
            --dr-bg: #{getHSLA("grey", 10%)};
            --dr-bg-odd: #{getHSLA("grey", 20%)};
            --dr-btn: #{getHSLA("grey", 35%)};
            --dr-btn-left: #{getHSL("grey")};
        }

        &.pink {
            --dr-bg: #{getHSLA("pink", 10%)};
            --dr-bg-odd: #{getHSLA("pink", 20%)};
            --dr-btn: #{getHSLA("pink", 35%)};
            --dr-btn-left: #{getHSL("pink")};
            --dr-color: #800015;
        }

        &.turquoise {
            --dr-bg: #{getHSLA("turquoise", 10%)};
            --dr-bg-odd: #{getHSLA("turquoise", 20%)};
            --dr-btn: #{getHSLA("turquoise", 35%)};
            --dr-btn-left: #{getHSL("turquoise")};
            --dr-color: #163C38;
        }
    }
}
