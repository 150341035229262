//
//  Past members || Past organisation
//  - two level list of past members, used for organisation and party view
//
.tab-past-members, .tab-past-organisation {
    ul {
        list-style: none;
        padding: 0px;
        margin: 0px 0px 30px 0px;
    }

    a {
        display: flex;
        padding: 10px 5px;
        border-top: 1px solid $e4;
        color: inherit;
        text-decoration: none;
        transition: all 0.15s linear;
        position: relative;

        &:hover { background-color: $f5; }
        .name, .role { flex: 1 1 45%; }
        .name { font-weight: bold; }
        .period { flex: 0 0 200px; }

        .view {
            flex: 0 0 10%;
            font-size: var(--font-size-small);
            color: var(--link-color);
            text-align: right;
        }

        @media (max-width: ($mobileLandscape - 1px)) {
            padding-right: 30px;

            &, .name, .role { display: block; }
            .view-txt { display: none; }

            .view {
                position: absolute;
                right: 10px;
                top: 12px;
            }
        }
    }
}

.tab-past-organisation {
    h3:not(:first-of-type) { margin-top: 20px; }
    .toggleblock { margin: 10px 0px; }
    .grey a:hover { background-color: $white; }
}
