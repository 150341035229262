//
//  Modal base styling
//  basic layout and several generic things
//

.modal-meeting-item-proposals {
    --vbtn-weight: normal;
    --vbtn-size: 20px;
    --vbtn-color: var(--light-grey);
    --vbtn-for: var(--green);
    --vbtn-deny: var(--red);

    h4 {
        margin: 30px 0px 0px 0px;
        font-size: var(--font-size-small);
    }

    .window .loading-placeholder {
        position: absolute;
        inset: 0px;
        background-color: var(--white);
        border: 0px;
        text-align: center;
        padding: 30px 0px;

        .loading-label { font-size: var(--font-size-small); }

        .icn {
            color: var(--soft-grey);
            font-size: 2.4rem;
            margin-bottom: 10px;
        }
    }

    &:not(.show-no-content) {
        .no-content { display: none; }
    }

    &:not(.is-loading) {
        .loading-placeholder { display: none; }
    }

    // Hide inactive modal pages
    .modal-page:not(.active) {
        display: none;
    }

    .window-content { min-height: 200px; }

    // Header above proposal list
    .prop-list-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border-bottom: 1px solid var(--soft-grey);

        h4 {
            margin: 0px;
        }

        h4 { flex: 1 1 auto; }
    }

    // Tweak styling of the modal header
    .window-header .subtitle {
        color: var(--alt-grey);

        .icn { color: var(--light-grey); }
    }

    .window-overflow { scroll-behavior: smooth; }

    // Hide / deactivate several items to prevent adding, editing or voting
    &.locked, .locked {
        label, input { pointer-events: none; }
        .add-proposal, .edit-proposal, .add-prop-header, [data-new-prop] { display: none; }

        .prop-menu {
            visibility: hidden;
            pointer-events: none;
        }
    }
}
