//
//  Share menu
//  - menu with social media sharing buttons
//
.share-menu {
    --_mobile-only: none;
    --_desktop-only: inline-block;

    // Hide based on device
    .menu-item.mobile-only { display: var(--_mobile-only); }
    .menu-item.desktop-only { display: var(--_desktop-only); }

    @media(hover:none) {
        --_mobile-only: inline-block;
        --_desktop-only: none;
    }
}
