//
//  Overview title
//  - h2 or h3 with button next to it
//
.theme-overview-title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    h2, h3 { flex: 1 1 auto; }

    .button {
        flex: 0 0 auto;

        @media ( max-width: ( $landscape - 1px )) {
            display: none;
        }
    }
}

.overview-intro {
    margin-bottom: 50px;
}
