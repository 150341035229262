//
//  Focus
//  - contains the styling for the focus on elements, when the user is tabbing
//

// Focus when user is tabbing
#page.user-is-tabbing {

    // Links & buttons
    a, button, input.button {
        &:focus {
            outline: 2px dotted var(--focus-color);
            outline-offset: 2px;
            transition: none;
        }
    }

    .tabs a {
        &:focus {
            color: $white;
            background-color: var(--focus-color);
        }
    }

    // Radio & checkboxes
    input[type=radio], input[type=checkbox] {
        &:focus {
            &, & + label {
                font-weight: bold;
                outline: 2px dotted var(--focus-color);
                outline-offset: 2px;
            }
        }
    }

    // Invert focus color on elements with dark background
    &.dark-focus, .dark-focus {
        --focus-color: var(--white);
    }

    // Focus on elements with image as background (slideshows)
    &.image-focus, .image-focus {
        --focus-color: var(--white);
    }
}
