//
//	Content filter tabs
//
$breakpoint: $mobileLandscape;
$border-color: $e4;

// Hide radio-buttons for content type filter, these are triggered by the (fake) tabs
.content-type-hidden { display: none; }

// Base layout of content-tabs, more button and the filter-button (on smaller screens)
.content-type-tabs-holder {
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;

    .open-filters-button {
        margin-left: auto;
        flex: 0 0 auto;

        @media (min-width: $landscape ) {
            display: none;
        }
    }
}

// Styling of the tabs
.content-type-tabs { flex: 1 1 auto; }

.main-tabs {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: -1px; // Negative margin to pull the bottom of the active tab over the border

    li {
        flex: 0 0 auto;
        height: 40px;
    }

    button {
        border: 1px solid transparent;
        background-color: transparent;
        padding: 5px 15px;
        font: inherit;
        color: var(--text-color);
        border-radius: 3px;
        cursor: pointer;
        white-space: nowrap;
        line-height: 24px;

        &:hover { background-color: $e4; }
    }

    .main-tab {
        margin: 0px 1px;

        &.active {
            border-color: $border-color;
            color: var(--primary);
            font-weight: bold;
            border-radius: 0px;
            padding: 7px 15px;
            border-bottom: 1px solid $white;
            background-color: $white;
        }
    }
}

// Styling for tabs in the more-menu
.more-holder {
    .fold-out {
        padding: 0px;
        max-height: 80vh;
        max-width: 180px;

        ul {
            position: relative;
            max-height: inherit;
            overflow: hidden;
            overflow-y: auto;
            padding: 10px;
        }

        li {
            height: auto;

            button {
                padding-left: 30px;
                position: relative;
            }
        }
    }

    .sub-tab {
        display: block;
        width: 100%;
        text-align: left;
        padding: 5px;
        overflow-wrap: break-word;
        white-space: normal;

        &:before {
            content: "\f00c";
            font-family: $font-awesome;
            font-weight: bold;
            color: $green;
            visibility: hidden;
            display: inline-block;
            margin-right: 5px;
            position: absolute;
            inset: 4px auto auto 10px;
        }

        &.active:before { visibility: visible; }
    }

    // Show different label on mobile
    @media ( max-width: ($mobileLandscape - 1px)) {
        .more-label { display: none; }
    }

    @media( min-width: $mobileLandscape ) {
        .mobile-label { display: none; }
    }
}

//
// Show or hide tabs based on screen size
//

// Helper function for show/hide based on specific amount
@mixin tabHide( $a ) {
    & > li:nth-of-type(n+#{$a}):not(.more-holder) { display: none; }
    .more-holder li:nth-of-type(-n+#{$a}) { display: none; }
}

.main-tabs {

    // Mobile portrait
    @media ( max-width: ($mobileLandscape - 1px) ) {
        & > li:not(.more-holder) { display: none; }
    }

    // Hide all tab in more menu
    @media ( min-width: $mobileLandscape ) {
        .more-holder .content-type-all { display: none; }
    }

    //@media ( min-width: $landscape ) {
        .more-holder.inactive {
            visibility: hidden;
            pointer-events: none;
        }

        .notvisible { display: none; }
    //}
}

// Hide entire bar when processing the JS
.processing {
    .main-tabs {
        overflow: hidden;
        height: 42px;

        &, li {
            visibility: hidden;
            pointer-events: none;
        }
    }
}


