//
//  System pages
//  - styling for the systempages like the 404 and 500
//

.system {
    --error-code-size: 32px;
    --error-letter-timing: 0.8s;
    --error-letter-delay: 0.3s;
    --error-easing: cubic-bezier(0.68, -0.6, 0.32, 1.6);

    .page-head h1 { opacity: 0; }
}

@keyframes errorLetterIn {
    from {
        transform: translateY(50%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@import "code";
@import "message";
