//
//  Subject
//  - details page of a subject
//

.subject-details {
    --_grid: 1fr 1fr;
    display: grid;
    grid-template-columns: var(--_grid);
    grid-gap: 0px 20px;

    // Add extra paddings when details are in the fold-out of a subject-row
    .subject-row & { padding: 0px 15px; }

    h3, h4, ul, .toolbar, .content-header {
        grid-column: 1 / -1;
        align-items: center;
    }

    h4 { margin-top: 20px; }

    @container subject-row ( max-width: 599px ) {
        --_grid: 1fr;
    }

    &:not(:has(.meta-block+.meta-block)) {
        --_grid: 1fr;
    }
}

// Change bg colors of metadata blocks
.document-subject {
    @media (max-width: 599px ) {
        --_grid: 1fr;
    }
}
