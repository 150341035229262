//
//  Debug
//  - debug contains several small classes which can be used for identifying or testen specific elements in the interface
//
#page.debug {
    &.highlight-icons {
        .fab, .far, .fas, .icn { outline: 2px solid red !important; }
    }

    &.highlight-buttons {
        button, .button { outline: 2px solid blue!important; }
    }
}
