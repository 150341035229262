//
//  Default containers
//
#page {
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    width: 100%;
    font-family: var(--font-family);
    font-size: var(--font-size);
    background-color: var(--page-bg);
    color: var(--text-color);

    @media print {
        display: block;
        height: auto;
    }
}

// Wrapper container */
#wrapper {
	padding: 70px 0px;
	position: relative;
	flex: 1 0 auto;
}

@media print {
	#wrapper { padding-top: 10mm; }
}

#content {
    padding-bottom: 50px;
    min-height: 300px;
    position: relative;

    @media print {
        float: none;
        width: 100%;
    }
}

/* Reset content for full-width pages */
#page.wide #content,
#content.wide,
#page.home #content,
#page.vergaderingen #content,
#page.vergadering #content,
#page.zoeken #content,
#page #content.wide {
    float: none;
    width: 100%;
}

// Z-indexes for overlays and sidebars
.overlays { z-index: 15; }
.panels { z-index: 99; }
.sidebars { z-index: 10; }

// Center aligned container
.container {
    max-width: 1200px;
    width: calc(100% - 20px);
    margin: 0px 10px;
    position: relative;
    min-width: 280px;
    box-sizing: border-box;

    @media (min-width: $mobileSmall){
        margin: 0px 20px;
        width: calc( 100% - 40px );
    }

    @media (min-width: $mobileLandscape){
        margin: 0px 30px;
        width: calc( 100% - 60px );
    }

    @media (min-width: $portrait){
        margin: 0px 50px;
        width: calc( 100% - 100px );
    }

    @media (min-width: $desktop){
        margin: 0px 100px;
        width: calc( 100% - 200px );
    }

    @media (min-width: 1240px){
        margin: 0px auto;
    }

    @media print {
        max-width: 100%;
        width: 100%;
        margin: 0px;
    }


    // Fix container in container margin
    .container {
        margin: 0px;
        width: 100%;
    }
}

.row {
    --row-bg: transparent;
    position: relative;
    clear: both;
    display: block;
    padding: 30px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--row-bg);

    @media (min-width: $portrait) {
        padding: 50px 0px;
    }

    @media (min-width: $desktop) {
        padding: 70px 0px;
    }

    &.no-padding {
        padding: 0px;
        margin: 0px;
    }
}
