//
//  Subscription main page and it's settings
//
@media ( max-width: $mobileLandscape - 1px ){
    .form-group {
        .button {
            display: block;
            float: none;
            width: 100%;
        }

        input[type="text"], input[type="password"], input[type="email"], textarea {
            width: 100%;
       }
    }

    input[type="submit"] {
        -webkit-appearance: none;
        appearance: none;
    }
}
