//
//  Modal Export
//  - extra styling for the advanced export modal
//
.modal-export-advanced {
    h4 {
      font-size: var(--font-size-small);
      margin-top: 30px;
      margin-bottom: 0px;
    }

    .checkbox { margin: 15px 0px; }

    .page-formats {
        button {
            display: block;
            width: 100%;
            text-align: left;
            padding: 15px
        }

        li {
            border-top: 1px solid var(--e4);
            padding: 5px 0px;
        }
    }

    .page-options {
        h4 { border-bottom: 1px solid var(--e4); }
        .toolbar { margin-top: 30px; }
    }

    .loading-placeholder {
        position: absolute;
        inset: 0px;
        text-align: center;
        background-color: var(--white);
        opacity: 1;
        transition: all 0.15s linear;
        pointer-events: visible;

        .loading-txt {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
        }

        .icn {
            color: var(--soft-grey);
            font-size: 32px;
        }
    }

    // For hiding/showing things with JS
    .modal-page:not(.active) { display: none; }
   &:not(.is-loading) .loading-placeholder {
       opacity: 0;
       pointer-events: none;
   }
}
