//
//  Load widgets
//  - used on home and meetings overview
//

// Predefine a few settings
$box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.10 );

@mixin whiteWidget {
    box-shadow: $box-shadow;
    background-color: $white;

    .widget-content{ padding: 15px; }
    .widget-footer { padding: 0px 15px 15px 15px; }
}

// Import widget base layout / styling
@import "base";

// Profile widgets
@import "twitter";
@import "youtube";

// New in 8.4
@import "tab-widget";
