//
//  Edit
//  - styling for the profile edit page
//
.profile-edit {
    .thumb {
        width: 120px;
        margin: 0px auto 20px auto;
        display: block;
    }

    fieldset { border: 0px; }

    [role="main"] h2 {
        border-bottom: 1px solid $e4;
        padding-bottom: 10px;
        margin-bottom: 20px;
        font-size: var(--font-size-large);
    }

    form {
        @media (min-width: $mobileLandscape ){
            display: flex;
            flex-flow: row nowrap;

            .photo-field {
                flex: 0 0 auto;
                margin-right: 20px;
            }

            .right-column { flex: 1 1 auto; }
        }
    }

    textarea {
        resize: vertical;
        max-height: 60vh;
    }

    .field-group {
        .text { flex: 1 1 auto; }
        input { width: 100%; }
    }

    .photo-field {
        text-align: center;

        label { width: 140px; }
    }

    .edit-photo-toolbar {
        flex-direction: column;
        display: flex;
        align-items: center;

        label {
            margin: 2.5px;
        }
    }

    .social-group {
        position: relative;

        @media ( min-width: $mobileLandscape ) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 3px;
        }

        input[type="checkbox"] {
            opacity: 0;
            position: absolute;
            left: 0px;
            top: 0px;
        }

        label {
            flex: 0 0 120px;

            &:before {
                content: "\f00c";
                font-weight: bold;
                font-family: $font-awesome;
                display: inline-block;
                border-radius: 3px;
                background-color: $white;
                border: 1px solid $input-border;
                color: $white;
                transition: all 0.15s linear;
                text-align: center;
                margin-right: 10px;

                @include equalAll(24px);
            }
        }

        .social-value {
            flex: 1 1 auto;
            background-color: $white;
            border: 1px solid $input-border;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 5px 10px;
            border-radius: 3px;
            opacity: 0;
            visibility: hidden;

            &:focus-within {
                border-color: $focus-outline-color;
                box-shadow: 0px 0px 3px rgba( 0, 0, 0.15 );

                input { box-shadow: none; }
            }

            label {
                position: absolute;
                opacity: 0;
                width: 1px;
                overflow: hidden;
            }

            .prefix {
                color: $mediumGrey;
                flex: 0 0 auto;
            }

            input {
                margin: 0px;
                border: 0px;
                padding-left: 0px;
                width: 100%;
            }
        }

        input:checked {
            & + label:before {
                background-color: $green;
                border-color: $green;
            }

            & + label:after { content: " : "; }

            & ~ .social-value {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .toolbar {
        justify-content: flex-end;

        .button {
            flex: 0 0 auto;
            width: auto;
        }
    }

    .email-wrapper {
        position: relative;

        input[type='checkbox'] {
            width: 30px;
        }

        label {
            flex-basis: 170px;
            width: 170px;
        }

        .hover-menu {
            position: absolute;
            top: 0px;
            right: 0px;
        }

        .field-group {
            opacity: 0;
            visibility: hidden;
            transition: all 0.15s linear;
            padding: 10px;
            background-color: $f5;
            border: 1px solid $e4;

            input {
                margin: 0px;
            }
        }

        input:checked ~ .field-group {
            opacity: 1;
            visibility: visible;
        }
    }
}

.high-contrast {
    .social-group {
        input:checked {
            & + label:before {
                background-color: $focus-outline-color;
                border-color: $focus-outline-color;
            }
        }
    }
}
