//
//  Meeting head
//  - styling for the meeting header element
//  - containing the meeting main & meta data
//  - and the page actions
//
$actions-breakpoint: $landscape;
$date-size-mobile: 70px;
$date-size-portrait: 90px;

.meeting-head {
    @import "layout";
}

@import "info";
