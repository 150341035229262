//
//  Theme sidebar
//  - styling for theme sidebar and the list overview
//

$border-color: $softGrey;

.sidebar-theme-list {
    .overflow {
        padding-left: 0px;
        padding-right: 0px;
    }

    h2, h3, h4 {
        padding-left: 20px;
    }

    h3, legend {
        color: $darkGrey;
        font-size: var(--font-size);
        font-weight: bold;
    }

    h4 {
        font-size: var(--font-size-small);
    }

    .theme-list .button, .main-theme-button {
        display: block;
        margin: 20px;
    }

    .quicksearch {
       padding: 20px;
        border-bottom: 1px solid $border-color;
        border-top: 1px solid $border-color;
        margin-top: 20px;

        .icn { color: $softGrey; }
        .field-group .input ::placeholder { color: #757575; opacity: 1; }
    }
}

.theme-list {
    border-bottom: 1px solid $border-color;

    ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
    }

    li { border-top: 1px solid $border-color; }
    a span { pointer-events: none; }

    .theme-link {
        display: flex;
        flex-flow: row nowrap;
        text-decoration: none;
        transition: all 0.15s linear;
        padding: 12px 0px 12px 20px;
        align-items: center;
        font-weight: bold;
        color: $mediumGrey;
        line-height: 1.4;

        &:hover { background-color: $f5; }
        .name { flex: 1 1 auto; }
    }

    .pointer { color: $softGrey }
    .toggle { color: getVar(--primary, $primary); }

    .pointer, .toggle {
        flex: 0 0 40px;
        text-align: center;
    }

    .toggle .fa-minus { display: none; }

    .children {
        display: none;
        background-color: $f5;
        border-top: 1px solid $border-color;

        ul {
            border-left: 20px solid $e4;

            .theme-link { border-left: 1px solid $border-color; }
        }

        .theme-link {
            &:hover {
                background-color: $white;
            }
        }
    }

    // Overwrite theme-link style when higlighted by quicksearch
    .theme-link.highlighted {
        color: $white;
        background: $secondary;
        border-color: $secondary;

        &:hover {
            color: $white;
            background: $hover-blue;
            border-color: $hover-blue;
        }
    }

    // Toggle magic
    .active {
        .children { display: block; }

        .toggle {
            .fa-minus { display: block; }
            .fa-plus { display: none; }
        }
    }
}
