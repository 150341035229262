//
//  Extra styling for widgets on home
//

// Widgets

:root {
    --widget-head-txt: var(--white);
}

#news h2, .block.article h3, .tile.article h3 {
    background: var(--bar-heading);
    color: var(--widget-head-txt);
    font-size: var(--font-size-large);
    font-weight: bold;
    line-height: 40px;
    text-indent: 15px;
}

.block.article p {
	margin: 0px 5px 20px 5px;
}

// News
#news {
    li {
        clear: both;

        h3 { font-size: var(--font-size); }

        .news-content {
            position: absolute;
            left: -9999px;
        }

        p {
            font-size: var(--font-size);
            margin-bottom: 10px;
            overflow: hidden;
            max-height: 100px;
            min-width: 0;
        }

        &:first-child .news-content {
            position: relative;
            left: 0px;
        }
    }
}

// Meetings
.block.meetings {
    p, ul { min-height: 120px; }
}

// Hide visual and nextMeeting when there is an active live stream
.stream-widget:not(.is-loading) {
    & ~ .visual-holder, & ~ .next-meeting {
        display: none;
    }
}
