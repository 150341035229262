//
//  Filters
//  - styling for search form
//

$breakpoint: $portrait;

//  Search form
.document-search-form {

    label {
        flex-basis: 100px;

        @media ( min-width: $mobileLandscape ) {
            flex-basis: 250px;
        }
    }

    select { margin: 0px; }

    .field-group, .divider { max-width: 500px; }

    .toolbar {
        clear: both;
        margin-top: 30px;
        text-align: right;

        .button {
            display: block;
            float: none;
            width: 100%;
        }

        @media ( min-width: $mobileLandscape ) {
            margin-top: 10px;

            .button {
                display: inline-block;
                width: auto;
            }
        }
    }

    .divider {
        clear: both;
        height: 10px;
        border-bottom: 1px solid $e4;
        text-align: center;
        margin: 20px 0px 30px;
        position: relative;

        span {
            display: block;
            position: absolute;
            left: calc( 50% - 20px );
            width: 40px;
            background-color: $white;
            line-height: 20px;
            z-index: 1;
        }
    }
}
