//
//  Search tools
//  - styling for the search tools for search parts, sort and period
//

.search-result-header {

    @media( min-width: $mobileLandscape ) {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        .numberofresults { order: 1; }
        .search-tools { order: 2; }
        .message { order: 3; }
    }

    // Search helpers: number of results | warning message | search suggestion
    .suggestion, .warning {
        clear: both;
        margin-bottom: 15px;

        strong { font-weight: bold; }
    }

    .suggestion {
        font-size: var(--font-size-larger);
        margin-bottom: 30px;

        a { font-style: italic; }
    }

    .warning { font-style: italic; }
}

.search-tools {
    background-color: white;
    padding: 3px;
    border-radius: 3px;
    margin: 4px 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    max-width: var(--gos-results-size);

    .mobile-label { display: none; }
    .field-wrap label{ float: none; }

    [data-hover-trigger], #period-button {
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .tool-name {
            max-width: 120px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    a {
        text-decoration: none;
        color: $black;
    }

    // Capitalize the first letter
    .tool-name:first-letter { text-transform: capitalize; }

    @media ( max-width: ( $mobileLandscape - 1px )) {
        margin-top: -10px;
        display: block;
        padding: 15px;
        border-radius: 3px;
        background-color: var(--f5);

        .hover-menu {
            display: block;
        }

        [data-hover-trigger], #period-button {
            text-align: left;
            width: 100%;

            .tool-name {
                flex: 0 1 200px;
                max-width: 200px;
            }
        }

        .mobile-label {
            display: block;
            font-weight: bold;
            flex: 0 0 100px;
        }
    }
}

// Hover menu fix, Search
.fold-out {
    font-size: var(--font-size-small);

    .fa-check { color: var(--f5); }
    .selected .fa-check { color: var(--green); }

    .menu-item {
        padding-left: 30px;
        position: relative;

        .fa-check {
            position: absolute;
            inset: 15px auto auto 8px;
        }
    }
}
