//
//  Theme column(s)
//  - styling for 'last modified themes', 'theme related agenda', 'theme related documents' and 'theme related news'
//


// Variables, defines when to hide rows
$col-limit: 6;
$agenda-limit: 5;

// Functiun for hiding rows
@mixin hideRows( $n ) {
    .theme-column:not(.active) li:nth-of-type(n+#{$n}) {
        display: none;
    }
}

// Default column styling
.theme-column {
    ul, ol {
        margin: 0px;
        padding: 0px;
        list-style: none;
    }

    li a {
        display: block;
        position: relative;
        border-top: 1px solid var(--soft-grey);
        padding: 7px 10px;
        color: inherit;
        text-decoration: none;
        transition: all 0.15s linear;

        &:hover { background-color: var(--f5); }
    }

    .name, .nr {
        font-weight: bold;
        line-height: 1.6;
    }

    .nr {
        display: inline-block;
        color: #6f6f6f;
        margin-right: 5px;
    }

    .meta {
        font-size: var(--font-size-small);

        .icn { color: var(--light-grey); }

        // Meta data side-by-side on tablets
        @media ( min-width: $mobileLandscape ) and ( max-width: ( $landscape - 1px ) ) {
            display: flex;
            flex-flow: row nowrap;

            .meta-item { flex: 1 1 50%; }
        }
    }

    .show-more-button {
        text-align: center;
        display: block;
        background-color: var(--f5);
        margin-top: 20px;

        .less-label { display: none; }

        .icn {
            transition: all 0.3s linear;
            transform-origin: center center;
        }
    }

    .no-content {
        border-top: 1px solid var(--soft-grey);
        font-style: italic;
        padding: 5px 0px;
        color: var(--alt-grey);
    }

    &.active .show-more-button {
        .less-label { display: inline-block; }
        .more-label { display: none }
        .icn { transform: rotate(180deg) }
    }
}

// Agenda items / News
.theme-agenda, .theme-news {
    li a { padding-right: 30px; }

    .pointer {
        position: absolute;
        width: 30px;
        right: 0px;
        top: 7px;
    }
}

//  Last modified themes list
.last-modified-themes {
    li a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .name { flex: 1 1 auto; }
}

// News
.theme-news {
    .date {
        display: block;
        font-size: var(--font-size-small);
    }
    // Hide after limit
    @include hideRows( $col-limit );
}

// Agenda items
.theme-agenda {

    // Hide after limit
    @include hideRows( $agenda-limit );
}

// Documents
.theme-docs {
    li a {
        display: flex;
        flex-flow: row nowrap;
    }

    .file-icn {
        color: var(--pdf-red);
        margin-top: 7px;
        width: 24px;
        text-align: center;
        align-self: flex-start;
        line-height: 1;
    }

    .file-info { flex: 1 1 auto; }

    @media ( min-width: $landscape ) {
        .meta {
            display: flex;
            flex-flow: row nowrap;

            .meta-item { flex: 1 1 50%; }
        }
    }

    // Add styling to unread documents (SD-47826)
    .unread {
        font-weight: bold;
        border-left: 2px solid var(--pdf-red);
        background-color: var(--f5);

        &:hover {
            background-color: var(--white);

            .doc-link { background-color: transparent; }
        }
    }

    // Hide after limit
    @include hideRows( $col-limit );
}
