//
//  Employee module
//

@media ( max-width: $adaptive-break ) {
    #content .group {
        display: flex;
        flex-flow: row wrap;

        h2, h3 { flex: 0 0 100%; }

        .medewerkers {
            position: relative;
            flex: 1 1 auto;

            .medewerker {
                flex: 0 0 33.333333%;
                margin: 0px;
                padding: 0px;
                width: auto;
            }
        }
    }
}

@media ( max-width: ( $mobileLandscape - 1px ) ){
    #content .group {
        display: block;

        .medewerkers .medewerker { flex: 0 0 50%; }

        .medewerkers, .info {
            float: none;
            clear: both;
            width: 100%;
        }

        .info { display: none; }
    }

    #content #group .groupmeta {
        width: 100%;

        .titel, .waarde {
            float: none;
            width: 100%;
        }
    }

    #ytfloat, #twitter {
        float: none;
        width: 100%;
        margin: 30px 0px;
    }

    #content .medewerkerpagina {
        .groupinfo, .meta {
            float: none;
            width: 100% !important;
            clear: both;
        }

        .groupinfo {
            padding-left: 0px;
            border-left: 0px;
        }
    }
}
