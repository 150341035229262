//
//  Loading
//  - styling for loading and spinner components
//
.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: $lightGrey;
    
    .icn {
        font-size: 36px;
        color: $softGrey;
        margin-bottom: 10px;
        display: block;
    }
}
