//
//	Containers | Base layout
//
.input, .content-type-tabs-holder, .search-main-col {
	width: 100%;

	@media (min-width: $landscape){
		max-width: var(--gos-results-size);
	}
}

// Basic layout of the results and the filters
.result-and-filters-holder {
	position: relative;

	// Show as right column, next to results
	@media ( min-width: $landscape ) {
		display: flex;
		flex-flow: row nowrap;

		.mobile-apply-buttons {
			display: none;
		}

		.search-main-col {
			flex: 0 0 var(--gos-results-size);
			margin-right: 60px;
		}

		.search-sidebar {
			flex: 1 1 auto;
			min-width: 0;
		}
		.search-background-close { display: none; }
	}

	// Show as sidebar overlay
	@media ( max-width: ( $landscape - 1px )) {
		.filters-header .apply-buttons {
			display: none;
		}

		.search-sidebar {
			width: 100vw;

			&, .search-sidebar-bar {
				position: fixed;
				left: 0px;
				top: 0px;
				height: 100vh;
				transition: all 0.25s linear;
				z-index: 10;
				visibility: hidden;
			}

			.search-background-close {
				position: absolute;
				top: 0px;
				left: 0px;
				right: 0px;
				bottom: 0px;
			}

			.search-sidebar-bar {
				width: var(--gos-sidebar-size);
				max-width: var(--gos-sidebar-size-max);
				transform: translateX(-100%);
				box-shadow:  0px 0px 15px rgba( 0, 0, 0, 0.5 );
				display: flex;
				flex-flow: column nowrap;
				background-color: $white;
			}

			.search-overflow-holder {
				overflow-x: hidden;
				background-color: $white;
				padding: 20px 20px 100px 20px;
				flex: 1 1 auto;
				position: relative;
				width: 100%;
			}

			.mobile-apply-buttons {
				justify-content: center;
				flex: 0 0 auto;
				background-color: $white;
				padding: 5px;
				box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.15 );
				z-index: 1;
			}

			.mobile-apply-buttons .apply-buttons {
				display: flex;
				justify-content: space-between;
				text-align: center;
			}

			&.active {
				background-color: var(--gos-sidebar-bg);
				visibility: visible;

				.search-sidebar-bar {
					transform: translateX(0%);
					visibility: visible;
				}
			}
		}
	}
}
