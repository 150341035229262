//
//  Settings
//  - CSS variables of the term-agenda
//  - colors are defined in colors (might be moved to generic colors.scss of css-variables.scss
//  - also include some helper mixins
//

.term-agenda-page {
    --lta-border: var(--e4);
    --lta-bg: var(--white);
    --lta-odd-bg: var(--f5);
    --lta-month-size: 200px;
    --lta-left-col: 260px;
    --lta-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    --lta-padding: 50px;
    --lta-today-offset: 0%;
    --lta-today: var(--orange);
    --lta-act-offset: 15px;
    --lta-radius: 5px;

    --lta-font-tiny: 0.85rem;
    --lta-font-small: 0.9rem;
    --lta-font-normal: 1rem;
    --lta-font-larger: #{ calcFontSize(16pt) };
    --lta-font-large: #{ calcFontSize(18pt) };


    // Define the width of the row, assuming we limit the LTA to 48 months (4 years), will be overwritten (recalculated) with JS
    //--lta-row-width: calc( (48 * var(--lta-month-size)) + var(--lta-left-col) );

    // Settings for year-view
    --lta-circle-size: 20px;
    --lta-dur-width: 120px; // Fallback for when the JS fails to add a width
    --lta-dur-height: 10px;

    .high-contrast & {
        --lta-border: var(--black);
    }
}

