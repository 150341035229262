//
//  Agenda row head
//
.agenda-row-head {

    // Base styling and flex layout
    background-color: $head-bg;
    transition: all 0.15s linear;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    padding: $offset-row 0px;
    overflow: hidden;
    line-height: 1.4;
    outline: 1px solid transparent;

    *:not(button) { pointer-events: none; }

    .nr, .row-info, .options { box-sizing: border-box; }
    .nr, .options { flex: 0 0 auto; }
    .options { align-self: center; }

    .row-info {
        flex: 1 1 auto;
        min-width: 0;

        h4 { line-height: 1.3; }
    }

    // Offset
    .nr { padding: $offset-mobile 5px; }
    .row-info { padding: $offset-mobile; }
    .options { padding-right: $offset-mobile; }

    @media ( min-width: $portrait ) {
        .nr { padding: $offset-portrait 5px; }
        .row-info { padding: $offset-portrait; }
        .options { padding-right: $offset-portrait; }
    }

    // Styling agenda item nr
    .nr {
        font-size: var(--font-size-large);
        font-weight: bold;
        text-align: center;
        border-right: 1px dotted $nr-border;
        min-width: $nr-width-mobile;
        position: relative;

        @media ( min-width: $portrait ) {
            min-width: $nr-width-portrait;
            font-size: calcEmFontSize(22pt);
        }
    }

    // Row info
    .row-info {
        a {
            color: $darkGrey;
            font-weight: bold;
            text-decoration: none;
            pointer-events: visible;
            word-wrap: break-word;

            @media ( min-width: $portrait ) {
                font-size: var(--font-size-large);
            }
        }

        @media( min-width: $mobileLandscape ) {
            .start-time { display: none; }
        }
    }

    .description {
        font-size: var(--font-size-small);
        margin-top: 5px;
        margin-bottom: 5px;

        p {
            margin: 10px 0px 0px 0px;

            &:first-of-type { margin-top: 0px; }
        }
    }

    // Options
    .options {
        line-height: 42px;
        margin-top: 20px;
        align-self: flex-start;

        a { pointer-events: visible; }

        .hide-mobile { margin-right: 10px; }

        .opt {
            display: inline-block;
            min-width: 20px;
            text-align: center;
            font-size: var(--font-size-small);
            line-height: inherit;
        }

        .pointer {
            margin-top: 6px;
            line-height: 30px;

            .icn { font-size: var(--font-size); }
        }

        @media ( min-width: $portrait ) {
            .opt {
                font-size: var(--font-size);
                min-width: 30px;
            }

            .pointer .icn {
                font-size: var(--font-size-large);
                min-width: 30px;
            }
        }
    }

    // Hover
    &:hover {
        background-color: $white;
        box-shadow: 0px 0px 5px rgba(0,0,0,.15);
        z-index: 1;
        outline-color: $border;
    }

    // Agenda arrow for opened
    .nr:before {
        content: " ";
        position: absolute;
        width: 14px;
        height: 14px;
        left: 50%;
        bottom: -40px;
        transform-origin: center center;
        transform: translate( -50%, -50% ) rotate( -45deg );
        background-color: $row-bg;
        transition: all 0.15s linear;

        @media( min-width: $portrait ) {
            transform: translate( -50%, -50% ) rotate( -45deg ) scale( 1.4 );
        }
    }
}

// Active row head
.agenda-row.active {
    .agenda-row-head {
        background-color: $white;
        box-shadow: none;
        outline-color: transparent;

        .nr {
            color: getVar(--primary, $primary);

            &:before { bottom: -20px; }
        }
    }
}

// SD-49036 Show time in a different place when
// - multisession and three sessions next to each-other and
// - sessions are not opened
.session-holder:not(.session-opened) .multi-session.grid-three {
    .agenda-row-head .options .hide-mobile { display: none }
    .meta-lint .start-time { display: inline-block }
}
