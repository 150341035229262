//
//	Search results
//

// Result head, title & url of searchresult and options
.result-head {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;

	.title { flex: 1 1 auto; }

	.options, .button {
		flex: 0 0 auto;
		margin-top: 4px;
	}
}

// Hide some unwanted stoff
.title {
	.resultnumber, .resultlabel { display: none; }
}

/* base result */
.results {
	width: 100%;

	ul { list-style: none; }

	li {
		margin: 40px 0px;
		position: relative;

		&:first-of-type { margin-top: 0px; }
		li { margin: 0px; }
	}

	.title {
		display: block;
		word-break: break-all;
		text-decoration: none;
		padding-right: 50px;

		h3 {
			line-height: 1.6;
			font-weight: bold;
			color: $darkGrey;
			margin: 0px;
			transition: all 0.15s linear;


			.icn-right {
				font-size: var(--font-size);
				display: inline-block;
				margin-left: 10px;
			}
		}

		&:hover {
			h3 { text-decoration: underline; }
			.search-pointer { color: $orange; }
		}

		&:visited {
			h3, .url { color: $mediumGrey; }
		}
	}

	.url {
		display: block;
		text-decoration: none;
		font-size: var(--font-size-small);
		word-break: break-all;
		word-wrap: break-word;
		line-height: 1.4;
		font-style: normal;
	}

	.search-pointer {
		display: block;
		position: absolute;
		right: 3px;
		top: 3px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 1.6em;
		text-align: center;
		color: $softGrey;
		transition: all 0.15s linear;
	}

	.preview {
		margin: 10px 0px;
		font-size: var(--font-size);

		&, .tag {
			line-height: var(--gos-result-line-height);
			max-height: calc(var(--gos-result-line-height) * var(--gos-result-line-amount));
			overflow: hidden;
		}

		.tag { font-weight: bold; color: $black; }
	}

	.meta-block {
		margin-left: 20px;

		:first-letter { text-transform: uppercase; }
		.meta-item { margin: 0px; }

		@media ( max-width: ( $mobileLandscape - 1px )) {
				display: none;
		}
	}
}
