//
//  Sidebar menu
//  - mobile navigation showing menu in a sidebar
//

$sidebar-padding: 20px;

.sidebar-menu {
    .clean-button {
        &, .icn { color: inherit; }

        &:hover, &:focus {
            background-color: var(--panel-border);
            border-color: var(--panel-border);
        }
    }

    .sidebar-header {
        border-bottom: 1px solid var(--panel-border);
        padding-bottom: calc( var(--panel-offset) / 2 );

        .title-holder { text-align: center; }
    }

    .menu > li {
        border-top: 1px solid var(--panel-border);

        &:first-of-type {
            border-top-color: transparent;
        }
    }

    .menu-item-wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    .menu-item {
        font-weight: bold;
        font-size: var(--font-size-large);
        padding: 15px 5px 15px 20px;
        display: inline-block;
        flex: 1 1 auto;
        transition: all 0.15s linear;

        &:hover {
            background-color: rgba( 0, 0, 0, 0.15);
        }
    }

    .menu-children {
        background-color: getVar(--panel-hightlight, $mediumGrey);
        padding: getVar(--panel-offset, 20px);
        flex: 0 0 100%;
        display: none;
        text-align: left;

        li {
            break-inside: avoid;
        }

        a {
            display: block;
            padding: 5px 10px;

            &:hover {
                background-color: getVar(--panel-bg, $darkGrey);
            }
        }

        @media ( min-width: $portrait ) {
            ul {
                column-count: 2;
                column-gap: 20px;
            }
        }
    }

    .menu-item-wrapper.active {
        .menu-children {
            display: block;
        }
    }

    .expand-trigger {
        display: inline-block;
        text-align: center;
        flex: 0 0 auto;

        * { pointer-events: none; }

        @include equalAll(44px);

        .icn {
            display: inline-block;
            border-radius: 100%;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
            color: var(--panel-bg);
            background-color: getVar(--primary, $primary);
            transition: all 0.15s linear;
            font-size: var(--font-size-smaller);

            @include equalAll(20px);

        }

        &:hover, &:focus {
            .icn { background-color: var(--white); }
        }
    }
}

@if isIE() {
    #page.is-ie {
        .sidebar-menu .menu-children ul {
            column-count: 1;
            list-style: none;
        }
    }
}

// Active | open panel
.expanded-menu-open {
    .sidebar-menu {
        pointer-events: visible;
        visibility: visible;

        .panel { transform: translateX(0); }
        .close-overlay { opacity: 1; }
    }
}
