//
//  Page head
//  - contains the main page title
//  - wraps the breadcrumb aswel
//  - can contain page options?
//
.page-head {
    position: relative;
    clear: both;
    margin-bottom: 30px;
    overflow-wrap: break-word;

    .no-offset & { margin: 0px; }

    .identifier {
        display: block;
        color: var(--alt-grey);
        font-size: var(--font-size-large);
    }
	@media print {
	    margin-top: 10mm;
    }
}

.tile .page-head {
    margin-top: 0px;
}
