//
//  Domain row
//  - styling for the domain row
//
.term-agenda {
    .domain-row {
        .domain-grid .month {
            position: relative;
            background-color: var(--dr-bg);

            &.odd { --dr-bg: var(--dr-bg-odd); }
        }

        .domain-button {
            background-color: var(--lta-bg);
            font-weight: bold;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            cursor: pointer;

            *, &:before, &:after {
                pointer-events: none;
                transition: all 0.15s linear;
            }

            .name {
                flex: 1 1 auto;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:before {
                content: " ";
                position: absolute;
                inset: 0px auto 0px 0px;
                width: 5px;
                background-color: var(--dr-btn-left);
            }

            // Add extra bg with the domain color to prevent issues with transparency overlay
            &:after {
                content: " ";
                position: absolute;
                inset: 0px;
                background-color: var(--dr-btn);
                z-index: -1;
            }

            &:hover, &:focus-visible {
                color: var(--white);
                &:after { background-color: var(--focus-color); }
            }
        }

        .strip {
            position: absolute;
            width: 5px;
            opacity: 0.6;
            background-color: var(--dr-btn-left);
            height: 100%;
        }

        .activities-holder {
            opacity: 1;
            transition: all 0.15s linear;
        }
    }

    .domain {
        // Toggleable domains (with JS)
        &.active {
            .domain-row .activities-holder { opacity: 0; }
        }

        &:not(.active) {
            .dossiers { display: none; }
            .domain-button .icn { transform: rotate(-90deg) }
        }
    }
}

// Keyboard focus on domain row button
#page.user-is-tabbing {
    .main-button:focus { outline-offset: -10px; }
    .domain-button:focus { outline-color: var(--white); }
}
