//
//  Theme view
//  - styling for the theme detais view
//
.subthemes {
    background-color: $f5;
    border-top: 1px solid $e4;
    min-height: 300px;
    margin-bottom: -70px;
}

.theme-page .theme-tabs-holder { margin-bottom: 70px; }

.theme-page, .theme-overview {
   [role="main"] h2 { font-size: var(--font-size-large); }
}
