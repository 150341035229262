//
//  Header
//  - modernized version of the header of the old default2014
//

// Settings
$quicksearch-size: 36px;
$opt-size: 40px;
$initials-size: 28px;
$quicksearch-breakpoint: $portrait;

// Include common parts
.header-basic {
    @import "basic/_common";
}

// Include specifics
@import "basic/header";
