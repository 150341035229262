//
//  News
//  - styling for news overview and news publication
//

.news-publication, .news-overview {
	.period-tabs + .page-head { margin-top: 20px; }
}

.news-publication {
	.meta-lint { margin: 10px 0px; }
	.content .button { margin-top: 20px; }
}

.news-overview {
	--news-thumb: 200px;

	.featured { margin-bottom: 40px; }

	.featured-item, .new-list a {
		color: inherit;
		text-decoration: none;
		padding: 5px;
		transition: all 0.15s linear;

		&:hover, &:focus { background-color: var(--f5); }

		h2 {
			font-size: var(--font-size-large);
			margin: 0px;
		}
	}

	.featured-item {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		margin: 20px 0px;

		.thumb {
			flex: 0 0 var(--news-thumb);
			margin-right: 20px;
		}

		.date {
			font-size: var(--font-size-small);
			margin-bottom: 10px;
			display: block;
		}

		.news-item-content { flex: 1 1 auto; }

		&.right-aligned {
			flex-direction: row-reverse;

			.thumb {
				margin-right: 0;
				margin-left: 20px;
			}
		}
	}

	.new-list {
		a {
			display: flex;
			flex-flow: row nowrap;

			.date { flex: 0 0 12ch; }
			.icn { margin-top: 6px; }
			.title { font-weight: bold }
		}
	}

	@media ( max-width: ( $mobileLandscape - 1px )) {
		--news-thumb: 100px;
	}

	// Change layout for portrait devices
	@media ( min-width: $mobileLandscape ) and ( max-width: ( $landscape - 1px )) {
		.featured {
			display: grid;
			grid-template-columns: repeat( auto-fit, minmax( 200px, 1fr ));
			grid-gap: 20px;

			.featured-item { display: block; }
			.thumb { margin-bottom: 10px; }
		}
	}
}
