//
//  Voting Modal Graph
//
:root {
    --graph-size-outer: 150px;
    --graph-size-inner: 90px;
}

.voting-modal {
    .graph-holder {
        margin: 40px 0px 50px 0px;

        @media( min-width: $mobileLandscape ) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }

    .voting-graph {
        flex-basis: var(--graph-size-outer);
        width: var(--graph-size-outer);
        height: var(--graph-size-outer);
        position: relative;
        transition: all 0.15s linear;

        background: conic-gradient(
            var(--red) 0% var(--graph-disagree),
            var(--blue) var(--graph-disagree) var(--graph-abstain),
            var(--grey) var(--graph-abstain) var(--graph-absent),
            var(--green) var(--graph-absent) 100%
        );

        &, .inner {
            border-radius: 100%;
            text-align: center;
            display: block;
        }

        .inner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: var(--graph-size-inner);
            height: var(--graph-size-inner);
            background-color: $white;
        }
    }

    .inner {
        .perc {
            font-weight: bold;
            font-size: 20px;
            line-height: 1.2;
            padding-top: 20px;
            display: block;

            &.agree { color: var(--green); }
            &.disagree { color: var(--red); }
            &.abstain { color: var(--blue); }
            &.absent { color: var(--grey); }
        }

        .lbl {
            display: block;
            font-size: var(--font-size-small);
            line-height: 1.2;
        }
    }

    // Legend next to graph (or below on smaller screens)
    .voting-legend {
        flex: 1 1 auto;
        list-style: none;
        margin: 0px 0px 0px 45px;
        padding: 0px;

        li {
            --legend-square: 20px;
            line-height: var(--legend-square);
            padding-left: 30px;
            position: relative;
            margin: 15px 0px;

            &:before {
                content: " ";
                display: inline-block;
                width: var(--legend-square);
                height: var(--legend-square);
                border-radius: 3px;
                position: absolute;
                top: 0px;
                left: 0px;
            }

            &.agree:before { background-color: var(--green); }
            &.disagree:before { background-color: var(--red); }
            &.abstain:before { background-color: var(--blue); }
            &.absent:before { background-color: var(--grey); }
        }
    }

    // Change layout for small screens
    @media( max-width: ($mobileLandscape - 1px )) {
        .graph-holder { text-align: center; }
        .voting-graph { margin: 40px auto; }

        .voting-legend {
            margin: 0px;
            display: flex;
            flex-flow: row wrap;

            li {
                --legend-square: 12px;
                flex: 0 0 50%;
                text-align: left;
                padding-left: 16px;
                font-size: var(--font-size-small);
                margin-top: 0px;

                span {
                    display: block;
                    font-size: var(--font-size-smaller);
                }
            }
        }
    }
}
