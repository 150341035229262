//
//  Session base
//
.session {
    border: 1px solid $border;
    background-color: $head-bg;

    // Fix for margin between main-session followed by main-session
    + .session:not(.part-session) {
        margin-top: 30px;
    }
}

.part-session {
    padding: 15px;
    background-color: $white;

    @media ( min-width: $mobileLandscape ) {
        padding: 25px;
    }

    @media ( min-width: $landscape ) {
        padding: 0px;
        background-color: $head-bg;
    }
}

.session-toolbar {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 15px 10px 10px 10px;

    .button { margin: 5px; }

    h4 {
        flex: 0 0 100%;
        font-size: var(--font-size-small);
        font-weight: bold;
        color: $lightGrey;
        
        @media ( min-width: $portrait ) {
            display: none;
        }
    }
    
    @media ( min-width: $mobileLandscape ) {
        margin: 15px 0px 0px 0px;
    }
    
    @media ( min-width: $portrait ) {
        margin-top: 30px;
    }
    
    @media ( min-width: $landscape ) {
        display: none;
    }
}
