//
//  Votings
//  - styling for votings overview
//  - used in employee / group profile, tab for votings
//  - used in my dashboard
//

.voting {

    // Correct meta-lint icons
    .icn {
        &.color-green { color: $green; }
        &.color-red { color: $red; }
        &.color-grey-300 { color: $lightGrey; }
    }

    @import "legenda";
    @import "overview";

    // Tab content
    .tab-content {
        display: none;
        padding: 30px 0px;

        &.active { display: block; }
    }
}

