//
//  Thumb styling
//  - extends default image.scss ( partials/image.scss )
//  - adds a  bit of extra style for the .no-image class, showing a person icon
//
.employees {

    .thumb {
        position: relative;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            object-fit: cover;
            object-position: top center;
        }

        &.no-image {
            background-color: $f5;
            border: 1px solid $e4;
            background-image: none;

            .icn {
                font-size: 60px;
                position: absolute;
                top: 50%;
                margin-top: -30px;
                line-height: 60px;
                text-align: center;
                width: 100%;
                color: $softGrey;
                left: 0px;
            }
        }
    }
}
