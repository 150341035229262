//
//  Meta blocking
//  - generic component showing metadata
//
.meta-block {
    --meta-border: #{$e4};
    --meta-bg: #{$white};

    font-size: var(--font-size-small);
    column-gap: 20px;
    padding-top: 10px;

    @media( min-width: $mobileLandscape ) {
        column-count: var(--meta-column-count);
    }
    
    .meta-item {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 5px;
        gap: 5px;
    }
    
    .icn {
        color: var(--alt-grey);
        margin-right: 5px;
    }

    .tag { margin: 0 0 0 5px }
    
    dt, dd { margin: 0px; }
    dd, .value { flex: 1 1 auto; }
    
    dt, .label {
        flex: 0 0 140px;
        font-weight: bold;
    }
    
    // Mobile break, breaks to label and value on seperate rows on small mobile devices
    &.break-mobile {
        @media ( max-width: ( $mobileLandscape - 1px ) ) {
            .meta-item {
                display: block;
                clear: both;
            }
            
            dd, .value {
                display: block;
                padding-left: 24px;
            }
        }
    }

    // Boxed with light-grey background
    &.boxed {
        padding: 20px;
        border: 1px solid var(--meta-border);
        background-color: var(--meta-bg);
    }

    &.grey {
        --meta-bg: #{$f5};
        --meta-border: var(--meta-bg);
    }
}
