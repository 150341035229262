//
//  Load headers and menu
//  - load the header, menu and sidebar combinations styles
//

// Settings for main header
//$header-background: hsl(var(--header-bg-color), var(--header-bg-lum));
//$header-background-dark: hsl(var(--header-bg-color), calc(var(--header-bg-lum) - 10%));
$header-background: var(--header-bg);
$header-background-dark: color-mix( in srgb, var(--header-bg) 60%, var(--black) );

$header-logo-background:                getVar( --logo-bg, $primary);
$header-logo-image:                     getVar( --emblem-alt, $emblem-alt);
$header-logo-size:                      80% auto;
$header-logo-width:                     getVar( --logo-width, 95px );


// Include common styling form profile dropdown
@import "partials/myAccount";

// Include specific headers
@import "basic";
@import "double-menu";
@import "main/_load";
@import "partials/hoverable";

// Include color-schems
@import "color-schemes";
