//
//  Icon
//  - default styling for icn element
//
$icon-size: 16px;
$amount-size: 14px;
$amount-2x: $amount-size * 1.5;
$amount-3x: $amount-size * 2;

// Default
.icn, .icn-stack {
    display: inline-block;
    min-width: $icon-size;
    text-align: center;
    position: relative;
    box-sizing: border-box;
}

// Alignment
.icn-left {
    margin-left: 0px;
    margin-right: 5px;
}

.icn-right {
    margin-left: 5px;
    margin-right: 0px;
}

// Stack
.icn-stack {
    .icn {
        margin: 0px;
        pointer-events: none;
    }

    .icn:last-of-type:not(:first-of-type), .amount {
        position: absolute;
        left: 50%;
        top: 50%;
        vertical-align: middle;
    }

    .icn:last-of-type:not(:first-of-type) {
        min-width: 50%;
        font-size: 80%;
    }

    .amount {
        top: auto;
        bottom: 50%;
        height: $amount-size;
        width: $amount-size;
        line-height: $amount-size;
        border-radius: 100%;
        background-color: var(--orange);
        color: var(--white);
        font-size: 70%;
        box-shadow: 0px 0px 2px var(--white);

        &:empty {
            display: none;
        }
    }

    .color-orb {
        position: absolute;
        bottom: 0px;
        right: -2px;
        margin: 0px;
    }

    &:has(.color-orb) {
        line-height: 1;
    }

    .color-orb {
        position: absolute;
        bottom: 0px;
        right: -2px;
        margin: 0px;
    }

    &:has(.color-orb) {
        line-height: 1;
    }
    
    // Private (locked) icon-stack
    &.private {
        .icn {
            color: var(--light-grey);
        }

        .fa-lock {
            color: var(--orange);
            text-shadow: -2px -2px white;
        }
    }

    &.has-read {
        .amount {
            background-color: var(--light-grey);
        }
    }
}

// Sizes
.icn-2x {
    min-width: 24px;
    font-size: 1.7rem;

    .amount {
        margin-right: -3px;
        height: $amount-2x;
        width: $amount-2x;
        line-height: $amount-2x;
    }
}

.icn-3x {
    min-width: 32px;
    font-size: 2.2rem;

    .amount {
        margin-right: -3px;
        height: $amount-3x;
        width: $amount-3x;
        line-height: $amount-3x;
    }
}

// Color schemes
@each $color in $colors {
    $name: nth($color, 1);
    $value: nth($color, 2);

    .icn.#{""+$name} {
        color: getVar(#{"--"+$name}, nth($value, 1));
    }
}
