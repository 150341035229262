//
//  Modal filters
//  - extra styling for the filter modal used on a documents overview
//
.modal-overview-filters {
    h4, .heading {
        flex: 1 1 100%;
        grid-column: 1 / -1;
    }

    .heading {
        display: flex;
        flex-flow: row nowrap;

        button { flex: 0 0 auto; }
    }

    .field-group, .checkbox { margin-top: 0px; }

    .filter-group {
        display: flex;
        flex-wrap: wrap;
        gap: 0px 10px;
        margin-bottom: 20px;
    }

    .field-group {
        flex: 1 1 auto;
        gap: 5px;
    }

    .checker { flex: 1 1 auto; }
    .delimiter { flex: 0 0 auto; }

    .document-type-filter {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
    }
}
