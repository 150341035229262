//
//  Employee votings tab
//
.tab-votings {
    --_result: 14ch;
    --_vote: var(--_result);
    --_grid:
        [date] 8ch
        [description] 1fr
        [vote] var(--_vote)
        [result] var(--_result)
        [icn] 40px;
    --_progress-bg: var(--dark-grey);
    --_label: inline-block;
    --_label-align: left;

    @media (max-width: ($mobileLandscape - 1px)) {
        --_label: none;
        --_label-align: center;
        --_result: 7ch;
    }

    .tab-titlebar {
        margin-bottom: 20px;

        @media (min-width: $mobileLandscape) {
            display: flex;
            flex-flow: row nowrap;
            gap: 10px;
        }

        h2 { flex: 1 1 auto; }

        label {
            font-weight: bold;
            flex-basis: 8ch;
        }
    }
}

.voting-table-view {
    display: grid;
    grid-template-columns: var(--_grid);
    grid-gap: 0px 10px;
    align-items: center;
    margin-bottom: 30px;

    .voting-row, .head { padding: 5px; }
    .my-vote, .vote-result, .no-result { text-align: center; }
    .no-result { grid-column: 1 / -1; }
    .description { font-weight: bold; }

    .label {
        display: var(--_label);
        text-align: var(--_label-align);
    }

    .head {
        line-height: 1;
        font-weight: bold;
    }

    .voting-row, .voting-row-li, .head {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
        align-items: center;
    }

    .voting-row .date {
        font-weight: bold;
        color: var(--alt-grey);
    }

    .vote-result-holder {
        grid-column: vote / icn;
        display: grid;
        grid-template-columns: subgrid;
        position: relative;
    }

    .voting-bar {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.15s linear;
        background-color: var(--white);
        padding: 15px 15px 20px 15px;
        filter: drop-shadow(0px 0px 3px rgba( 0, 0, 0, 0.25 ));
        width: 260px;
        z-index: 1;
        pointer-events: none;
        opacity: 0;
        margin-top: -10px;
        border-radius: 3px;

        progress {
            width: 100%;
            background: var(--e4);
            color: var(--_progress-bg);
            appearance: none;
            border: 0px;
            height: 15px;
            display: block;

            &::-webkit-progress-bar { background: rgba(0, 0, 0, 0); }
            &::-webkit-progress-value { background: var(--_progress-bg); }
            &::-moz-progress-bar { background: var(--_progress-bg); }
        }

        &.green { --_progress-bg: var(--green); }
        &.red { --_progress-bg: var(--red); }
    }

    .vote-result-holder:hover {
        .voting-bar {
            opacity: 1;
            margin-top: 5px;
        }
    }

    .arrow {
        overflow: hidden;
        top: -8px;
        right: calc(25% - 10px);
        position: absolute;
        transform-origin: center;
        background-color: $white;
        height: 16px;
        width: 16px;
        transform: translateX(-50%) rotate(-45deg);
        left: 50%;
    }

    // Styling for mobile vote results element
    .mobile-vote-results {
        font-size: 0.9rem;
        font-weight: normal;
        color: var(--alt-grey);
        display: none; // only visible on screens smaller than 449px;

        & > span {
            display: inline-block;
            padding-right: 10px;
        }
    }
}

// For extra small screens, reorder the layout so the my-vote and results are below the title
@media (max-width: 449px ) {
    .tab-votings {

        .head .my-vote, .head .vote-result,
        .vote-result-holder { display: none; }
        .mobile-vote-results { display: block; }

        .description {
            grid-column: span 3;
        }
    }
}

// Extra styling for group page votings
.party .tab-votings {
    --_vote: 20ch;

    .vote-result-holder .group-votes {
        display: flex;
        flex-flow: row nowrap;

        & > span { flex: 0 0 50%; }
    }

    @media (max-width: ($mobileLandscape - 1px)) {
        --_vote: 10ch;
    }

    @media (max-width: 449px ) {
        .head .group-votes { display: none; }
    }
}
