//
//  Variables
//

// Responsive breakpoints
$mobileSmall        :   360px; // Breakpoint for small mobile screens ( mostly 320px )
$mobileLandscape    :   640px;
$portrait           :   768px;
$landscape          :   992px;
$desktop            :   1200px;
$desktopLarge       :   1400px;

// text color
$text-color-base:                       $darkGrey;

// links
$link-color:                            $primary;
$link-color-hover:                      $secondary;

// Focus
$hover-blue: #136bd8;
$focus-outline-color: $hover-blue;

//
//   Settings
//

//
//   Typography
//

$font-awesome       : 'Font Awesome 5 Free', sans-serif;
$open-sans          : 'Open Sans', sans-serif;
$montserrat         : 'Montserrat', $open-sans;
$font-family-base   : $open-sans;

//
//   Animations
//

$transition-base:                       all .2s ease;
$transition-background:                 background .2s linear;


//
//  Form
//
$fieldset-border: $softGrey;
$input-border: #7e97a9;

//
// Media root path
//
$root-path: '../';

@if( $application == 'CMS' ) {
    $root-path: '/style/';
}

@if( $application == 'RIS' ) {
    $root-path: '/site/default2020/';
}

$img-path: $root-path + 'images/';


// Logo paths
$logo: $img-path + 'logo.svg';
$logo-alt: $img-path + 'logo_alt.svg';

$emblem: $img-path + 'emblem.svg';
$emblem-alt: $img-path + 'emblem_alt.svg';
