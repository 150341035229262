//
// Toggle block
//

.toggleblock {
    padding: 15px 20px;
    margin-top: 30px;
    background-color: $white;
    border: 1px solid $softGrey;

    &.grey {
        background-color: $f5;
        border-color: $f5;
    }

    .togglecontent {
        max-height: 0px;
        overflow: hidden;
        transition: all 0.5s ease-out;
        pointer-events: none;
        visibility: hidden;
    }

    .toolbar {
        align-items: center;

        h3, h4 { cursor: pointer; flex: 1 1 auto; }
        .fa-minus { display: none; }
    }

    &.active {
        .toolbar {
            .fa-plus { display: none; }
            .fa-minus { display: inline; }
        }

        .togglecontent {
            max-height: 300px;
            overflow-y: auto;
            pointer-events: visible;
            visibility: visible;
        }
    }
}
