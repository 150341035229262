//
//  Themes & districts
//

@media ( max-width: $adaptive-break ) {
    #page.themas {
        #thema, #sidebar {
            width: 100%;
            float: none;
            clear: both;
            border-left: 0px dotted $softGrey;
            border-top: 3px dotted $softGrey;
            padding: 30px 0px;
            margin: 0px;
        }
    }
}

@media ( max-width: ( $mobileLandscape - 1px )) {
    #page.thema #thema .grid .block,
    #page.thema #thema .block {
        width: 100%;
        float: none;
        clear: both;
    }
}
