//
//  Accessibility toolbar
//
.ac-toolbar {
    --_acces-bg: var(--dark-grey);
    --_acces-txt: var(--white);
    --_acces-br: 5px;
    --_acces-size: 70px;
    display: block;
    box-sizing: border-box;

    @media( min-width: $portrait ) {
        position: fixed;
        inset: 45% 0px auto auto;
        transform: translateY(-50%);
        z-index: 6;
        box-shadow: 0px 0px 3px rgba( 0, 0, 0, 0.3 );
        border-radius: var(--_acces-br);
        width: var(--_acces-size);
    }

    .ac-toolbar-holder {
        display: flex;
        flex-flow: row nowrap;

        @media( min-width: $portrait ) {
            flex-direction: column;
        }
    }

    button {
        display: block;
        background-color: var(--_acces-bg);
        color: var(--_acces-txt);
        border: 0px;
        padding: 10px 15px;
        cursor: pointer;
        flex: 1 1 auto;
        text-align: center;
        transition: all 0.15s linear;

        @media( min-width: $portrait ) {
            border: 1px solid var(--_acces-br);
            padding: 10px;
            font-size: var(--font-size-smaller);
            flex: 0 0 var(--_acces-size);
        }

        * { pointer-events: none; }

        @media( max-width: ($portrait - 1px) ) {
            &:not(:first-child) { border-left: 1px solid var(--medium-grey); }
        }
        &:hover { --_acces-bg: var(--medium-grey); }
        &:focus-visible { --_acces-bg: var(--focus-color); }

        &.selected {
            --_acces-bg: var(--white);
            --_acces-txt: var(--black);
        }

        @media( min-width: $portrait ) {
            &:first-child { border-top-left-radius: var(--_acces-br); }
            &:last-child { border-bottom-left-radius: var(--_acces-br); }

            .label {
                display: block;
                margin-bottom: 10px;
            }

            .icn {
                display: block;
                font-size: var(--font-size-larger);
            }
        }
    }
}
