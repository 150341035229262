.loading-placeholder {
  position: absolute;
  text-align: center;
  background: white;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.15s linear 0.15s;
  opacity: 0;
  pointer-events: none;

  .loading-txt {
    position: absolute;
    top: 40%;
    left: 0px;
    width: 100%;
  }
}

.is-loading .loading-placeholder { opacity: 1; pointer-events: visible }