//
//  Notification content
//

.notifications {
    .noti-content {
        border-top: $border;
        border-bottom: $border;
        padding: 0px;
        margin: 0px;
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
        list-style: none;
        min-height: 75px;
    }

    .rec {
        padding: 5px 5px 5px 10px;
        border-radius: 3px;
        margin: 5px;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        animation: 1.5s linear 0s 1 recordIn;

        &:first-of-type { animation: none; }
        .msg { flex: 1 1 auto; }
        .button, .icn { color: inherit; }

        @media ( min-width: $desktop ) {
            &:not(:hover) .button { opacity: 0; }
        }

    }
}

@keyframes recordIn {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 1;
        background-color: $hover-blue;
        color: $white;
    }

    85% {
        background-color: $hover-blue;
        color: $white;
    }

    100% {
        background-color: $white;
    }
}
