//
//	Search form
//

$submit-size: 46px;

.form, .base {
	padding: 0px;
	background-color: transparent;
}

.base { border-bottom: 1px solid $softGrey; }

.input {
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: 30px;
	padding: 5px;

	.searchinput {
		flex: 1 1 auto;
		border: 0px;
		border-radius: 5px;
		margin: 0px;
		font-size: var(--font-size-large);
		padding: 6px 10px 8px 15px;

		&:focus {
			outline: none;
			border: 0px;
			box-shadow: none;
		}
	}

	.button {
		flex: 0 0 auto;
		border-radius: 4px;
		width: $submit-size;
		min-width: $submit-size;
		height: $submit-size;
		padding-left:0px;
		padding-right:0px;
		text-align: center;
		cursor: pointer;
		overflow: hidden;
		font-size: var(--font-size-large);
	}
}

.submit, .submit:hover { margin-right: 0px; }

.input {
	position: relative;
	border-radius: 5px;
	box-shadow: 0px 2px 0px rgba( 0,0,0,0.07);
	border: 1px solid $input-border;
	background: $white;
	width: 100%;
	box-sizing: border-box;

	&:focus-within {
		--suggestions-border: #{$input-border};
		box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);

		.suggestions-dropdown {
			padding: 10px;
			inset: 100% -1px auto -1px;
			border-top: 0px;
			margin-top: -4px;
			width: auto;

			ul {
				padding-top: 10px;
				border-top: 1px solid var(--e4);
			}
		}
	}
}
