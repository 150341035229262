//
//  Termagenda list view
//  - list view of the termagenda
//
.activities-list {
    position: relative;

    .activities-list-heading, .activities-list-row {
        display: grid;
        grid-template-columns: 125px auto 235px 100px;
    }

    .dossiers, .type, .start, .subjects-details {
        color: var(--alt-grey);
    }

    .activities-list-heading {
        font-weight: bold;
        border-bottom: 1px solid var(--soft-grey);
        padding-bottom: 10px;
    }

    li {
        padding: 5px 0;
        border-bottom: 1px solid var(--soft-grey);
    }

    .activities-list-row {
        padding: 5px;
        border-radius: 3px;
        transition: all 0.15s linear;

        &:hover {
            background-color: var(--f5);

            .act-title {
                text-decoration: underline;
                color: var(--focus-color);
            }
        }
    }

    .dossiers {
        font-size: var(--font-size-small);
        overflow-wrap: break-word;

        span { display: block; }
        &:not(.active) span:not(.more-text):nth-of-type( n + 3 ) { display: none; }
    }

    .more-text {
        color: var(--secondary);
        text-decoration: underline;
    }

    .subjects {
        font-weight: bold;

        .subjects-details {
            font-weight: normal;
            display: flex;
            gap: 10px;
            font-size: 0.9rem;

            .subjects-details-location {
                min-width: 85px;

                .icn { padding-right: 5px; }
            }

            .subjects-details-domain { vertical-align: unset!important; }
        }
    }

    // List on small screens
    @media( min-width: $mobileLandscape ){
        .hide-desktop { display: none; }
    }

    @media (max-width: ($mobileLandscape - 1px )) {
        & { border-top: 1px solid var(--soft-grey); }

        .activities-list-heading { display: none; }
        .activities-list-row { display: block; }
        .start, .type { font-size: var(--font-size-small); }

        .hide-desktop {
            display: inline-block;
            min-width: 8ch;
        }
    }

    // Status messages
    .activities-list-error, .no-content {
        display: none;
    }

    &[data-mode="no-content"] {
        .no-content { display: block; }
        .pagination-holder { display: none; }
    }

    &[data-mode="error"] {
        .activities-list-error { display: block; }
        .pagination-holder { display: none; }
        .activities-list-content .clear-list { display: none; }
    }

    &[data-mode="is-loading"] {
        .loading-placeholder { opacity: 1; pointer-events: visible }
        .pagination-holder { display: none }
    }

    // Styling fixes for status messages
    .loading-placeholder { top: 40px; }
    .loading-txt { top: 100px; }

    .no-content {
        text-align: center;
        padding-top: 50px;
    }
}
