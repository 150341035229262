//
//  Info
//  - styling for document info overlay or document subject fold-out
//

// Tweak overlay
.document-info-overlay {
    
    @media ( min-width: $portrait ){
        .window.medium { width: 600px; }
    }
    
    .window-header .subtitle .icn { color: var(--alt-grey); }
    .window-content { font-size: var(--font-size-small); }

    // Contributors
    .contributors {
        margin-top: 30px;

        .icn { color: var(--green); }

        ul {
            list-style: none;
            column-count: 2;
            column-gap: 30px;
            margin: 10px 0px;
            padding: 0px;
        }

        p { margin: 0px; }

        li {
            break-inside: avoid;
        }

        a {
            border-radius: 3px;
            text-decoration: none;
            color: inherit;
            display: block;
            margin-bottom: 2px;
            padding: 3px 5px;

            &:hover { background-color: var(--f5); }

            .role {
                font-size: var(--font-size-small);
            }

            .name:not(:hover) {
                color: var(--link-color);
                text-decoration: underline;
            }
        }
    }

    &:not(.confidential) .private-message {
        display: none;
    }
    &:not(.show-extra-fields) .extra-fields {
        display: none;
    }
    &:not(.show-handling-meetings) .attached-meetings {
        display: none;
    }
    &:not(.show-document-persons) .contributors {
        display: none;
    }
}

// Meta information block(s)
.meta-info, .attached-meetings {
    h4, .meta-block { font-size: var(--font-size-small); }
    
    h4 {
        font-weight: bold;
        margin: 30px 0px 10px 0px;
    }
    
    dt, .label {
        color: var(--medium-grey);
        font-weight: normal;
        flex-basis: 150px;
        margin-right: 20px;
    }
}

// Related meeting link/button
.related-meeting {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-align: left;
    
    time { color: var(--light-grey); }
    
    .name {
        display: inline-block;
        width: 210px;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

// Private document warning
.private-message {
    border: 1px solid var(--soft-grey);
    padding: 10px 10px 10px 17px;
    line-height: 30px;
    font-size: var(--font-size);
    margin: 30px 0px;
    
    .private-txt {
        flex: 1 1 auto;
        font-weight: bold;
    }
    
    .icn-stack {
        font-size: 20px;
        margin-right: 15px;
    }
}

// Download file block
//  - shows a link with file-name, type, size and download icon in a grey block
.download-file-block {
    display: block;
    padding: 10px 50px 10px 15px;
    border: 1px solid var(--soft-grey);
    background-color: var(--f5);
    transition: all 0.15s linear;
    color: inherit;
    text-decoration: none;
    position: relative;
    font-size: var(--font-size);
    
    &:hover {
        background-color: var(--back-blue);
        border-color: var(--light-blue);
    }
    
    .icn {
        position: absolute;
        right: 20px;
        top: 50%;
        font-size: var(--font-size-large);
        transform-origin: center center;
        transform: translateY(-50%);
    }
    
    .name {
        display: block;
        font-weight: bold;
    }
}
