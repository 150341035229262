//
//  Overlay
//  - styling for the background of popOver windows, modals, loaders
//
.overlay, .close-overlay {
    position: fixed;
    box-sizing: border-box;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

// Overlay
.overlay {
    z-index: 15;
    background-color: rgba( $white, 0.8 );
    transition: opacity 0.15s linear;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    
    &.active, &:target {
        opacity: 1;
        pointer-events: visible;
        visibility: visible;
    }
    
    // Color variations
    &.dark {
        background-color: rgba( #222222, 0.9 );
    }
}

// Close helper, for click outside window closing events
.close-overlay {
    z-index: 11;
    opacity: 0;
    cursor: pointer;
}
