//
//  Settings and CSS variables for the meeting-page
//

$row-bg: var(--ec);       // Color of the row background / visible in fold-out
$head-bg: var(--f5);      // Color of the row head background
$border: var(--soft-grey);     // Color of the row border
$nr-border: #9eaeaf;    // Color of the right border of the agenda item number

// Offset & widths
$offset-row: 5px;
$offset-mobile: 5px;        // Offset of nr, info and options on mobile
$offset-portrait: 10px;     // Offset of nr, info and options on tablet portrait and larger

$nr-width-mobile: 40px;     // Min width of nr. on mobile
$nr-width-portrait: 60px;   // Min width of nr. on tablet portrait and larger

// CSS variables
.meeting-page {
    --meeting-row-max: 5000px;
}
