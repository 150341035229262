//
//  No content element in employees
//
.employees {
    .no-content {
        margin-top: 30px;
        text-align: center;

        .icn {
            display: block;
            color: $softGrey;
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
}
