//
//  Basic quicksearch components
//
.light-quicksearch {
    position: relative;
    margin: 0px;
    transition: flex 0.15s linear;
    transition-delay: 0.5s;

    &:focus-within {
        flex: 1 1 400px;
        transition-delay: 0s;
    }

    .input > .icn {
        top: 2px;
        left: 2px;
        right: auto;
        color: var(--soft-grey);
    }

    input {
        border: 1px solid var(--ee);
        background-color: var(--ee);
        border-radius: 5px;
        padding: 5px 5px 5px 30px;
        margin: 0px;

        &:focus {
            box-shadow: none;
            border-color: var(--focus-color);
            background-color: var(--white);
        }
    }

    button {
        position: absolute;
        inset:  0 0 0 auto;
        padding: 4px 0px;

        .icn {
            color: var(--alt-grey);
        }
    }

    &:has(input:placeholder-shown) [data-input-clear] { display: none; }
}
