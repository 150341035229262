//
//  Filtes
//  - Styling for the elements in the filters part right-column / sidebar
//
.advanced-filters {

    // For everything
    .filters-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 15px;

        h2 {
            flex: 1 1 auto;
            margin: 0px;
        }
    }

    [data-select-group]:not(:hover, :focus) {
        text-decoration: underline;
    }

    fieldset {
        margin-bottom: 20px;
        border-bottom: 1px solid $e4;
        min-width: 0;

        &:last-of-type { border-bottom: 0px; }

        .more-button {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .field-wrap label {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            padding: 0px;

            & > span { flex: 1 1 auto; }

            &:before {
                flex: 0 0 auto;
                margin-top: 1px;
            }
        }

        &.active {
            .more-button {
                .more { display: none; }
                .less { display: inline; }
            }
        }

        &:not(.active) {
            .field-wrap:nth-of-type(n+6) { display: none; }

            .more-button {
                .more { display: inline; }
                .less { display: none; }
            }
        }

        .subgroup { margin-left: 10px; }
    }

    // For filters in right column
    @media ( min-width: $landscape ) {
        .filters-header {
            .reset {
                .icn { margin: 0px; }

                .btn-txt {
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    width: 0px;
                    height: 0px;
                    opacity: 0;
                }
            }

            .sidebar-close { display: none; }
        }

        .mobile-apply-buttons { display: none; }
    }

    // For filters in sidebar overlay (mobile and tablet portait)
    @media ( max-width: ($landscape - 1px)) {
        .filter-header .apply-buttons { display: none; }
    }
}
