//
//  List options
//  - Options for the default-rows configurable on parent element
//

// Show a counter before each row
.use-counter {
    counter-reset: default-list-counter;

    .default-row {
        counter-increment: default-list-counter;
        container-type: normal;
        align-items: flex-start;

        &:before {
            font-weight: bold;
            margin-right: 5px;
            content: counter(default-list-counter) ".";
        }

        &:first-of-type:last-of-type {
            &:before {
                display: none;
            }
        }
    }
}

// Smaller row icons
.smaller-row-icons {
    .row-icn {
        font-size: 1rem;
        align-self: flex-start;
        margin-top: 2px;

        @media ( min-width: $portrait ) {
            font-size: calcFontSize(18pt);
        }
    }
}

// Bordered lists
.bordered-list {
    li:not(.head) { border-top: 1px solid var(--e4); }

    .default-row {
        margin: 0px;
        border-radius: 0px;
    }
}

// Boxed, adds background to elements
.boxed-list {
    --item-row-bg: var(--f5);
    --unread-bg: var(--white);

    .unread {
        box-shadow: 0 0 3px #0003;
    }

}

// In light-grey rows
.light-grey {
    --item-row-bg: var(--ee);
    --item-row-hover-bg: var(--f5);
    --unread-bg: var(--white);
}
