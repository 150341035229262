//
//  Dossier documents
//  - extra styling for the documents overview on the dossier page
//  - does not include the styling for a document row, this is in a separate partials
//

.dossier-attachments {
    .content-header {
        border-bottom: 1px solid var(--soft-grey);
        align-items: center;
    }
    :not(.show-no-content) + .all-filtered { display: none; }
    @media (min-width: 640px) {
        .options { max-width: 300px }
    }
}
