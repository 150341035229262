//
//  Session bar
//  - shows a tabbar for navigation of the multisession
//  - as horizontal tabs on mobile devices
//  - as vertical tabbar on larger devices when a session is opened
//

// Helper mixins
@mixin showPortrait() {
    .session-tab { padding-left: 15px; }
    .session-info { display: block; }
    .prefix { display: none; }
}

// Styling of the bar
.session-tabs {
    position: relative;
    box-sizing: border-box;
    clear: both;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    li {
        flex: 1 1 auto;
        margin-right: 5px;

        @media ( min-width: $portrait ) {
            margin-right: 20px;
        }

        &:last-of-type { margin-right: 0px; }
    }
}

.session-tab {
    padding: 15px 0px 5px 0px;
    position: relative;
    display: block;
    border: 1px solid $border;
    border-top-width: 3px;
    color: inherit;
    background-color: $white;
    text-decoration: none;
    transition: all 0.15s linear;
    height: 100%;

    * { pointer-events: none; }
    &:focus { outline: 0px; }

    .arrow {
        display: block;
        text-align: center;
    }

    .prefix {
        font-size: 24px;
        font-weight: bold;
        display: block;
        text-align: center;
        min-width: 50px;
    }

    .session-info {
        display: none;

        & > span {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 160px;
        }

        .icn { color: $lightGrey; }
        .lbl { font-size: var(--font-size-smaller); }
        .title { font-weight: bold; }

        .location, .amount {
            font-size: var(--font-size-small);
            display: block;
            white-space: nowrap;
        }
    }

    &:after {
        content: " ";
        display: block;
        position: absolute;
        bottom: -7px;
        height: 7px;
        left: -1px;
        right: -1px;
        background-color: inherit;
        border-left: 1px solid $border;
        border-right: 1px solid $border;
        opacity: 0;

        @media ( min-width: $portrait ) {
            bottom: -22px;
            height: 22px;
        }
    }

    // Hover style
    &:hover {
        border-top-color: $hover-blue;
        box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.15 );
    }

    // Active style
    &.active {
        border-top-color: getVar(--primary, $primary);
        padding-left: 50px;

        .prefix {
            position: absolute;
            left: 0px;
            top: 8px;
        }

        .arrow { opacity: 0; }
        .session-info { display: block; }

        &:after { opacity: 1; }
        &:hover { box-shadow: none; }
    }
}

// Earlier break for double-sessions
.session-bar {
    .block-header {
        margin: 0px;
        display: none;
    }

    @media (min-width: $mobileLandscape) {
        &.double-sessions,
        &.triple-sessions, {
            @include showPortrait();
        }
    }

    // Different layout on portrait
    @media (min-width: $portrait) {
        @include showPortrait();
    }
}


