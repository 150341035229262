//
//  Activity in timeline
//
.timeline-act {
    .act {
        border-radius: 5px;
        background-color: var(--tl-act-bg);
        padding: 15px;
        position: relative;
        filter: var(--tl-act-shadow);
        font-size: var(--font-size-small);

        &:before {
            width: 16px;
            height: 16px;
            content: " ";
            position: absolute;
            background-color: inherit;
            top: 12px;
            left: 0px;
            transform-origin: center;
            transform: translateX( var(--arrow-x) ) rotate(-45deg);
        }

        // Switch position of activity-element and pointer
        @container timeline ( min-width: #{ $breakpoint } ) {
            margin: var(--act-margin);
            max-width: var(--tl-act-max);

            &:before {
                right: 0px;
                left: auto;
                --arrow-x: 50%;
            }
        }
    }

    h5, h6 {
        font-size: var(--font-size);
        margin: 0px;
        line-height: 1.2;

        .type {
            display: block;
            font-size: var(--font-size-small);
            color: var(--alt-grey);
        }
    }

    h6 {
        font-size: var(--font-size-small);
        margin-top: 4px;
    }

    .timeline-act-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        & > div { flex: 1 1 auto; }
    }

    .subtitle {
        color: var(--alt-grey);
        font-size: var(--font-size-small);
        margin-bottom: 10px;

        .loc {
            display: inline-block;
            margin-left: 40px;
        }

        .status {
            display: inline-block;
            margin-right: 40px;
        }
    }

    .view {
        display: block;
        text-align: right;
        margin-left: auto;
    }

    .meeting-small {
        padding: 5px 5px 5px 10px;
        margin: 10px 0px;
        border-radius: 3px;
        background-color: var(--e4);
    }

    .timeline-relations {
        margin: 10px 0px 20px 0px;
    }
}

// Change max-width of meeting elements
.timeline-row.meeting,
.timeline-row.event,
.timeline-row.instrument {
    --tl-act-max: var(--tl-meeting-max);
}

// Change max-width of milestones and apply them always
.timeline-row.milestone {
    --tl-act-max: var(--tl-milestone-max);

    .act { max-width: var(--tl-act-max); }
}

// Slightly larger width for status update elements
.timeline-row.status-update { --tl-act-max: var(--tl-status-max); }

// Reverse align documents box and pointer
.timeline-row.documents,
.timeline-row.status-update {
    --act-margin: 0px auto 0px 0px;

    .timeline-act {
        .act:before {
            left: 0px;
            right: auto;
            --arrow-x: -50%;
        }
    }
}

// Extra styling for the documents list
.timeline-row.documents {
    ul {
        border-top: 1px solid var(--e4);
        margin-top: 10px;
    }

    .document-link {
        &:hover, &:focus-visible {
            background-color: var(--e4);
        }
    }
}

// Voting graphs in timeline-row
.timeline-row .small-voting-graph {
    --_graph-inner-bg: var(--tl-act-bg);
}
