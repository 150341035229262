//
//  Accessibility themes
//  - color schemes for accessibility
//

// Larger font
.larger-font {
    --font-size: 1.25rem;
}

// For both high-contrast and colored-contrast
.high-contrast, .colored-contrast {
    // Link link
    --link-color: var(--focus-color);

    // Light grey color for date elements
    --date-color: var(--dark-grey);
}

// High contrast ( black-white)
.high-contrast {

    // Reset primary and secondary
    --primary: var(--black);
    --secondary: var(--black);

    // Button colors
    --btn-primary: var(--focus-color);
    --btn-secondary: var(--focus-color);
    --btn-border: var(--black);

    // Main header specific
    --header-logo-bg: var(--primary);
    --logo-bg: var(--primary);

    // Color of title in content-header element
    --content-header-color: var(--primary);

    // Default header variables
    --header-active: var(--focus-color);

    // Active tab
    --tab-active-txt: var(--primary);

    // Secondary bar above the 'last meetings' block
    --bar-heading: var(--black);

    // Alt grey tekst
    --alt-grey: var(--black);

    --f5: var(--white);
    --e4: var(--white);
    --ee: var(--white);
    --ec: var(--white);

    --soft-grey: var(--black);
    --light-grey: var(--black);
    --dark-grey: var(--black);
    --pdf-red: var(--black);

    --blue: var(--focus-color);
    --orange: var(--black);
    --red: var(--black);
    --green: var(--black);

    // For tags
    .tag {
        --tag-bg: var(--black);
        --tag-color: var(--white);
    }

    #wrapper .button:not(.full-button, :hover, :focus), .menu-item {
        .icn {
            color: var(--focus-color);
        }
    }

    .tab-fragments .employee-fragments-list .toggle {
        background-color: var(--focus-color);
    }

    .clean-button:hover, .clean-button:focus {
        background-color: var(--focus-color);
        color: var(--white);

        .icn { color: inherit; }
    }

    // Change color of icons in header
    .main-header {
        --header-active: var(--focus-color);

        .icn {
            color: var(--white);
        }
    }
}

// Color contrast (darker client colors)
.colored-contrast {

    // Reset primary and secondary
    --primary: color-mix(
        in srgb,
        hsl(
            var(--hsl-primary-color),
            var(--hsl-primary-lum)
        ) var(--colored-contrast-mix),
        black );

    --secondary: color-mix(
        in srgb,
        hsl(
            var(--hsl-secondary-color),
            var(--hsl-secondary-lum)
        ) var(--colored-contrast-mix),
        black );

    // Button colors
    --btn-primary: var(--primary);
    --btn-secondary: var(--secondary);

    // Link link
    --link-color: var(--focus-color);

    // Main header specific
    --header-logo-bg: var(--primary);
    --logo-bg: var(--primary);

    // Light grey color for date elements
    --date-color: var(--dark-grey);

    // Color of title in content-header element
    --content-header-color: var(--primary);

    // Default header variables
    --header-active: var(--primary);

    // Active tab
    --tab-active-txt: var(--primary);

    // Secondary bar above the 'last meetings' block
    --bar-heading: var(--secondary);

    .tag.orange {
        --tag-bg: var(--dark-grey);
    }

}

.dark-mode {
    --text-color: var(--white);
    --page-bg: #111;
    --link-color: lightskyblue;
    --basic-back-bg: var(--page-bg);
}
