//
//  Activities
//
.tab-activities, .tab-votings {

    // Title bar
    .tab-titlebar {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding-bottom: 10px;

        h2 {
            flex: 1 1 auto;

            .main-title span {
                font-size: 80%;
                color: var(--alt-grey);
            }
        }

        .field-group {
            flex: 0 0 150px;

            label {
                flex: 0 0 60px;
                font-weight: bold;

                @media (max-width: ( $mobileLandscape - 1px ) ) {
                    display: none;
                }
            }

            .select {
                border-color: $f5;
                background-color: $f5;

                &:focus-within { border-color: $focus-outline-color; }
                .icn { color: inherit }

                select {
                    margin: 0px;
                    line-height: 16px;
                }
            }
        }
    }

    // Record list and more button
    .more-button {
        margin-top: 20px;

        .less-txt { display: none; }
    }
}
