//
//  Period agenda pop-up
//  - for details or the filter menu
//
.term-agenda {
    --pa-bg: var(--white);
    --pa-color: var(--dark-grey);
    --pa-width: 260px;
    --pa-left: var(--lta-left-col);
    --pa-right: var(--lta-left-col);
    --pa-top: var(--lta-act-offset);
    --pa-menu-hover: var(--f5);

    .overlays {
        pointer-events: none;
        position: absolute;
        inset: 0px;
        z-index: 3;
    }

    .pa-pop {
        position: absolute;
        left: var(--pa-left);
        top: var(--pa-top);
        z-index: 2;
        padding: 20px;
        background-color: var(--pa-bg);
        filter: drop-shadow(0 0px 5px rgba(0,0,0,.15));
        border-radius: 5px;
        color: var(--pa-color);
        width: var(--pa-width);
        pointer-events: visible;
        visibility: hidden;
        transform: scale( 0.8 );
        transition: transform 0.15s linear;
        min-height: var(--pa-width);
        display: flex;
        flex-flow: column nowrap;

        &.active {
            visibility: visible;
            transform: scale( 1 );
        }

        &.dark {
            --pa-bg: var(--dark-grey);
            --pa-color: var(--white);
            --focus-color: var(--white);
            --pa-menu-hover: var(--medium-grey);
        }

        &.align-bottom {
            bottom: var(--pa-top);
            top: auto;
        }

        h4, h5 { margin-top: 20px; }

        header {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            margin-bottom: 5px;
            overflow: hidden;

            .title {
                flex: 1 1 auto;
                font-weight: bold;
                font-size: var(--lta-font-larger);
                line-height: 1.2;
            }

            .tag {
                margin-bottom: 3px;
                transition: none;
            }

            .meeting-label {
                margin: 5px 0px;
            }

            button {
                flex: 0 0 auto;
                width: 24px;
                line-height: 24px;
                text-align: center;
                background-color: transparent;
                border: 0px;
                cursor: pointer;
                transition: opacity 0.15s linear;

                &:hover { opacity: 0.7; }
                &, .icn { color: inherit; }
                .icn { pointer-events: none; }
            }
        }

        .pa-content { flex: 1 1 auto; }
        .date, .loc { display: block; }

        .description {
            margin-top: 5px;
            font-size: var(--lta-font-tiny);
            line-height: 1.6;

            p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .button {
            display: block;
            transition:
                background-color 0.15s linear,
                border-color 0.15s linear;
            padding-left: 0px;
            padding-right: 0px;

            &:hover{
                background-color: var(--secondary);
                border-color: var(--secondary);
            }
        }

        .progress {
            margin: 0px 0px 20px 10px;
            position: relative;

            --orb-border: var(--soft-grey);
            --orb-bg: var(--white);

            &:before {
                content: " ";
                position: absolute;
                inset: 5px auto 10px 4px;
                width: 2px;
                background-color: var(--orb-border);
            }

            li { margin-bottom: 5px; }

            .orb {
                display: inline-block;
                margin-right: 10px;
                border-radius: 10px;
                width: 10px;
                height: 10px;
                border: 1px solid var(--orb-border);
                background-color: var(--orb-bg);
                position: relative;
                z-index: 1;
            }

            .done {
                --orb-border: var(--green);
                --orb-bg: var(--green);
            }
        }

        .menu-item {
            border: 0px;
            background-color: transparent;
            padding: 7px 15px;
            font: inherit;
            color: inherit;
            display: block;
            border-radius: 3px;
            width: 100%;
            text-align: left;
            cursor: pointer;
            margin: 4px 0px;

            &:hover { background-color: var(--pa-menu-hover); }
            .icn { color: var(--blue); pointer-events: none; }
        }

        // Details modal
        &.details {
            margin-left: var(--lta-month-size);

            &.align-right {
                margin-left: calc( (var(--pa-width) + var(--lta-act-offset)) * -1);
            }
        }

        // Show back-button
        .back-button {
            color: var(--orange);
            transition: all 0.15s linear;
            margin-left: -24px;
        }

        &.show-back {
            .back-button {
                margin-left: 0px;
            }
        }

        // Voting graphs in popup
        .small-voting-graph {
            --_graph-inner-bg: var(--pa-bg);
        }

        // Reset margin of simple lists
        .simple-item-list {
            &, .description { margin-top: 0px; }
        }
    }
}
