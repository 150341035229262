//
//  Document row
//  - styling for document row in dossier attachments and timeline
//
.dossier-documents li,
.timeline-row.documents li {
    padding: 5px 0px;

    &:not(:last-of-type) { border-bottom: 1px solid var(--e4); }

    &, & > a {
        display: flex;
        flex-flow: row nowrap;
    }

    a {
        flex: 1 1 auto;
        color: inherit;
        text-decoration: none;
        line-height: 1.4;
        border-radius: 3px;
        padding: 5px 10px;
        gap: 10px;

        &:hover, &:focus-visible {
            background-color: var(--ee);
        }
    }

    button { align-self: center; }
    .doc-info { flex: 1 1 auto; }
    .publication-date { flex: 0 0 85px; text-align: right; }
    .name { word-break: break-word; }

    .type {
        color: var(--alt-grey);
        display: block;
        font-size: var(--font-size-small);
    }

    .modified {
        font-weight: bold;
        flex: 0 0 100px;
        font-size: var(--font-size-small);
        text-align: right;
        color: var(--alt-grey);
        align-self: center;
    }

    .icn-holder {
        font-size: 1.15rem;
        color: var(--pdf-red);
        margin-top: 2px;
        width: 24px;
        text-align: center;
    }

    .icn-stack {
        color: var(--light-grey);

        .fa-lock { color: var(--orange); }
    }

    .options, .icn-holder {
        flex: 0 0 auto;
    }

    &.filtered { display: none; }

    // Hide modified date on small screens
    @media (max-width: ($mobileLandscape - 1px )) {
        .modified { display: none; }
    }
}
