//
//  Meta lint
//  - generic component showing metadata on a single row (lint)
//
$block-breakpoint: $desktop - 1px;

.meta-lint {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    clear: both;
    position: relative;
    margin: 5px 0px;
    font-size: var(--font-size-small);
    
    @media ( min-width: $landscape ) {
        font-size: var(--font-size);
        display: inline-block;
    }
    
    * {
        display: inline-block;
        box-sizing: border-box;
    }
    
    .meta-item {
        padding-right: 15px;
        @media ( min-width: $mobileLandscape ) {
            padding-right: 25px;
        }
    }
    
    .label {
        font-weight: bold;
    
        @media ( width > $mobileLandscape ) {
            display: inline-block;
        }

        @media ( width < $mobileLandscape ) {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 0px;
            height: 0px;
            overflow: hidden;
            opacity: 0;
        }
    }
    
    .icn {
        color: var(--alt-grey);
        margin-right: 5px;
    }

    &.grey {
        background-color: var(--f5);
        padding: 0px 5px;
    }
}

// Show mobile 50/50 blocks, hide labels. Shows everything in a lint on desktop
.block-to-lint {
    @media ( max-width: $block-breakpoint ) {
        flex-wrap: wrap;
        
        .meta-item { width: 50%; }
    }
    
    @media ( max-width: ( $mobileSmall - 1px ) ) {
        &, .meta-item { display: block; }
    }
}
