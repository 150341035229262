//
//  (Dossier) progress element
//
.dossier-progress-holder {
    --prog-col: #006daa;
    --prog-col-end: #1472e6;
    --prog-step-size: 18px;
    --prog-bg: var(--ee);
    --prog-icn: var(--alt-grey);
    margin-bottom: 60px;

    h2 {
        font-size: var(--font-size-small);
        line-height: 20px;
        margin: 0px;
    }
}

.dossier-progress {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 20px 0px;
    position: relative;

    .step {
        --bg: var(--white);
        width: var(--prog-step-size);
        height: var(--prog-step-size);
        line-height: var(--prog-step-size);
        box-shadow: 0px 0px 6px rgba( 0, 0, 0, 0.15 );
        outline: 1px solid var(--white);
        border-radius: 100%;
        background-color: var(--bg);
        z-index: 2;
        position: absolute;
        left: var(--prog);
        transform: translate( -50%, -50% );

        .icn {
            position: absolute;
            inset: 0px;
            text-align: center;
            font-size: 0.75rem;
            color: var(--prog-icn);
            line-height: inherit;
        }

        &.current {
            --bg: var(--prog-col);
            --prog-icn: var(--white);
        }
    }

    .label {
        position: absolute;
        inset: 100% auto auto 0px;
        margin-top: 5px;
        white-space: nowrap;
        transform-origin: top left;
    }

    .step.last {
        left: 100%;
        .label {
            right: 0px;
            left: auto;
        }
    }

    // Labels on mobile
    @media( max-width: ($portrait - 1px )) {
        .label { font-size: var(--font-size-small); }
    }

    // Background and prog line
    &:after, .progress-line {
        position: absolute;
        inset: 50% 0px auto 0px;
        height: 10px;
        border-radius: 5px;
        transform: translateY(-50%);
        z-index: 0;
    }

    &:after {
        content: " ";
        background-color: var(--ee);
        z-index: 0;
    }

    .progress-line {
        background: linear-gradient( to right, var(--prog-col), var(--prog-col-end) );
        right: auto;
        width: var(--prog);
        z-index: 1;
    }
}
