//
//  Styling of parts in the general tab
//

.tab-general {

    // Grid layout
    @media (min-width: $landscape) {
        .tabcontent-holder {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }

    .main-col {
        flex: 1 1 auto;
        margin-bottom: 30px;
    }

    .widget {
        flex: 0 0 30%;
        margin-left: 30px;
        margin-bottom: 30px;
    }

    &.all-social .tabcontent-holder {
        flex-wrap: wrap;

        .main-col {
            flex-basis: 100%;
        }

        .widget {
            flex: 0 0 48%;
            margin-left: 0px;
        }
    }

    .role-history {
        h4 { margin-top: 20px; }
        ul { list-style: none; }

        li {
            border-top: 1px solid $e4;
            padding: 5px 10px;

            .role { display: block; font-weight: bold; }

            @media ( min-width: $mobileLandscape ) {
                display: flex;
                flex-flow: row nowrap;

                .date { flex: 0 0 180px; }
                .role { margin-left: 10px; }
            }
        }
    }
}
