//
//  Simple item list
//  - more basic version of the default-row
//
.simple-item-list {
    --_icn: 32px;
    --_grid: var(--_icn) 1fr auto 5px;
    --_row-bg: var(--f5);
    --_icn-color: var(--alt-grey);

    display: grid;
    grid-template-columns: var(--_grid);
    grid-gap: 10px;
    margin: 20px 0px;
    list-style: none;
    container: simple-item-list / inline-size;

    li {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1/-1;
        border-radius: 5px;
        background-color: var(--_row-bg);
        align-items: center;
        padding: 10px 0px;
    }

    .row-icn {
        color: var(--_icn-color);
        font-size: calcEmFontSize(24px);
        text-align: center;

        .icn {
            margin-left: 15px;
        }
    }

    .description { line-height: 1.4; }
    .row-title { font-weight: bold; }

    .meta {
        display: block;
        font-size: var(--font-size-small);
        color: var(--_icn-color);

        & > * {
            display: inline-block;

            & + * { margin-left: 20px; }
        }
    }

    // Color schemes and other options
    &.darker { --_row-bg: var(--e4); }
    &.medium-grey { --_row-bg: var(--medium-grey); }
    &.dark-grey { --_row-bg: var(--dark-grey) }

    &.medium-grey, &.dark-grey {
        color: var(--white);
        --_icn-color: inherit;
    }

    @container simple-item-list (max-width: 360px) {
        .label { display: none; }

    }
}
