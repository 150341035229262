//
//  Select period
//  - extra styling for the 'select period' modal
//
.select-period {
    .fa-check { color: var(--f5); }
    .selected .fa-check { color: var(--green); }

    .date-wrap {
        display: flex;
        flex-flow: row nowrap;

        label { flex: 0 0 40px; }

        input {
            width: auto;
            flex: 0 0 130px;
        }
    }

    .data-format {
        margin: 0px;
        font-size: var(--font-size-small);
    }

    .select-date-wrapper { margin: 10px 0px; }

    .select-date-toolbar {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .presets {
        button {
            display: block;
            width: 100%;
            text-align: left;
        }
    }

    @media ( min-width: $mobileLandscape ) {
        .period-holder {
            display: flex;
            flex-flow: row nowrap;
            padding-bottom: 30px;
            padding-top: 5px;
        }

        fieldset { flex: 1 1 auto; }

        .presets {
            flex: 0 0 auto;
            border-right: 1px solid var(--e4);
            padding-right: 10px;
            margin-right: 20px;

            button {
                display: flex;
                flex-flow: row nowrap;

                span { flex: 1 1 auto; }
                .icn { flex: 0 0 auto; }
            }
        }
    }

    @media ( max-width: ( $mobileLandscape - 1px ) ) {
        .presets {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--e4);
        }
    }
}
