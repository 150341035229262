//
// Voting overview
//

// Voting head/row
.voting-overview-head, .voting-row {
    display: flex;
    flex-flow: row nowrap;
    border-bottom: 1px solid $e4;
    padding: 5px;
}

.voting-overview-head { font-weight: bold; }

.voting-row {
    color: inherit;

    &:hover { background-color: $f5; }
}

.voting-description { flex: 1 1 auto; }
.voting-date { flex: 0 0 140px; }

.voting-vote, .voting-result {
    flex: 0 0 120px;
    font-size: var(--font-size-small);
}

// Different layout on mobile
@media ( max-width: ( $desktop - 1px ) ) {
    .voting-vote, .voting-result {
        text-align: center;
        .label { display: none }
    }
}

// Layout changes for group-overview
&.voting-of-group {
    .voting-vote { flex-basis: 120px; }

    .voting-votes {
        display: block;

        .label { font-size: var(--font-size-smaller); }
    }
}
