.mt-document {
    a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border-top: 1px solid $e4;
        border-bottom: 1px solid $e4;
        padding: 10px 5px 10px 0px;
        color: inherit;
        text-decoration: none;
        transition: all 0.15s linear;
        line-height: 1.4;

        &:hover { background-color: $backBlue; }
    }

    .mt-document-info { flex: 1 1 auto; }

    .file-icn {
        flex: 0 0 48px;
        text-align: center;
        font-size: 24px;
        color: var(--pdf-red);
    }

    .file-info, .document-type {
        display: inline-block;
        font-size: var(--font-size-smaller);
        color: var(--alt-grey);
    }

    .mod {
        flex: 0 0 auto;
        font-size: var(--font-size);
        font-weight: bold;
        color: var(--alt-grey);
        margin-right: 15px;
    }

    & + .mt-document a { border-top: 0px; }

}

.mt-content ~ .mt-document { margin-top: 20px; }
