//
//  High contrast agenda row
//
.high-contrast {

    .agenda-row-content {
        border-top: 1px solid var(--black);
    }

    .agenda-row-head .nr:before {
        background-color: var(--black);
    }
}
