//
//  Annotations
//  - styling for annotations tab content in dashboard
//
$row-border: var(--e4);

.tab-annotations {

    // Clear table style
    table, tr {
        background-color: var(--white);
        margin: 0px;
    }

    table { width: 100%; }
    td, th { border: 0px; }

    // Set new table style
    td {
        border-bottom: 1px solid $row-border;
        padding: 7px 5px;
    }

    // Show / hide table rows when filtering
    .search-hidden {
        display: none;
        opacity: 0.3;
        pointer-events: none;
    }

    // Table columns
    .status { width: 26px; }
    .description { padding: 7px 5px; }
    .annotations {
        width: 150px;

        @media ( max-width: ( $portrait - 1px )) {
            width: 80px;
        }

        @media ( max-width: ( $mobileLandscape - 1px )) {
            display: none;
        }
    }

    .modified {
        width: 50px;
        text-align: right;
        padding-left: 0px;
    }

    // Other
    .unread {
        .title, .date { font-weight: bold; }
    }

    .meta, .date, .annotations { font-size: var(--font-size-small); }
    .date { font-style: normal; }

    // Show/hide extra info
    &:not(.show-extra) .meta { display: none; }
}

.annotations{
    &-group {
        margin: 50px 0px;

        h3 {
            font-size: var(--font-size);
            margin: 0px;
            border-bottom: 1px solid $row-border;
            line-height: 30px;

            a {
                color: inherit;
                text-decoration: none;
                display: inline-block;
                transition: all 0.15s linear;

                * { pointer-events: none; }

                &:hover { background-color: var(--f5); }
            }

            .amount {
                display: inline-block;
                font-weight: normal;
                margin: 0px 2px 0px 5px;
            }
        }

        // Icn colors
        .icn { color: var(--light-grey); }
        .fa-share-alt, .fa-info-circle { color: var(--blue); }
        .fa-users, .fa-lock { color: var(--orange); }

        .annotations-holder,
        &.show-no-content .no-content { display: none; }

        &.active {
            .pointer .icn { transform: rotate(90deg); }
            .annotations-holder { display: table; }
            &.show-no-content .no-content { display: block; }
        }
    }
}

.annotated-document {

    // Hide status icns
    .status .icn, .fa-lock {
        display: none;
    }

    &.shared {
        .status .fa-share-alt { display: inline-block; }
    }

    &.secured {
        .fa-lock { display: inline-block; }
    }

    .title-holder {
        display: block;
        position: relative;
        cursor: pointer;

        .icn {
            flex: 0 0 24px;
            margin-left: 5px;
        }

        .title { pointer-events: none; }
    }

    .meta {
        @media ( min-width: $portrait ) {
            display: flex;
            flex-flow: row wrap;
        }

        a {
            color: inherit;
            display: block;
        }

        .meta-item { flex: 0 0 50%; }
        .icn { color: var(--light-grey); }
        .shared-with { flex: 0 0 100%; }
    }
}

.annotations-shares {
    transition: all 0.15s linear;
    border-radius: 3px;
    position: relative;
    display: block;
    padding: 0px 3px;
    cursor: default;

    .fold-out {
        position: absolute;
        padding: 15px;
        background-color: var(--white);
        box-shadow: 0px 0px 3px rgba( 0, 0, 0, 0.08 );
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        transition: all 0.15s linear;
        width: 200px;
        box-sizing: border-box;
        left: 50%;
        margin-left: -100px;
        border-radius: 5px;

        &:before {
            content: " ";
            display: block;
            width: 16px;
            height: 16px;
            transform-origin: center center;
            transform: rotate(-45deg) translateX(-50%);
            top: -11px;
            left: 50%;
            position: absolute;
            background-color: var(--white);
        }

        ul {
            list-style: none;
            padding: 0px;
            margin: 10px 0px 10px 5px;
        }

        li {
            display: flex;
            flex-flow: row nowrap;
        }

        .name { flex: 1 1 auto; }
        .amount {
            flex: 0 0 auto;
            font-weight: bold;
        }
    }

    &:hover {
        background-color: var(--f5);

        .fold-out { opacity: 1; }
    }


    // On mobile, hide labels
    @media ( max-width: ( $portrait - 1px )) {
        .icn { display: none; }

        .own, .other {
            span { display: none; }

            &:before {
                font-weight: bold;
                font-family: $font-awesome;
                color: var(--soft-grey);
                margin-right: 5px;
                display: inline-block;
            }

        }

        .own:after { content: " | "; color: var(--soft-grey); }
        .own:before { content: "\f075"; }
        .other:before { content: "\f086"; }

    }
}

// Fix pointer-events bug in IE11
.is-ie .annotated-document .title-holder .title {
    display: inline-block;
}
