//
//  Agenda filters
//  - show/hide as modal on mobile
//  - always show as sidebar on desktop
//
$filterOffset: 25px;

.tab-agenda {

    // Mobile button/toolbar for opening / closing filters
    .mobile-filter-toolbar {
        text-align: right;
        margin-bottom: 20px;

        @media ( max-width: ( $mobileLandscape - 1px ) ) {
            .button {
                display: block;
            }
        }

        @media ( min-width: $landscape ) {
            display: none;
        }
    }

    // Filters
    .filters {
        legend {
            font-size: var(--font-size);
            font-weight: bold;
        }

        fieldset { margin-bottom: 20px; }
        .checkbox { padding-left: 3px; }

        :not(.active) .overflow-holder :nth-child(n+6) {
            display: none;
        }

        .show-more-button {
            border-top: 1px solid var(--e4);
            text-align: center;
            display: block;
            margin-top: 20px;
            clear: both;

            .icn { transition: all 0.3s linear; }
            .less-label { display: none; }
        }

        .active .show-more-button {
            .more-label { display: none; }
            .less-label { display: inline-block; }
            .icn { transform: rotate(180deg); }
        }

        // Mobile modal / pop-up like window
        @media ( max-width: ( $mobileLandscape - 1px )) {

            // Hide
            opacity: 0;
            pointer-events: none;

            // Style as modal
            background-color: var(--white);
            border: 1px solid var(--e4);
            padding: 20px 30px;
            position: fixed;
            top: -10px;
            margin-top: 20px;
            left: 20px;
            right: 20px;
            transform: translateY(-50%);
            transition: all 0.15s linear;
            overflow: hidden;
            overflow-y: auto;
            box-shadow: 0px 0px 25px rgba( 0, 0, 0, 0.7 );
            max-height: 75vh;

            .toolbar {
                margin: 40px 0px;

                .button { display: block; }
            }
        }

        // Desktop als static sidebar
        @media ( min-width: $landscape ){
            padding-left: $filterOffset;
            margin-left: $filterOffset;
            border-left: 1px solid var(--e4);

            .toolbar { display: none; }
        }
    }

    // Mobile show filters
    &.show-filters .filters {
        opacity: 1;
        pointer-events: visible;
        margin-top: 0px;
    }
}
