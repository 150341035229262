//
//  Message
//  - styling for the error message and technical error
//
.error-page-message {
    text-align: center;
    margin: 30px 0px;
    animation: errorLetterIn var(--error-letter-timing) ease-out 0s forwards;
    animation-delay: calc(var(--error-letter-delay) * 6);
    transform: translateY(50%);
    opacity: 0;

    p {
        font-size: var(--font-size-larger);
        margin: 0px;
    }

    .error-page-toolbar { margin-top: 30px; }
}

.error-page-debug {
    background-color: $f5;
    max-width: 500px;
    margin: 50px auto auto auto;
    padding: 10px 10px 10px 25px;

    .error-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;

        h3 { flex: 1 1 auto; }
        h3, .button { margin: 0px; }
    }

    .fa-minus { display: none; }

    &.active {
        .fa-minus { display: inline; }
        .fa-plus { display: none; }
    }

    .meta-block {
        font-size: var(--font-size-small);

        dt { flex-basis: 70px; }
    }

    &:not(.active) .meta-block { display: none; }
}
