//
//  Document legend
//
.document-legend {
    position: relative;

    .fold-out {
        width: 800px;
        max-height: 1000px;
    }

    &:hover, &:active, &.active {
        .hover-trigger { background-color: $white; }
        .fold-out { max-height: 1000px; }
    }

    ul {
        list-style: none;
        column-count: 3;
    }

    li {
        display: block;
        line-height: 20px;
        padding: 2px 2px 2px 30px;
        margin: 2px 0px;
        position: relative;

        &:hover { background-color: $f5; }
    }
}
