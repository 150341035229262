//
//  Decisions
//  - extends default row
//
.decision-list {
    --item-row-bg: var(--f5);

    .description {
        line-height: var(--line-height);

        &:first-child {
            padding-left: 0px;
        }

        ul {
            list-style: initial;
            padding-left: 20px;
        }
    }
}

.decision-row {
    padding: 10px 15px;
}
