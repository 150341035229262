//
//  Overview and filters
//  - Predefined content layout containing a larger left column for an overview and a right-column for filters
//

// Settings
:root {
    --of-filter-width: 270px;
    --of-group-bg: var(--f5);
    --of-gap: 100px;
    --of-header-area: header;
    --of-filter-offset: -40px;
}

// Default breakpoint for when the overview switches between a visible kolom and sidebar
$breakpoint: $landscape;

.overview-and-filters {

    // Layout on larger devices
    display: grid;
    grid-template-columns: auto var(--of-filter-width);
    grid-gap: 0px var(--of-gap);
    grid-template-areas:
        "header ."
        "main filters";

    .content-header { grid-area: var(--of-header-area); }
    .filters { grid-area: filters; }

    // Fix alignment of buttons and quicksearch in the content-header when they are next to eachother
    .content-header .button.smaller {
        line-height: 17px;
        margin: 0 1px;
    }

    // Hide filtered row items
    .overview-content {
        grid-area: main;
        // Clear top/bottom margin of buttons
        button { margin: 0px 5px; }
        .filtered { display: none; }
    }

    // Align title-holder
    .title-holder {
        align-self: center;
        padding-top: 0px;
    }

    // Default styling of input / select in the header (for filters or quicksearch)
    .select, .input input {
        border-color: var(--ee);
        background-color: var(--ee);
        border-radius: 5px;
        margin: 0px 5px 0px 0px;
    }

    // Default styling of blocks
    .filter-group {
        border-radius: 5px;
        background-color: var(--of-group-bg);
        padding: 15px 20px 20px 20px;
        margin-bottom: 40px;

        h4 { margin-bottom: 10px; }
    }

    // Styling for period filter in filters
    .filter-period {
        label { flex-basis: 70px; }
        input, .field-group .icn { background-color: var(--white); }

        input {
            padding-right: 5px;
            border-color: var(--soft-grey);
        }

        .filter-presets {
            margin-top: 20px;

            div {
                column-count: 2;
                column-gap: 0px;
            }

            button {
                text-align: left;
                padding: 5px;
                display: block;
                margin: 0px;
                width: 100%;
            }
        }
    }

    // Styling of filter-header, above the filter column
    .filter-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 20px;

        @media ( min-width: $breakpoint ) {
            min-height: 40px;
            margin-top: var(--of-filter-offset);
            margin-bottom: 0px;
        }

        h3 {
            flex: 1 1 auto;
            font-size: 1.2rem;
            margin: 0px;
        }
    }

    // Calendar widget in filter bar
    .calendar-widget { height: auto; }

    // Hide mobile elements on larger screens
    @media ( min-width: $breakpoint ) {
        .toolbar, .content-header, .filters {
            [data-open-modal], [data-close-modal], [data-apply-modal] { display: none; }
        }
    }

    // Layout on smaller devices
    @media ( max-width: ($breakpoint  - 1px) ) {

        // Undo grid
        display: block;

        // Position filters as sidebar
        .filters {
            position: fixed;
            inset: 0px 0px 0px auto;
            background-color: var(--white);
            padding: 20px 20px 0px 20px;
            box-shadow: 0px 0px 15px #00000019;
            z-index: 11;
            overflow: hidden auto;
            transition: transform 0.3s linear;

            &:not(.active) { transform: translateX(100%); }

            .toolbar {
                position: sticky;
                bottom: 0px;
                background-color: var(--white);
                padding: 10px 0px 20px 0px;
            }
        }

        // Semi-transparent background used to hide the content, also used for closing the sidebar
        .close-modal {
            position: fixed;
            inset: 0px;
            background-color: #fffc;
            z-index: 10;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.3s linear;
        }

        .filters.active + .close-modal {
            pointer-events: visible;
            opacity: 1;
        }

        // Place title and tools on two rows on small screens
        .content-header {
            flex-wrap: wrap;

            .title-holder {
                flex: 1 1 100%;
            }

            .options, .search  { flex: 1 1 auto; }
        }
    }
}
