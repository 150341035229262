//
//  Profile card
//  - shows a thumb, name, function, social media an group info
//
.profile-card {
    display: flex;
    flex-flow: column nowrap;

    // Basic layout of elements
    @media ( min-width: $landscape ) {
        flex-flow: row nowrap;
        align-items: flex-start;
        margin-bottom: 80px;
    }

    // On mobile, everything below each other
    .profile-info {
        margin-bottom: 30px;
        flex: 1 1 auto;

        @media ( min-width: $landscape ) {
            margin: 0px 30px;
        }
    }

    .thumb {
        max-width: 120px;
        margin: 0px auto 20px auto;
        display: block;
        order: -1;
    }

    // Styling of elements
    .group-info {
        flex: 0 0 220px;
        border-left: 1px solid $e4;
        padding-left: 20px;

        .logo {
            display: block;
            width: 100%;
            min-height: 100px;
            background-position: center left;
        }

        .group-logo { background: none; }

        .role, .start { display: block }

        @media (max-width: ($landscape - 1px)) {
            border: 0px;
            background-color: $f5;
            padding: 20px;
        }

        .button {
            margin-top: 20px;
            display: block;

            @media ( min-width: $landscape ) {
                float: right;
            }
        }
    }

    .profile-info {
        @media (max-width: ($mobileLandscape - 1px)) {
            text-align: center;
        }

        h1 { margin-bottom: 0px; }
        .info { margin-bottom: 20px; }
    }

    .party-description {
        font-size: var(--font-size-small);
        margin-top: 20px;
    }

    .party-contact {
        font-size: var(--font-size-small);
        background-color: $f5;
        margin: 20px 0px;
        padding: 15px;

        h3 { font-size: var(--font-size); }

        p:last-of-type { margin-bottom: 0px; }
    }

    // Alignment of edit-button
    .edit-profile { margin-top: 20px; }

    @media ( max-width: ( $landscape - 1px ) ){
        .edit-profile {
            display: block;
            width: 100%;
        }
    }

}

.party {
    .profile-card .thumb {
        max-width: 160px;
        flex-basis: 160px;
        background-color: transparent;

        img { object-fit: contain; }
    }
}
