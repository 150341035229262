//
//  Cookie ba
//  - styling of the cookie warning at the top of the page
//  - only visible when Google-analytics and cookiebar is active and when the message has nog been hidden by the user

#warningtop {
    background: $backYellow;
    color: $darkGrey;
    padding: 10px;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    border-bottom: 1px solid #ccc;
    z-index: 20;
    line-height: 200%;
    margin: 0px;
    text-align: center;

    &:after {
        content: " ";
        clear: both;
        display: block;
    }

    input {
        padding: 3px 5px;
        margin: 0;
        margin-left: 5px;
        text-align: center;
        float: none;
    }

    a {
        color: blue;
        text-decoration: underline;
    }
}

