//
//  Minimal footer
//
.minimal-footer {
    --_footer-dark: color-mix( in srgb, var(--footer-bg) 92%, black );
    position: relative;
    background-color: var(--footer-bg);
    color: var(--footer-color);
    border-bottom: 10px solid var(--_footer-dark);
    padding: 30px 0px 20px 0px;

    * { color: inherit; }

    a:not(.button) {
        text-decoration: none;
        color: inherit;

        &:hover, &:focus {
            text-decoration: underline;
            color: inherit;
        }
    }

    .container {

        @media ( max-width: ( $mobileLandscape - 1px )) {
            text-align: center;

            .social, .about { margin: 10px 0px 30px 0px; }
        }

        @media( min-width: $mobileLandscape ){
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;

            .logo, .social { flex: 0 0 50%; }
            .social { padding-left: 30px; }
            .about { flex: 0 0 100%; }
        }

        @media( min-width: $landscape ){
            flex-wrap: nowrap;
            align-items: flex-start;

            .logo, .social {
                flex: 0 0 auto;
                margin: 0px;
            }
            .about {
                flex: 1 1 auto;
                padding-left: 30px;
            }
            .logo {
                flex-basis: 300px;
                background-position: left top;
            }
        }
    }

    .logo {
        min-height: 80px;
        max-width: 70vw;
    }

    .social {
        .links a {
            display: inline-block;
            text-align: center;
            border-radius: 100%;
            font-size: var(--font-size-larger);
            background-color: var(--secondary);
            margin-right: 15px;
            transition: all 0.15s linear;
            color: var(--white);

            @include equalAll( 40px );

            &:hover, &:focus {
                background-color: var(--focus-color);

                @each $color in $socialColors {
                    $name: nth($color, 1 );
                    $value: nth($color, 2 );

                    &.#{$name} {
                        background-color: nth($value , 1);
                    }
                }
            }
        }
    }

    .about {
        li {
            display: inline-block;
            font-size: var(--font-size-small);

            @media ( min-width: $portrait ) {
                font-size: var(--font-size-large);
            }

            &:after {
                content: "|";
                display: inline-block;
                margin: 0px 10px;
                text-align: center;
            }

            &:last-of-type {
                &:after { display: none; }
            }
        }
    }
}
