//
//  Dossier tabs
//

.dossier-tabs {

    // Settings
    --tab-basis: auto;
    --tab-bg: var(--f5);
    --tab-bg-hover: var(--ee);
    --tab-bg-active: var(--white);
    --tab-color: var(--dark-grey);
    --tab-color-active: var(--secondary);

    // Style
    margin-top: 50px;
    display: flex;
    flex-flow: row nowrap;
    gap: 2px;
    justify-content: flex-start;

    // Tab styling
    .tab {
        position: relative;
        background-color: var(--tab-bg);
        color: var(--tab-color);
        font-weight: bold;
        padding: 15px 40px;
        line-height: 20px;
        border: 0px;
        font-size: var(--font-size-large);
        display: inline-block;
        cursor: pointer;
        transition: all 0.15s linear;
        flex: 0 0 var(--tab-basis);

        &:hover, &:focus-visible { background-color: var(--tab-bg-hover); }
        * { pointer-events: none; }

        &.active {
            background-color: var(--tab-bg-active);
            box-shadow: 0px -5px 5px rgba( 0, 0, 0, 0.08 );
            color: var(--tab-color-active);
        }
    }

    @media ( max-width: ($mobileLandscape - 1px) ) {
        --tab-basis: 50%;
        justify-content: space-evenly;
    }
}

.dossier-tab-content {
    padding: 50px 0px;
    min-height: 280px;
    position: relative;

    &:not(.active) { display: none; }

    .is-loading .loading-placeholder { display: block; }

    .loading-placeholder {
        position: absolute;
        inset: 0px;
        text-align: center;
        background-color: var(--white);
        display: none;

        .loading-txt {
            position: absolute;
            inset: 50% 0px auto 0px;
            transform: translateY(-50%);
        }

        .icn {
            color: var(--soft-grey);
            font-size: 24px;
        }
    }
}

// Set tabs side-by-side on larger devices

// Hide tab controls and show all tab content elements
@media ( min-width: $desktop ) {
    .involved-row {
        display: none;
    }

    .tab-column-container {
        border-top: 1px solid var(--e4);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--ih-padding);

        .tab-content {
            display: block;
        }
    }
}
