//
//  Default table styling
//

:root {
    --caption: #696E74;
}

// Default table
table {
    border-collapse: collapse;
    margin-bottom: 20px;
    min-width: 31.25%;
    max-width: 100%;

    .content & { width: 100%; }
}

caption {
    background: var(--caption-bg);
    color: $white;
    font-size: var(--font-size-h4);
    font-weight: bold;
    padding: 5px 10px;
}

tr {
    background-color: $white;

    &:nth-child(odd) { background: $f5; }
    &:hover { background-color: $e4; }
}

th, td { padding: 7px 12px; }

th {
    background: $darkGrey;
    color: $white;
    text-align: left;
    vertical-align: bottom;
}

td {
    vertical-align: top;
    border-bottom: 1px solid $e4;
}

@media print {
    caption, th, td, tr, tr:nth-child(odd) {
        border-bottom: 0.5mm solid #cccccc;
        background-color: transparent;
        color: #444444;
    }

    caption { border-bottom: 0px; }
}

.light-table {
    tr {
        background-color: $white;
    }

    td, th {
        color: var(--dark-grey);
    }

    th {
        background-color: transparent;
        border-bottom: 1px solid $e4;
    }
}
