//
//  Document type
//  - styling for document type overview (overview of documents with a certain type)
//  - can contain styling used in a document-subject
//
.subject-list, .document-list {
    list-style: none;
    padding-left: 0px;
}

.document-type,.library-page .content {
    section { margin: 30px 0px; }

    .no-content {
        text-align: center;
        padding: 50px 0px;
        border-top: 1px solid var(--e4);

        .icn {
            display: block;
            font-size: 1.6em;
            color: var(--soft-grey);
            margin-bottom: 10px;
        }
    }
}

.document-type-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 30px;
    
    h2 {
        color: var(--medium-grey);
        margin: 0px;
        flex: 1 1 auto;
    }
    
    .options {
        flex: 0 0 auto;
    }
}
