//
//  Twitter widget
//  - used in employee profile
//
$colorTwitter: #33cbfc;

.twitter-widget {
    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
    }

    .tweet-holder + .tweet-holder {
        border-top: 1px solid $e4;
        padding-top: 10px;
    }

    .fa-twitter { color: $colorTwitter; }
}

.tweet-holder {
    padding: 10px 0px;
    margin-bottom: 10px;

    .tweet-profile {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 10px;
    }

    .thumb {
        flex: 0 0 50px;
        border-radius: 100%;
    }

    .account-info {
        flex: 1 1 auto;
        margin: 0px 10px;
        line-height: 1.4;

        .name {
            font-weight: bold;
            display: block;
            font-size: var(--font-size-large);
        }
    }

    .twitter-icn {
        flex: 0 0 30px;
        color: $e4;
        text-align: center;
        font-size: var(--font-size-larger);
    }

    img {
        max-height: 150px;
        object-fit: contain;
        object-position: center center;
        width: 100%;
        background-color: $f5;
    }

    .twitter-icn:hover {
        color: $colorTwitter;
    }

    .tweet { display: block; }

    .date {
        font-weight: bold;
        display: block;
        text-align: right;
        color: $mediumGrey;
    }
}
