// Back button
.overlay {
    --bb-size: 24px;
    --bb-left: calc(var(--bb-size) * -1);
    --bb-pointer: none;

    .back-button {
        background-color: transparent;
        border: 0px;
        padding: 0px;
        width: var(--bb-size);
        line-height: var(--bb-size);
        position: relative;
        text-align: center;
        display: inline-block;
        color: var(--primary);
        border-radius: 3px;
        cursor: pointer;
        margin-top: 14px;
        margin-left: var(--bb-left);
        transition: all 0.15s linear;
        pointer-events: var(--bb-pointer);

        * { pointer-events: none }

        &:hover { background-color: var(--f5); }
    }

    &.show-back {
        --bb-left: 0px;
        --bb-pointer: visible;
    }
}
