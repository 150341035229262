//
//  Meeting base layout
//
& {
    clear: both;
    display: flex;
    flex-flow: row nowrap;

    .meeting-info { flex: 1 1 0px; }

    .meeting-actions {
        flex: 0 0 auto;

        @media ( min-width: $actions-breakpoint ) {
            border-left: 1px dotted $softGrey;
            margin-left: 30px;
            padding-left: 20px;
        }
    }
}
