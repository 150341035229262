//
//  Default colors
//

//
//   Base colors
//

// Status colors
$green			:	#60bc3d;
$red			:	#b70101;
$yellow			:	#ffc000;
$orange			:	#f46b10;
$blue			:	#37afe4;
$lightBlue		:	#6ec3e8;
$purple         :   #975cb7;

// Status backgrounds
$backGreen      :   #eaffe2;
$backRed        :   #ffe4e3;
$backYellow     :   #fff8e2;
$backBlue       :   #dbf4ff;

// Status text colors
$txtGreen         :    #435637;
$txtRed           :    #563232;
$txtYellow        :    #7f6a3b;
$txtBlue          :    #3c798e;


// Grey shades
$white  : #ffffff;
$black  : #222222;
$e4     : #e4e4e4;
$f5     : #f5f5f5;

$softGrey   : #cdd6de;
$lightGrey  : #a0afb0;
$mediumGrey : #525a65;
$altGrey    : #6f6f6f;
$darkGrey   : #303f4c;

$go: #f47721;

$primary: #f46b10;
$secondary: #006daa;


// PDF Red
$pdf-red:   #eb1c29;

//
// Color arrays
//

// Status colors extended with primary and secondary
$colors : (
    'orange'		( $orange ),
    'blue'			( $blue ),
    'green'			( $green ),
    'yellow'		( $yellow ),
    'red'			( $red ),
    'purple'		( $purple ),
    'grey'			( $altGrey ),
    'dark-grey'		( $darkGrey ),
    'primary'       ( $primary ),
    'secondary'     ( $secondary )
);

// Grey shades
$greyShades : (
    'white'         ( $white ),
    'black'         ( $black ),
    'soft-grey'     ( $softGrey ),
    'light-grey'    ( $lightGrey ),
    'medium-grey'   ( $mediumGrey ),
    'dark-grey'     ( $darkGrey ),
    'alt-grey'      ( $altGrey ),
);


// Social media
$socialColors : (
    'twitter'       ( #33cbfc ),
    'linkedin'      ( #0077b5 ),
    'facebook'      ( #3b5999 ),
    'youtube'       ( #d32421 ),
    'instagram'     ( #e4405f ),
    'flickr'        ( #ff707d ),
);
