:root {
    --btn-bg: var(--white);
    --btn-border: var(--soft-grey);
    --btn-txt: var(--medium-grey);
    --btn-padding: 10px 15px;
    --btn-icn: var(--light-grey);

    --btn-hover-bg: var(--btn-bg);
    --btn-hover-border: var(--light-grey);
    --btn-hover-txt: var(--dark-grey);
    --btn-hover-box: 0px 0px 5px rgba( 0, 0, 0, 0.15 );

    --btn-active-border: var(--blue);
    --btn-active-bg: var(--back-blue);
}

.button {
    --primary: var(--btn-primary);
    --secondary: var(--btn-secondary);
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    padding: var(--btn-padding);
    margin: 1px;
    border-radius: 3px;
    background-image: none;
    font-size: var(--font-size);
    font-family: inherit;
    font-weight: normal;
    line-height: 1.3;
    text-decoration: none;
    text-align: center;
    transition: all 0.2s ease-in;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    min-height: auto;
    min-width: 40px;
    -webkit-appearance: none;

    // Light-button style, default
    border: 1px solid var(--btn-border);
    background-color: var(--btn-bg);
    color: var(--btn-txt);

    *{ pointer-events: none; }

    &:hover, &:focus {
        --btn-bg: var(--btn-hover-bg);
        --btn-border: var(--btn-hover-border);
        --btn-txt: var(--btn-hover-txt);
        box-shadow: var(--btn-hover-box);
    }

    &:focus {
        z-index: 1;
        outline-color: transparent;
        outline-offset: 3px;
    }

    &:active {
        border-color: var(--btn-active-border);
        background-color: var(--btn-active-bg);
        box-shadow: none;
        outline: 0px;
    }

    &:active:focus { outline: 0px; }

    &:disabled, &.disabled {
        pointer-events: none;
        color: var(--light-grey);
        opacity: 0.5;
        cursor: not-allowed;

        .icn { color: var(--soft-grey); }
    }

    // Sizes
    &.smaller {
        --btn-padding: 6px 15px;
        font-size: var(--font-size-small);
        min-width: 32px;
    }

    &.larger {
        --btn-padding: 15px 24px;
        font-weight: bold;
        border-radius: 5px;
    }

    // Label in button
    .label, .icn {
        pointer-events: none;
        display: inline-block;
        float: none;
    }

    // Icons in button
    .icn {
        width: 16px;
        text-align: center;
        color: var(--btn-icn);
        transition: all 0.2s ease-in;
    }

    .icn-left { margin-left: -5px; }
    .icn-right { margin-right: -5px; }

    &.icn-only {
        --btn-icn: var(--alt-grey);
        width: 38px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;

        .icn, .icn-stack { margin: 0px; }
        .label {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px; width: 1px;
            margin: -1px; padding: 0; border: 0;
        }
    }

    &.smaller.icn-only {
        width: 32px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.full-button {
    --btn-bg: var(--btn-primary);
    --btn-txt: var(--white);
    --btn-icn: var(--btn-txt);
    --btn-border: var(--btn-bg);
    --btn-hover-bg: var(--focus-color);
    --btn-hover-txt: var(--white);
    --btn-hover-box: none;
    --btn-active-bg: var(--blue);
    --btn-active-border: var(--btn-active-bg);

    &:hover, &:focus, &:active {
        color: var(--btn-hover-txt);
        --btn-border: var(--btn-bg);
    }
}

.clean-button {
    --btn-bg: transparent;
    --btn-border: transparent;
    --btn-hover-box: none;
    --btn-hover-bg: var(--e4);
    --btn-hover-border: var(--btn-hover-bg);
    --btn-active-border: var(--btn-active-bg);

    // Alternate themes
    &.dark {
        --btn-txt: var(--white);
        --btn-icn: var(--btn-txt);

        &:focus {
            outline-color: var(--white);
            background-color: rgba( 255, 255, 255, 0.20 );
            border-color: rgba( 255, 255, 255, 0.20 );
        }

        &:hover, &:focus {
            --btn-txt: var(--soft-grey);
            background-color: rgba( 255, 255, 255, 0.20 );
            border-color: rgba( 255, 255, 255, 0.20 );
        }
    }
}

// Color schemes
@each $color in $colors {
    $name: nth($color, 1 );
    $value: nth($color, 2 );

    .button.#{""+$name} {
        --btn-icn: var(#{"--"+$name});
    }

    .full-button.#{""+$name} {
        --btn-bg: var(#{"--"+$name});
        --btn-icn: var(--btn-txt);
    }
}

// Final overwrite full-button status
.full-button {
    &:hover {
        --btn-txt: var(--white);
        --btn-hover-txt: var(--btn-txt);
        --btn-bg: var(--focus-color);
    }

    &:focus {
        --btn-txt: var(--white);
        --btn-bg: var(--focus-color);
    }
}

// Mobile button layout

@mixin mobileSwitch () {
    min-width: 38px;
    padding-left: 10px;
    padding-right: 10px;

    .icn, .icn-stack { margin: 0px; }
    .label {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }
}

.mobile-button {
    &:not(.switch-early){
        @media ( max-width: ( $landscape - 1px ) ) {
            @include mobileSwitch();
        }
    }

    &.switch-early {
        @media ( max-width: ( $portrait - 1px ) ) {
            @include mobileSwitch();
        }
    }
}
