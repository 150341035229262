//
//  Notifications header
//
.notifications {
    .noti-header {
        display: flex;
        flex-flow: row nowrap;
        line-height: 34px;
        align-items: center;
        padding: 5px;

        a { text-decoration: none; }

        .title {
            flex: 1 1 auto;
            font-weight: bold;
        }
    }

    .noti-icn {
        position: relative;
        flex: 0 0 auto;
        text-align: center;
        overflow: hidden;
        width: auto;
        padding-left: 5px;
        margin-right: 5px;

        .icn {
            pointer-events: none;
            line-height: inherit;
        }

        .fa-chevron-left {
            font-size: var(--font-size-larger);
            text-align: center;
            color: $lightGrey;
        }

        .fa-info-circle {
            font-size: 20px;
            color: $yellow;
            width: 34px;
        }

        &:after {
            content: attr(data-amount);
            position: absolute;
            right: 0px;
            top: -20px;
            font-size: 12px;
            color: $white;
            background-color: $amount-bg;
            border-radius: 3px;
            box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
            transition: all 0.1s linear;

            @include equalAll(16px);
        }
    }
}
