//
//  Widget generic styling
//  - basic generic styling of the widgets on the dashboard
//
.dashboard-widget {
    padding: 20px 20px 10px 20px;
    border: 1px solid $softGrey;
    box-shadow: 0px 0px 2px rgba( 0, 0, 0, 0.1 );
    background-color: $white;
    height: auto;
    min-height: 200px;
    min-width: 0;

    @media ( min-width: $portrait ) {
        padding: 20px 30px 10px 30px;
    }

    .widget-header h3 {
        padding: 0px;
        font-size: var(--font-size-larger);
    }

    .widget-footer {
        text-align: center;
        border-top: 1px solid $e4;
        padding-top: 10px;

        a {
            color: getVar(--link-color, $secondary);
            display: block;
        }
    }

    .meta-lint { margin: 0px; }

    &-item {
        display: flex;
        flex-flow: row nowrap;
        transition: all 0.15s linear;
        padding: 12px 12px 12px 7px;
        border-top: 1px solid $e4;

        &:hover { background-color: $f5; }

        .mod {
            flex: 0 0 6ch;
            text-align: right;
            font-size: var(--font-size-small);
        }

        .info {
            flex: 1;
            min-width: 0;
            position: relative;
        }

        .title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            display: block;
        }


        &.unread {
            font-weight: bold;
            border-left: 2px solid var(--pdf-red);
            background-color: $f5;

            .title, .mod { font-weight: bold; }
        }
    }

    // Option menu
    .widget-menu {
        position: absolute;
        top: 16px;
        right: 16px;

    }

    .widget-move {
        display: flex;
        flex-flow: row nowrap;
        border-top: 1px solid $e4;

        .button { flex: 0 0 50%; }

        .mobile-label { display: none; }

        @media ( max-width: ( $landscape - 1px )) {
            .mobile-label { display: block; }
            .desktop-label { display: none; }
        }
    }
}

// IE Fix buttons out of bounds
.is-ie {
    .widget-move {
        .button { flex: 1; flex-basis: 50%; }
    }
}
