//
//	Component image
//	- Generic styling for emblem, logo, image and thumb
//	- Aspect ratio's for dynamic height relative to width of element
//
img {
	max-width: 100%;
	font-size: 0px;
	vertical-align: middle;
	height: auto;
}

// Thumb, image, emblem and logo
.emblem, .logo, .thumb, .image {
    background-repeat: no-repeat;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
	
	img { width: 100%; height: auto; }

	// image element, usefull for animating
	.img {
		background-repeat: inherit;
		background-size: inherit;
		background-position: inherit;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		transform-origin: center;
		transition: all 0.15s linear;
		object-fit: cover;
		object-position: top center;
	}
}

// Emblem & logo
.emblem, .logo {
    background-size: contain;
    background-position: left center;
    min-height: 55px;

	a {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
	}
}

.emblem {
	background-image: getVar( --emblem, $emblem);
	min-width: 60px;
}

.logo {
	background-image: getVar( --logo, $logo);
	min-width: 100px;
}

.emblem.alt {
	background-image: getVar( --emblem-alt, $emblem-alt);
}

.logo.alt {
	background-image: getVar( --logo-alt, $logo-alt);
}

// Thumb & image
.thumb, .image {
	background-size: cover;
	background-position: center center;
	background-color: $f5;
	transition: all 0.3s linear;
	min-width: 50px;

	// Image element in thumg or image
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0px;
        object-fit: cover;
    }

	&.no-image {
		background-image: getVar( --emblem-alt, $emblem-alt);
		background-size: auto 40%;
		background-color: getVar( --primary, $primary);
	}
}
	
a:hover .no-image {
	background-color: getVar( --secondary, $secondary);
}

//
//	Relative height for thumbs
//	- ar[x]x[y]: aspect ratio x times y
//
.ar16x9,
.ar4x3,
.ar1x1,
.ar2x1,
.ar3x1,
.ar3x4,
.ar4x1 {
	position: relative;
	width: 100%;
	
	&:before {
		content: " ";
		display: block;
		transition: all 0.3s linear;
	}
}

.ar16x9:before 	{ padding-top: 56.25%;		}
.ar4x3:before 	{ padding-top: 75%;			}
.ar1x1:before 	{ padding-top: 100%;		}
.ar2x1:before 	{ padding-top: 50%; 		}
.ar3x1:before 	{ padding-top: 33.333333%;	}
.ar4x1:before 	{ padding-top: 25%;	}
.ar3x4:before 	{ padding-top: 133.333333%; }

//
//	Content in thumbs
//	- date for events / news
//	- title for titles
//
.thumb .date, .thumb .title {
	position: absolute;
	bottom: 0px;
	left: 0px;
	background-color: getVar( --secondary, $secondary);
	color: $white;
	padding: 10px 15px;
	line-height: 20px;
	font-size: var(--font-size-small);
	text-align: center;
	min-width: 80px;
}

.thumb abbr {
	border: 0px;
	border-bottom: 0px;
	text-decoration: none;
}

// Title
.thumb .title {
	width: 100%;
	text-align: left;
	font-weight: normal;
	margin: 0px;

	.fa, .icn {
		margin-right: 7px;
		line-height: inherit;
	}
}
