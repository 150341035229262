//
//  Minimized notifications
//
.notifications {
    overflow: hidden;
    max-height: 500px;

    .overflow-holder { overflow: hidden; }

    .noti-icn {
        .icn {
            overflow: hidden;
            display: inline-block;
        }

        .fa-chevron-left {
            max-width: 0px;
            min-width: auto;
            overflow: hidden;
            opacity: 0;
            transition:
                max-width 0.15s linear 0.8s,
                opacity 0.15s linear;
        }
    }

    &.minimized {
        transition-timing-function: linear;
        transition-delay: 0s;
        max-height: 44px;
        width: 60px;

        .noti-icn .fa-chevron-left {
            transition-delay: 0.8s;
            max-width: 16px;
            opacity: 1;
        }

        .noti-icn:not([data-amount="0"]):after {
            top: 0px;
            transition-delay: 1s;
        }

        .noti-content { overflow-y: hidden; }
    }

    .noti-header, .noti-footer {
        white-space: nowrap;
        overflow: hidden;
    }
}
