//
//  Decisions modal
//  - contains overview of all decisions
//
.decisions-modal {

    h4 {
        margin-top: 20px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--soft-grey);
    }

    p + ul, p + ol { margin-top: -15px; }
    .nr { color: var(--primary); }

    .decision-list {
        --item-row-bg: transparent;
        --item-row-hover-bg: transparent;
        font-size: 0.9em;
    }

    .decision-row {
        margin: 0px;
        padding: 5px 10px
    }

    .description :last-child {
        margin-bottom: 0px;
    }

    .use-counter .default-row:before {
        line-height: var(--line-height);
    }
}
