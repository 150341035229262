//
//  Activities record
//

@use 'sass:math';

.tab-activities {
    ul { margin-bottom: 30px; }

    .annotate-button {
        padding: 6px 15px;
        font-size: var(--font-size-small);
        min-width: 32px;
        flex: 0 0 auto;

        @media( min-width: $portrait ) and ( max-width: ( $landscape - 1px )) {
            .annotate-label { display: none; }
        }
    }

    // Hide info buttons in meeting-tools
    .default-row.meeting-tool {
        .button {
            visibility: hidden;
        }
    }
}
