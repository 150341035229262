//
//  Legenda
//
.voting-legenda-holder {
    padding: 10px 20px;
    font-size: var(--font-size-small);
    background-color: $f5;
    border: 1px solid $e4;
    margin: 30px 0px;

    .voting-legenda {
        display: flex;
        flex-flow: row nowrap;

        .legenda-label {
            flex: 0 0 80px;
            margin-top: 5px;
        }

        dd { font-size: var(--font-size-small); }
    }
}
