//
//  Basic layout of dashboard
//  - defines sidebar|content layout
//  - contains styling for the tabs
//  - contains grid for widgets
.dashboard-content {
    padding-top: 0px;
    padding-bottom: 50px;
    min-width: 0;

    @media ( min-width: $sidebar-breakpoint ){
        .container {
            display: flex;
            flex-flow: row nowrap;
        }
    }

    .dashboard-navigation {
        flex: 0 0 auto;
        width: 200px;
        margin-right: 20px;


        @media ( max-width: ( $sidebar-breakpoint - 1px )) {
            display: none;
        }

        @media ( min-width: $landscape ) {
            margin-right: 40px;
        }
    }
}

.dashboard-widgets {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    position: relative;
}

.dashboard-widget {
    flex: 0 0 calc( 50% - 20px );
    margin-bottom: 40px;
}

// No flex on mobile devices
@media( max-width: ( $landscape - 1px )) {
    .dashboard-widgets { display: block; }
    .dashboard-widget { flex-basis: 100%; }
}
