//
//  Dashboard meetings widget
//
.dashboard-meetings-widget {
    .meeting-item {
        display: flex;
        flex-flow: row nowrap;
        transition: all 0.15s linear;
        border-radius: 3px;

        &:hover, &:focus { background-color: $f5; }
    }

    .next-meeting {
        padding: 15px 0px;
        border-top: 1px solid $e4;
        border-bottom: 1px solid $e4;

        h4 {
            font-size: var(--font-size);
            margin: 0px 0px 10px 5px;
        }
    }

    .next-meeting .meeting-item {
        padding: 5px;
        align-items: center;

        .icn, .profile-icon { flex: 0 0 auto; }

        .profile-icon {
            margin-right: 15px;

            @include equalAll(50px);
        }

        .info { flex: 1 1 auto; }

        .group {
            display: block;
            font-weight: bold;
            font-size: var(--font-size-larger);
            line-height: 1;
        }

        .tag { vertical-align: middle }

        .pointer {
            font-size: 24px;
            flex-basis: 48px;
            text-align: center;
        }
    }

    .meeting-list .meeting-item {
        padding: 3px;
        margin: 5px 0px;

        .start { flex: 0 0 100px; }
        .pointer { flex: 0 0 auto; }
        .group { flex: 1 1 auto; }
    }
}
