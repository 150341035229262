//
//  Pointer
//
.pointer {
    color: inherit;

    .icn { color: getVar( --primary, $primary); }

    &, .icn {
        transition: all 0.15s linear;
        transform-origin: center center;
    }
}

button.pointer {
    background-color: transparent;
    border: 0px;
    border-radius: 3px;

    &:hover {
        background-color: var(--f5);
    }
}
