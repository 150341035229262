//
//  Dashboard tabs
//  - styling for the tab-content elements on the dashboard
//      - for the show / hide of tabs
//      - for the AJAX content loading, dependand of/to dashboard.js
//
.dashboard-tab {
    display: none;
    flex: 1 1 auto;
    position: relative;
    min-height: 400px;
    min-width: 0;

    &.active { display: block; }
}

.ajax-content {
    .dashboard-tab-content {
        opacity: 0;
        transform: translateY( +20px );
        transition: all 0.3s linear 0.15s;
    }

    .loading-placeholder {
        height: 50vh;
        position: absolute;
        top: 0px;
        text-align: center;
        transition: all 0.3s linear;
        pointer-events: none;
        width: 100%;
        background-color: transparent;

        .icn {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 48px;
            color: var(--f5);
        }
    }

    &.loaded {
        .dashboard-tab-content {
            opacity: 1;
            transform: translateY(0);
        }

        .loading-placeholder { display: none; }
    }
}
