//
//  No content element in dashboard
//
.dashboard-overview {
    .no-content {
        text-align: center;
        padding: 50px;
        position: relative;
        display: none;
        font-size: var(--font-size-small);
        color: var(--medium-grey);
        font-style: italic;
        border-top: 1px solid var(--e4);
        border-bottom: 1px solid var(--e4);

        .icn {
            color: var(--soft-grey);
            font-size: 24px;
            margin-bottom: 10px;
            display: block;
        }
    }

    .show-no-content .no-content { display: block; }
}

.dashboard-widget {
    .no-content {
        display: block;
        border-bottom: 0px;
    }
}
