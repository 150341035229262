//
//  My preferences
//  - styling for view where user can select on which parts to recieve updates per mail or in their agenda
//

$group-breakpoint: $mobileLandscape;
$group-hover: $backBlue;
$form-max: 950px;

.my-preferences {

    // Clear forms
    form, fieldset {
        border: 0px;
        padding: 0px;
        max-width: $form-max;
    }

    label {
        font-weight: bold;
        cursor: pointer;
        width: 100%;
    }

    form {
        margin: 70px 0px;

        a {
            text-decoration: underline;

            &:hover { text-decoration: none; }
        }

        h3, h4 { font-weight: bold; }
    }

    .toolbar { margin: 30px 0px; }

    .type-label, .setting {
        display: inline-block;
        text-align: center;
    }

    .meta-block {
        @media ( min-width: $group-breakpoint ) {
            padding-left: 20px;
        }

        @media ( max-width: ( $group-breakpoint - 1px )) {
            dt { flex-basis: 100px; }
        }

        .icn { color: var(--primary); }
    }

    .check {
        display: inline-block;
        text-align: center;
        color: var(--white);
        border: 1px solid var(--soft-grey);
        background-color: var(--white);
        transition: all 0.15s linear;
        border-radius: 3px;

        @include equalAll(30px);
        .icn { color: inherit }
    }

    input:checked + label .check {
        background-color: var(--green);
        border-color: var(--green);
    }

    button[type="submit"] {
        &:not(.saving) {
            .fa-spinner { display: none }
        }
        &:not(.saved) {
            .fa-check { display: none }
        }
        &.saving {
            .fa-save { display: none }
        }
        &.saved {
            .fa-save { display: none }
        }
    }
}

.setting-holder, .settings-head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .types { flex: 0 0 auto; }

    .opt {
        flex: 1 1 auto;
        min-width: 0;
        margin-left: 10px;
        font-size: var(--font-size-small);

        .icn { color: var(--light-grey); }
    }
}

.settings-head {
    font-weight: bold;
    border-bottom: 1px solid var(--soft-grey);

    .icn { color: var(--primary); }
}

// Levels for (sub)groups, mainly themes
.settings-group {
    position: relative;

    .level {
        display: none;
        background-color: var(--f5);

        .title {
            padding-left: 35px;
            position: relative;

            &:before {
                position: absolute;
                left: 20px;
                top: 10px;
                content: " ";
                width: 10px;
                height: 10px;
                border-left: 1px solid var(--dark-grey);
                border-bottom: 1px solid var(--dark-grey);
            }
        }

        .level {
            .title {
                padding-left: 55px;
                font-size: var(--font-size-small);

                &:before { left: 40px; }
            }

            .level {
                .title {
                    padding-left: 75px;
                    font-size: var(--font-size-small);

                    &:before { left: 60px; }
                }
            }
        }
    }

    .pointer {
        background-color: var(--f5);
        border-radius: 3px;
        width: 20px;
        line-height: 20px;
        display: inline-block;
        text-align: center;
        text-decoration: none;

        .icn { pointer-events: none; }
        .fa-minus { display: none; }
    }

}

.setting-holder {
    transition: all 0.15s linear;

    &:hover, &.active { background-color: $group-hover; }
    input { display: none; }
    .title { line-height: 1.2; }

    .type-mobile .active .icn { color: var(--green); }

    &.show-children {
        .pointer {
            .fa-plus { display: none; }
            .fa-minus { display: block; }
        }

        & + .level { display: block; }
    }

    // Select all link at and of row
    .select-all {
        position: relative;
        display: block;

        span {
            pointer-events: none;
            position: relative;
            display: inline-block;
            text-decoration: inherit;
        }

        .icn { text-decoration: none; }

        @media ( min-width: $mobileLandscape ) and ( max-width: ( $landscape - 1px )) {
            .sel { display: none; }
        }

        @media ( max-width: ( $portrait - 1px )) {
            .all { display: none; }
        }
    }
}

// For mobile
@media ( max-width: ( $group-breakpoint - 1px )) {
    @import "settingsGroupMobile";
}

// For larger devices
@media ( min-width: $group-breakpoint ) {
    @import "settingsGroup";
}

// For mobile landscape & portrait, smaller columns
@media ( min-width: $mobileLandscape ) and ( max-width: ( $landscape - 1px )) {
    .my-preferences {
        .type-label, .setting {
            width: 90px;
        }

        .title { flex: 0 0 310px; }
    }
}

// For mobile landscape & portrait, smaller columns
@media ( min-width: $mobileLandscape ) and ( max-width: ( $portrait - 1px )) {
    .my-preferences .title { flex: 0 0 250px; }
}

// Download iCal description and button
.download-ical-block {
    margin: 60px 0px 30px 0px;
    padding: 20px 0px;
    border-top: 1px solid var(--soft-grey);
    border-bottom: 1px solid var(--soft-grey);
    max-width: $form-max;

    @media ( min-width: $mobileLandscape ){
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .description {
            flex: 1 1 auto;
            margin-right: 50px;
        }

        .options { flex: 0 0 auto; }
        .button { white-space: nowrap; }
    }

    @media ( max-width: ( $mobileLandscape - 1px )) {
        .options .button{
            display: block;
            margin-top: 30px;
        }
    }
}

// Final fix IE11
.is-ie.my-preferences .title { max-width: 350px; }
