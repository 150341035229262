//
//  Widget base
//  - contains the widget base styling and generic widget classes
//
.widget {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    min-height: 100px;
}

.widget-header, .widget-footer, .widget-toolbar { flex: 0 0 auto; }
.widget-content {
    flex: 1 1 auto;
    padding: 5px;
}

// Widget color schemes
.white-widget {
    @include whiteWidget;
}

.light-widget {
    background-color: $f5;
    border: 1px solid $softGrey;

    // Lay header over widget border
    .widget-header { margin: -1px -1px 0px -1px; }

}

// Widget header
.widget-header {
    color: $darkGrey;
    font-weight: bold;
    
    .widget-title {
        font-size: var(--font-size);
        color: inherit;
        margin: 0px;
        padding: 5px 5px 5px 15px;
        line-height: 30px;
    }
}

.header-primary {
    background-color: $primary;
    color: $white;
}

.header-secondary {
    background-color: $secondary;
    color: $white;
}

.header-dark {
    background-color: $mediumGrey;
    color: $white;
}

// Widget footer / Widget toolbar
.widget-footer, .widget-toolbar { text-align: right; }
.widget-toolbar { text-align: center; }

// Widget item
.widget-item {
    color: inherit;
    text-decoration: none;
    transition: all 0.15s linear;
    padding: 5px 5px 5px 10px;
    margin: 5px 0px;
    border-radius: 3px;
    line-height: 1.4;

    .pointer { line-height: inherit; }

    &:hover, &:focus { background-color: $f5; }
}
