//
// Position of info button on search page
//
#page.zoeken {
    .search-breadcrumb-holder {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .title-holder { flex: 1 1 auto; }
    }
}
