//
//  Wie zit waar
//  - styling for the 'wie zit waar' page
//  - this page shows a map with the numbered seats and a legenda to match these seats to a profile
//
.room-and-seats-container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: space-between;

    ul { list-style: none; }

    li {
        border-bottom: 1px solid #ccc;
        color: #666;
        display: block;
        font-weight: bold;
        padding: 5px 0px;

        .nr, img { margin-right: 5px; }
        .nr { display: inline-block; }
    }

    .room-person-list, .room-legend {
        flex: 0 0 49%;
        margin: 0px;
    }

    .room-person-list { order: 2; }
    .room-legend { order: 3; }

    .room-image {
        border: 1px solid $e4;
        padding: 20px;
        text-align: center;
        float: left;
        position: relative;
        flex: 0 0 100%;
        order: 1;
        margin-bottom: 40px;
    }

    .room-legend li {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    @media ( max-width: ( $portrait - 1px )){
        img { max-width: 100%; }
    }

    @media ( max-width: ( $mobileLandscape - 1px )) {
        flex-flow: column nowrap;

        .room-person-list { order: 3; }
        .room-legend { order: 2; }
        .room-image { order: 1; }
    }
}
