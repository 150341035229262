//
//  CSS variables for the timeline
//

$breakpoint: $portrait;

:root {
    --tl-icn-size: 40px;
    --tl-gap: 10px;
    --tl-gap-larger: 30px;
    --tl-icn-bg: var(--white);
    --tl-icn-col: var(--dark-grey);
    --tl-act-max: 500px;
    --tl-meeting-max: 300px;
    --tl-milestone-max: 200px;
    --tl-status-max: 240px;
    --tl-line-bg: var(--soft-grey);
    --tl-line-width: 2px;
    --tl-marker-bg: var(--dark-grey);
    --tl-marker-col: var(--white);

    // Specific variables for the time-line-row
    --arrow-x: -50%;
    --act-margin: 0px 0px 0px auto;
    --tl-act-bg: var(--ee);
    --tl-act-shadow: none;
}

// Change several CSS variables for when after today
.after-today {
    --tl-act-bg: var(--white);
    --tl-act-shadow: drop-shadow(0 0px 5px rgba(0,0,0,.1));
    --tl-icn-col: var(--primary);
}
