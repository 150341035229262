//
//  Activities
//  - styling for the calendar page with events and meetings
//  - and the styling for the year overview of months
//

.calendar-year-overview {
    display: grid;
	grid-template-columns: repeat( auto-fit, minmax( 280px, 1fr ));
	grid-gap: 20px;
	list-style: none;
	padding-left: 0;
}

// Layout
.activities-page-holder {
	display: flex;
	flex-flow: row nowrap;

	.day_container {
		flex: 1 1 auto;
		order: 2;
	}

	.calendar-default {
		flex: 0 0 300px;
		order: 1;
		margin-right: 30px;
	}

	// Different layout on mobile portrait
	@media( max-width: ($mobileLandscape - 1px )) {
		flex-direction: column;

		.calendar-default {
			flex: 0 0 auto;
			margin-bottom: 30px;
		}
	}

	.activities-year-holder {
		flex: 1 1 100%;
	}
}

.day_container {
	position: relative;

	ul {
		list-style: none;
		padding: 0px;
	}

	li {
		position: relative;

		h2 a {
			text-decoration: none;
		}
	}

	li.meeting, li.news, li.event, &.event {
		padding: 10px 20px;
		border: 1px solid #E4E4E4;
		margin-bottom: 20px;
	}

	&.event { box-sizing: border-box; }

	.download_iCal {
		position: absolute;
		right: 10px;
		top: 10px;
	}

	// SD-45444
	li.preview { border-color: var(--orange); }

	.preview-label {
		position: absolute;
		right: 0;
		top: 0px;
		border-radius: 0px 0px 0px 5px;
		background-color: var(--orange);
		color: var(--white);
		font-weight: bold;
		font-size: var(--font-size-large);
		padding: 0px 12px;
	}

	.publication-date {
		font-size: var(--font-size-smaller);
		color: var(--white);
		background-color: var(--secondary);
		display: inline-block;
		padding: 0px 5px;
		border-radius: 3px;
		font-weight: bold;
	}
}

// Download to kalender button on smaller screens
@media ( max-width: ( $landscape - 1px )) {
    .day_container .download_iCal {
        position: relative;
        top: 0px;
        right: 0px;
        clear: both;
        display: block;
        margin-bottom: 20px;
    }
}
