//
//  Pagination
//  - page navigation element, contains styling for next/previous buttons and page number links
//

.pagination-holder {
    margin-top: 40px;
}

.pagination {
    clear: both;
    margin: 50px 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .button.smaller {
        line-height: 20px;
        padding: 4px 10px;
        flex: 0 0 100px;
    }

    .pages {
        margin: 0px 20px;
        list-style: none;
        display: inline-block;
    }

    li {
        display: inline
    }

    .page-link {
        display: inline-block;
        font-weight: bold;
        text-decoration: none;
        background-color: $white;
        border: 1px solid $e4;
        transition: all 0.15s linear;
        text-align: center;
        margin: 0 1px;
        min-width: 30px;
        height: 30px;
        line-height: 28px;
        color: inherit;
        padding: 0 4px;
        box-sizing: border-box;

        &:hover {
            background-color: $hover-blue;
            border-color: $hover-blue;
            color: $white;
        }

        &.current-page {
            background-color: var(--primary);
            border-color: var(--primary);
            color: $white;
        }
    }
}

.item-counter {
    text-align: center;
    font-size: var(--font-size-small);
    margin-bottom: 10px;

    & + .pagination {
        margin-top: 0px;
    }
}
