//
//  term-agenda
//  - loads style parts for the term-agenda
//

// Settings and color schemes
@import "settings";
@import "colors";

// Add specific fixes for FrontEnd
.term-agenda-page #wrapper { padding-top: 0px; }

// Include mobile view
@media ( max-width: ( $portrait - 1px ) ) {
    @import "mobile-domain-overview";
}

@media (min-width: $portrait ) {
    .hide-desktop { display: none; }
}

// Include desktop view
@media ( min-width: $portrait ) {

    // Import base layout
    @import "layout";

    // Import partials
    @import "overview-header";
    @import "domain-row";
    @import "dossier-row";
    @import "today";
    @import "activities";
    @import "toolbar";
    @import "pop-up";
    @import "_page";
    @import "loading";
    @import "filter-menu";

    // Import extra styling used by JS to show/hide based on selected filters
    @import "_filter-and-view-settings.scss";

}

// Include alternative views
@import "year-view";

// Include the list view
@import "list";
