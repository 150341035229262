//
//  Legenda
//  - extra styling for elements in the legenda
//

.legenda-groups {
    @media ( min-width: $portrait ) {
        display: flex;
        flex-flow: row wrap;
    }
}

.legenda-group {
    margin-bottom: 20px;

     @media ( min-width: $portrait ) {
        flex: 0 0 50%;
        padding-right: 10px;
    }

    .legenda-item {
        padding-left: 5px;
            .clean-button {
                pointer-events: none;
                background-color: var(--e4);
            }
    }

    &.first { flex-basis: 60%; }
    &.second { flex-basis: 40%; }
}
