//
//  Subscription
//  - extra styling for the (e-mail) subscription form for visiters
//
#aboform {
    clear: both;

    .block {
        margin-bottom: 0;
        width: 100%;
    }

    input[type=checkbox]+label { float: none;}
    .form-group .unscribe { float: right; }

    .mt .form-group div {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;

        label { flex: 1 1 auto; }
    }

    /* Verplicht veld abonemmenten pagina */
    .required_last_name {
        color: #dd0000;
        font-size: var(--font-size-small);
        padding-left: 4px;
        font-weight: bold;
    }
}

.message + #aboform { margin-top: 20px; }
.abocancel { float: right; }

// GO no robot check
.goNoRobot {
    input {
        float: none;
        display: inline-block;
    }

    .label {
        margin-bottom: 10px;
        display: block;
    }

    .question, .answer, .is {
        font-size: var(--font-size-large);
        height: 30px;
    }

    .question { width: 40px; }
    .answer { width: 50px; }
    #captcha_answer:target { border: 2px solid red; }

    .is {
        text-align: center;
        width: 30px;
        padding: 0px 10px;
    }

    input[type="button"] {
        height: 42px;
        position: relative;
        top: -2px;
        padding: 0px 10px;
        margin-left: 30px;
    }

    .captchaMessage {
        color: red;
        border: 0px;
        background-color: transparent;
    }
}
