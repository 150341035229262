//
// Search reset/base styling
//

/* Base */
& { position: relative; }

// Clear form elements
form, fieldset, ul, li {
	margin: 0px;
	padding: 0px;
	border: 0px;
	clear: none;
}

#searchresults strong:not(.inheritStyle) {
	font-weight: bold;
	display: inline-block;
	border: 1px solid $blue;
	background-color: $backBlue;
	padding: 0px 3px;
	margin: 0px 2px;
	color: #3d4c53;
}

.advancedFilters:after, .container:not(.base):after {
	content: " ";
	clear: both;
	display: block;
	font-size: 1px;
	height: 1px;
}

a:hover { text-decoration: underline; }

input[type=text], input[type=submit] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.field-wrap {
	position: relative;
	display: block;
	margin-bottom: 10px;
	clear: both;
	break-inside: avoid;
}

.legend {
	color: $darkGrey;
	font-size: var(--font-size-large);
	margin: 0px 0px 10px 0px;
	padding: 0px;
	width: 100%;

	button {
		float: right;
	}
}

h2, h2 a { font-size: calcEmFontSize(20pt); line-height: 1.8; }
h3, h3 a, .title, .title a { font-size: var(--font-size-large); line-height: 1.4; }

.gosMore, .gosClose, .gosTop {
	position: relative;
	display: block;
	background-repeat: no-repeat;
	color: $mediumGrey;
	text-decoration: none;
	flex: 0 0 auto;

	&:after {
		content: "\f106";
		font-family: $font-awesome;
		font-weight: bold;
		display: inline-block;
		width: 20px;
		text-align: center;
	}
}

.gosMore:after { content: "\f107"; }

input[type=text] {
	border: 1px solid $input-border;
	width: auto;
	padding: 3px 5px;
	margin-right: 5px;

	&:focus {
		border: 1px solid $focus-outline-color;
		background: $white;
	}
}
