//
// Alternative font-sets
//

// Use Montserrat as main font
.use-montserrat {
    --font-family: #{$montserrat};
}

.use-arial {
    --font-family: Arial, Helvetica, sans-serif;
}

// Increase font-size
.larger-font {
    --font-size: 1.07143rem;
    --line-height: 2;
}
