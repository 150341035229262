//
//  Social media
//
.profile-info .social {
    a {
        display: inline-block;
        text-align: center;
        border-radius: 100%;
        color: $white;
        margin: 2px;
        background-color: var(--secondary);
        transition: all 0.15s linear;
        position: relative;

        .label {
            position: absolute;
            top: 100%;
            background-color: inherit;
            padding: 5px 10px;
            font-size: var(--font-size-small);
            font-weight: bold;
            border-radius: 3px;
            opacity: 0;
            left: 50%;
            line-height: 20px;
            transform: translateX(-50%);
            transition: all 0.15s linear;
            box-shadow: 0px 2px 2px rgba( 0, 0, 0, 0.15 );
            margin-top: 0px;
            pointer-events: none;
            z-index: 1;
            white-space: nowrap;

            &:before {
                content: " ";
                position: absolute;
                width: 14px;
                height: 14px;
                transform-origin: center;
                top: -6px;
                left: 50%;
                background-color: inherit;
                transform: translateX(-50%) rotate(-45deg);
            }
        }

        @include equalAll(40px);

        &:hover, &:focus {
            background-color: $focus-outline-color;

            .label {
                opacity: 1;
                margin-top: 15px;
            }

            @each $color in $socialColors {
                $name: nth($color, 1 );
                $value: nth($color, 2 );

                &.#{$name} {
                    background-color: nth($value , 1);
                }
            }
        }
    }
}
