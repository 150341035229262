//
// Extra color schemes
//

// Overwrite color variables

.primary-header {
    --header-bg-color: var(--hsl-primary-color);
    --header-bg-lum: var(--hsl-primary-lum);
    --header-bg: var(--primary);
    --logo-bg: var(--secondary);
    --header-active: var(--secondary);

    --basic-bg: var(--primary);
    --basic-border: var(--primary);
    --basic-menu-txt: #fff;

    --account-icn-color: #fff;

    .main-header {
        --btn-primary: var(--btn-secondary);
    }
}

.secondary-header {
    --header-bg-color: var(--hsl-secondary-color);
    --header-bg-lum: var(--hsl-secondary-lum);
    --header-bg: var(--secondary);
    --logo-bg: var(--primary);

    --basic-bg: var(--secondary);
    --basic-border: var(--secondary);
    --basic-menu-txt: #fff;

    .main-header {
        --btn-secondary: var(--btn-primary);
    }
}

.logo-white .main-header {
    --logo-bg: #{$white};
}

.basic-bar {
    --basic-bar-bg: var(--basic-bg);
}

// Logo white
.logo-white .main-header .logo-holder {
    box-shadow: 0px 0px 5px rgba( 0, 0, 0, 0.25 );

    .logo { background-image: var(--logo); }
    .emblem { background-image: var(--emblem); }
}
