//
//  Profile icon
//  - shows an small icon of a user, as:
//      - thumbnail or profile image
//      - initials of user's name
//      - not logged in or generic user icon
//  - default size is 50x50px, uses extra classes for other size
//      - .tiny: 20px, .small: 40px;, .smaller: 24px, .large: 70px, .larger: 120px;
//

:root {
    --picn-bg: var(--dark-grey);
    --picn-not-bg: var(--soft-grey);
    --picn-border: var(--white);
    --picn-txt: var(--white);
    --picn-size: 50px;
    --picn-font-size: 24px;
}

.profile-icon {
    display: inline-block;
    flex: 0 0 auto;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    font-size: var(--picn-font-size);
    color: var(--picn-txt);
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    vertical-align: middle;

    outline: 2px solid var(--picn-border);
    border-radius: 100%;

    background-color: var(--picn-bg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    transition: all 0.15s linear;
    transform-origin: center center;

    object-fit: cover;
    object-position: center center;

    // Default size
    width: var(--picn-size);
    height: var(--picn-size);
    line-height: var(--picn-size);

    * { pointer-events: none; }

    // Image in icon
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0px;
        object-fit: cover;
    }

    // Other sizes
    &.extra-large {
        --picn-size: 120px;
        --picn-font-size: 54px;
    }

    &.larger {
        --picn-size: 70px;
        --picn-font-size: 32px;
    }

    &.small {
        --picn-size: 40px;
        --picn-font-size: 16px;
    }

    &.smaller {
        --picn-size: 30px;
        --picn-font-size: 14px;
    }

    &.smallest {
        --picn-size: 24px;
        --picn-font-size: 10px;
    }

    &.tiny {
        --picn-size: 20px;
        --picn-font-size: 8px;
    }

    &.smaller, &.smallest, &.tiny {
        outline-width: 1px;
    }

    // Element in icon
    span {
        margin: 0px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate( -50%, -50% );
    }

    &.not-logged-in, &.no-user {
        background-color: var(--picn-not-bg);

        &:before {
            content: " ";
            position: absolute;
            width: 90%;
            height: 6px;
            background-color: inherit;
            border-radius: 4px;
            bottom: 40%;
            left: 5%;
            z-index: 5;
            transform-origin: center center;
            transform: rotate(-45deg);
        }

        &.tiny, &.smaller {
            &:before { height: 3px; }
        }

        &.extra-large:before { height: 9px; }
    }
}

// Link
a.profile-icon {
    &:hover, &:focus {
        color: var(--picn-txt);
        transform: scale( 1.2 );
        background-color: var(--secondary);
    }
}

// Color schemes
@each $color in $colors {
    $name: nth($color, 1 );
    $value: nth($color, 2 );

    .profile-icon.#{""+$name} {
        --picn-bg: var(#{"--"+$name});
    }
}

// Group icon
.group-icn {
    font-size: 22px;
    width: var(--picn-size);
    flex-basis: var(--picn-size);
    height: var(--picn-size);
    line-height: var(--picn-size);
    border-radius: 100%;
    color: var(--picn-txt);
    background-color: var(--primary);
    text-align: center;

    .icn {
        color: inherit;
        margin: 0px;
    }
}

// More icon
.more-icon {
    --picn-bg: var(--e4);
    --picn-txt: var(--medium-grey);
}

//Multiple profile-icons
.profile-icon + .profile-icon {
    margin-left: calc( (var(--picn-size) / 2 ) * -1);
}
