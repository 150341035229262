//
//  Settings group on larger devices
//

$col-size: 130px;

.my-preferences {
    .type-label, .setting {
        width: $col-size;
        border-left: 1px solid $softGrey;

        .label { width: $col-size; }

        &:before {
            content: " ";
            display: block;
            position: absolute;
            background-color: $group-hover;
            top: 0px;
            bottom: 0px;
            width: $col-size;
            z-index: -1;
            opacity: 0;
            transition: all 0.15s linear 0.15s;
        }

        &:hover:before {
            opacity: 1;
            transition-delay: 0s;
        }

        &:last-of-type { border-right: 1px solid $softGrey; }
    }

    .title { flex: 0 0 350px; }

    .toolbar {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        h3 { flex: 1 1 auto; }
        .options { flex: 0 0 auto; }
    }
}

.settings-group { margin-bottom: 70px; }

.settings-head {
    .icn { display: none; }

    h4, label, span {
        margin: 0px;
        line-height: 30px;
    }

    .types {
        display: flex;
        flex-flow: row nowrap;
    }
}

.setting-holder {
    .type-mobile, .choose, .label { display: none; }
    .setting, .title, .opt { padding: 10px 0px; }
    .title, .opt { padding-left: 10px; }

    .type-menu {
        display: flex;
        flex-flow: row nowrap;
    }
}
