//
//  Agenda layout
//  - contains styling for the layout of the agenda
//
.agenda-holder {
    
    // Reset
    &, .session-holder, .session-bar, .session {
        position: relative;
        box-sizing: border-box;
    }

    .session {
        transition: all 0.3s 0.15s linear;
        min-width: 0;
    }
    
    .session > ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
     }
}

.session-holder {
    margin: 30px 0px;
    display: flex;
    flex-flow: column-reverse nowrap;
}

// Session-holder sessions and bar side-by-side
$sb-width: 240px;
$sb-margin: 30px;

@media ( min-width: $landscape ) {
    .session-holder {
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
        
        .session-bar {
            flex: 0 0 $sb-width;
            margin-left: $sb-margin;
            transition: margin-right 0.15s 0s linear;
            margin-right: (($sb-width + $sb-margin) * -1);
            visibility: hidden;
        }
        
        .multi-session {
            flex: 1 1 auto;
        }
    }
}

// Multi-sessions side-by-side
@media ( min-width: $landscape ){
    .multi-session {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        min-width: 0;
        margin-top: -30px;

        .session {
            flex: 0 0 calc( 50% - 15px );
            width: calc( 50% - 15px );
            margin-top: 30px;
        }
        
        &.grid-three .session {
            flex-basis: calc( 33.333333% - 20px );
            width: calc( 33.333333% - 20px );
        }
    }
}

// Switch between showing and hiding sessions / session-bar based on viewport

// Mobile and tablet portrait: Hide inactive and show active sessions
@media ( max-width: ( $landscape - 1px ) ) {
    .part-session {
        display: none;
        margin-top: 5px;

        @media (min-width: $portrait) {
            margin-top: 20px;
        }
    }
    .session-opened .active-session {
        display: block;
    }
}

// Landscape and larger: Hide inactive sessions and show session-bar, when a session is opened.
@media ( min-width: $landscape ) {
    .session-opened {
        .part-session { display: none; transition-delay: 0s }
        .active-session {
            flex: 1 1 auto;
            display: block;
        }
    
        .session-bar {
            margin-right: 0px;
            transition-delay: 0.15s;
            visibility: visible;
        }
    }
}
