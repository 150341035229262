//
//  Recent items widget
//  - used in widget and in recent tab view containing more recent items
.dashboard-recent-widget, .dashboard-recent-list {
    .recent-icn {
        font-size: var(--font-size-larger);
        color: var(--primary);
        width: 24px;
        text-align: center;
        margin-right: 10px;
    }

    .meeting .recent-icn { color: var(--secondary); }
    .event .recent-icn { color: var(--primary); }
    .document .recent-icn { color: var(--pdf-red); }
    .meta-lint, .mod, .new { font-size: var(--font-size-small); }
    .meta-lint .icn, .mod { color: var(--light-grey); }
    .new, .name { line-height: 1.2; }
    .new { display: block; }
    .meta-lint { margin-top: 0px; }

    .name {
        display: block;
        font-weight: bold;
        word-break: break-word;
    }

    .recent-list-header {
        h3 {
            font-size: var(--font-size);
            font-weight: bold;
            text-transform: capitalize;
        }

        & ~ .recent-list-header { margin-top: 40px; }
    }
}

.dashboard-recent-list {
    .dashboard-widget-item {
        padding: 7px 12px 7px 7px;

        .meta-lint { margin: 0px; }
    }

    .more-button {
        display: block;
        margin-top: 40px;
        border-radius: 0px;
        border-color: transparent;
        border-top-color: var(--soft-grey);

        &:hover, &:focus { box-shadow: none; }
        &:hover { background-color: var(--f5); }
    }

    .more-content-holder {
        clear: both;
        position: relative;
        min-height: 300px;

        .recent-list-header { margin-top: 40px; }

        .more-placeholder {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50% );
            text-align: center;

            .icn {
                font-size: 48px;
                color: var(--f5);
            }
        }

        & ~ .more-button { display: none; }
        &.loaded ~ .more-button { display: block; }
        &.loaded .more-placeholder { display: none; }
        &.hide-more ~ .more-button { display: none; }
    }

    @media ( min-width: $landscape ) {
        .info {
            display: flex;
            flex-flow: row wrap;

            .new { flex: 0 0 100%; }
            .meta-lint { flex: 0 0 40%; }
            .name {
                flex: 0 0 60%;
                min-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
