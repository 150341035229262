/* #################### */
/* #      Fonts       # */
/* #################### */

// Open Sans
@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-light-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-light-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-light-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-light-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-lightitalic-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-lightitalic-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-lightitalic-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-lightitalic-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-lightitalic-webfont.svg#open_sanslight_italic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-regular-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-regular-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-regular-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-regular-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-italic-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-italic-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-italic-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-italic-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-semibold-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-semibold-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-semibold-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-semibold-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-semibolditalic-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-semibolditalic-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-semibolditalic-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-semibolditalic-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-semibolditalic-webfont.svg#open_sanssemibold_italic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-bold-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-bold-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-bold-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-bold-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-bolditalic-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-bolditalic-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-bolditalic-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-bolditalic-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-bolditalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-extrabold-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-extrabold-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-extrabold-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-extrabold-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-extrabold-webfont.svg#open_sansextrabold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url($root-path + 'fonts/opensans/opensans-extrabolditalic-webfont.eot');
    src: url($root-path + 'fonts/opensans/opensans-extrabolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url($root-path + 'fonts/opensans/opensans-extrabolditalic-webfont.woff2') format('woff2'),
    url($root-path + 'fonts/opensans/opensans-extrabolditalic-webfont.woff') format('woff'),
    url($root-path + 'fonts/opensans/opensans-extrabolditalic-webfont.ttf') format('truetype'),
    url($root-path + 'fonts/opensans/opensans-extrabolditalic-webfont.svg#open_sansextrabold_italic') format('svg');
    font-weight: 800;
    font-style: italic;
}

//
//  Montserrat
//

// montserrat-regular - latin
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: local('Montserrat Light'), local('Montserrat-Light'),
    url($root-path + 'fonts/montserrat/montserrat-300.woff2') format('woff2'),
    url($root-path + 'fonts/montserrat/montserrat-300.ttf') format('truetype')
}

// montserrat-regular - latin
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url($root-path + 'fonts/montserrat/montserrat-regular.woff2') format('woff2'),
    url($root-path + 'fonts/montserrat/montserrat-regular.ttf') format('truetype')
}

// montserrat-500 - latin
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url($root-path + 'fonts/montserrat/montserrat-500.woff2') format('woff2'),
    url($root-path + 'fonts/montserrat/montserrat-500.ttf') format('truetype')
}

// montserrat-600 - latin
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url($root-path + 'fonts/montserrat/montserrat-600.woff2') format('woff2'),
    url($root-path + 'fonts/montserrat/montserrat-600.ttf') format('truetype')
}

// montserrat-700 - latin
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url($root-path + 'fonts/montserrat/montserrat-700.woff2') format('woff2'),
    url($root-path + 'fonts/montserrat/montserrat-700.ttf') format('truetype')
}

// montserrat-800 - latin
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url($root-path + 'fonts/montserrat/montserrat-800.woff2') format('woff2'),
    url($root-path + 'fonts/montserrat/montserrat-800.ttf') format('truetype')
}
