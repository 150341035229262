//
//  Dossier overview
//

// Dossier overview container
.dossier-overview-holder {
    --of-filter-offset: 0px;
    margin-top: 40px;

    .pagination-holder { margin-top: 40px; }


    &:not(.show-no-content) {
        .no-content { display: none; }
    }

    .no-content {
        text-align: center;
        border-top: 1px solid var(--e4);
        padding-top: 30px;

        .icn {
            font-size: 1.4rem;
            color: var(--soft-grey);
        }
    }
}
