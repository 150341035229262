//
//  Other
//  - Toggleable and is-loading styling for the voting modal
//
.voting-modal {
    @media ( min-width: 800px ) {
        .window { width: 700px; }
    }

    .voting-loading-placeholder {
        display: none;
        padding: 50px 0px;
        text-align: center;

        .icn {
            display: inline-block;
            font-size: 24px;
            color: $softGrey;
        }
    }

    &.is-loading {
        .voting-loading-placeholder { display: block; }
        .votings-holder { display: none; }
    }

    .voting-holder {
        border-top: 1px solid $softGrey;
        padding: 10px 0px;

        // For toggling
        &:not(.active) {
            .graph-holder, .voting-table, .no-results-table {
                display: none;
            }
        }
    }

    // No results text, when a voting does not have public party results (schriftelijke stemming)
    .no-results-table {
        text-align: center;
        margin: 20px 0px;
        padding: 20px;
        border-radius: 5px;
        background-color: $f5;
        font-size: var(--font-size-small);

        .icn {
            display: block;
            color: $softGrey;
            font-size: 24px;
            margin-bottom: 10px;
        }
    }

    // My vote element
    .my-vote {
        padding: 15px;
        margin-bottom: 20px;
        background-color: $f5;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        h4 { margin: 0px 10px 0px 0px; }
    }
}
