//
// Redesign for document rows
//
.document-redesign-list  {

    .document-row {
        border-radius: 5px;
        margin-bottom: 5px;
        border-left: 5px solid var(--item-row-bg);
        background: var(--item-row-bg);



        .row-icn {
            font-size: calcFontSize(24pt);
            margin: 0 10px;
        }

        .doc-meta { color: #6f6f6f; }
        .doc-info { margin-left: 3px; }

        .options {
            border-left: 0;
            margin: auto 5px;

            .date {
                font-weight: bold;
                margin-right: 10px;
                color: #6f6f6f;
            }
        }

        &:hover {
            --item-row-bg: transparent;
            border-color: transparent;
        }
    }

    .document-row.unread {
        font-weight: normal;
        background: var(--unread-bg);
        border-left-color: var(--unread-border);
    }
}
