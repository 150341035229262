//
//  Footer grid
//  This an temporary grid CSS to overcome the differences between CMS (Inwonersportaal) and the RIS default grid.
//  - The default RIS grid is based on three columns and based on flex-box where
//  - the new CMS grid is based on CSS3 grid system and 4 columns, with a fallback for IE11
//  - Changings these grid are fundamental changes with large impact, therefor we have this temporary grid
//  - End goal is a (more or less) equal footer in both CMS as RIS
//

$grid-gap: 40px;

.footer-grid {
    display: block;

    .tile, .col {
        grid-column: span 1;
        min-width: 0;

        @media ( max-width: ( $mobileLandscape - 1px ) ) {
            margin: $grid-gap 0px;
        }
    }

    @media ( min-width: $mobileLandscape ) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $grid-gap;
    }

    @media ( min-width: $landscape ) {
        grid-template-columns: repeat( 4, 1fr);
    }
}
