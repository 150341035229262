//
//  Code
//  - styling for the error code on the error page(system)
//

.error-page-code-holder {
    margin-top: 40px;

    @media( min-width: $landscape ) {
        --error-code-size: 64px;
    }

    text-align: center;
    font-size: var(--error-code-size);
    color: #e4e4e4;
    line-height: 1;
    position: relative;

    .error-page-code {
        font-weight: bold;
        font-size: calc( var(--error-code-size) * 3);
    }

    .error-page-letter, .error-page-icn {
        transform: translateY(100%);
        opacity: 0;
        animation: errorLetterIn var(--error-letter-timing) var(--error-easing) 0s forwards;
        display: inline-block;
    }

    .error-page-letter {
        &:nth-of-type(n+1) { animation-delay: 0s; }
        &:nth-of-type(n+2) { animation-delay: var(--error-letter-delay); }
        &:nth-of-type(n+3) { animation-delay: calc( var(--error-letter-delay) * 2); }
    }

    .error-page-icn { animation-delay: calc( var(--error-letter-delay) * 3 ); }
}
