//
//  Define CSS variables
//

:root {

    // Define primary, secondary and status colors
    @each $color in $colors {
        $name: nth($color, 1);
        $value: nth($color, 2);

        --#{$name}: #{$value};
    }

    // Define grey shades
    @each $color in $greyShades {
        $name: nth($color, 1);
        $value: nth($color, 2);

        --#{$name}: #{$value};
    }

    // Extra status colors
    --back-green: #eaffe2;
    --back-red: #ffe4e3;
    --back-yellow: #fff8e2;
    --back-blue: #dbf4ff;

    // Status text colors
    --txt-green: #435637;
    --txt-red: #563232;
    --txt-yellow: #7f6a3b;
    --txt-blue: #3c798e;

    // Extra grey shades
    --f5: #f5f5f5;
    --e4: #e4e4e4;
    --ee: #eee;
    --ec: #ececec;

    // Page background
    --page-bg: transparent;

    // Extra fIle type colors
    --pdf-red: #{$pdf-red};

    // Define shades of primary and secondary
    --hsl-primary-color: 24, 91%;
    --hsl-primary-lum: 51%;

    --hsl-secondary-color: 202, 100%;
    --hsl-secondary-lum: 33%;

    // Define font variables
    --text-color: #{$text-color-base};
    --font-family: #{$open-sans};
    --font-size: 1rem;
    --line-height: 1.8;
    --root-font-size: 87.5%;

    // Font size variables
    --font-size-small: 0.9em;
    --font-size-smaller: 0.8em;

    --font-size-large: #{calcEmFontSize(16pt)};
    --font-size-larger: #{calcEmFontSize(18pt)};

    // Heading font-sizes
    --font-size-h1: 1.8em;
    --font-size-h2: 1.6em;
    --font-size-h3: var(--font-size-larger);
    --font-size-h4: var(--font-size-large);
    --font-size-h5: var(--font-size);

    // Button colors
    --btn-primary: var(--primary);
    --btn-secondary: var(--secondary);

    // Link link
    --link-color: var(--secondary);
    --link-color-hover: #{$focus-outline-color};
    --focus-color: #{$focus-outline-color};

    // Row colors
    --item-row-bg: transparent;
    --item-row-hover-bg: var(--ee);
    --unread-border: var(--red);
    --unread-bg: var(--f5);
    --item-row-gap: 10px;

    // Logo's
    --logo: url(#{$logo});
    --logo-alt: url(#{$logo-alt});
    --emblem: url(#{$emblem});
    --emblem-alt: url(#{$emblem-alt});

    // Light grey color for date elements
    --date-color: var(--medium-grey);

    // Color of title in content-header element
    --content-header-color: var(--primary);

    // Default header variables
    --header-active: var(--primary);

    // Main header specific
    --header-bg: var(--dark-grey);
    --header-bg-color: 208, 23%;
    --header-bg-lum: 24%;
    --header-logo-bg: var(--primary);
    --logo-bg: var(--primary);
    --logo-width: 95px;
    --logo-padding: 15px;
    --logo-bottom: -15px;

    // Basic header specific
    --basic-bg: #{$f5};
    --basic-back-bg: #{$white};
    --basic-border: #{$e4};
    --basic-bar-bg: transparent;
    --basic-menu-txt: inherit;
    --basic-hover-border: #{$softGrey};
    --basic-hover-bg: #{$white};
    --basic-hover-txt: #{$darkGrey};

    // Footer variables
    --footer-bg: var(--dark-grey);
    --footer-bg-color: 208, 23%;
    --footer-bg-lum: 24%;
    --footer-color: #{$white};

    //--copybar-bg: color-mix( in srgb, var(--footer-bg) 75%, black );
    --copybar-txt: var(--footer-color);

    // Content and sidemenu
    --submenu-width: 300px;
    --submenu-offset: 70px;

    // Active tab
    --tab-active-txt: var(--primary);
    --tab-active-bg: #fff;
    --tab-active-border: #e4e4e4;

    // Mandate
    --imp-bar-bg: var(--focus-color);
    --imp-bar-index: 11;

    // Modifier for colored-contrast mode
    --colored-contrast-factor: 25%;
    --colored-contrast-mix: calc(100% - var(--colored-contrast-factor));

    // LTA Colors

    // Blue: #37afe4 || hsl(198, 76%, 55%)
    --lta-blue-hue: 198;
    --lta-blue-sat: 76%;
    --lta-blue-lum: 55%;

    // Green: #60BC3D || hsl(103, 51%, 49%)
    --lta-green-hue: 103;
    --lta-green-sat: 51%;
    --lta-green-lum: 49%;

    // Yellow: #FFC000 || hsl(45, 100%, 50%)
    --lta-yellow-hue: 45;
    --lta-yellow-sat: 100%;
    --lta-yellow-lum: 50%;

    // Orange: #F47721 || hsl(24, 91%, 54%)
    --lta-orange-hue: 24;
    --lta-orange-sat: 91%;
    --lta-orange-lum: 54%;

    // Red: #975ab7 || hsl(0, 99%, 36%)
    --lta-red-hue: 0;
    --lta-red-sat: 99%;
    --lta-red-lum: 36%;

    // Purple: #975cb7 || hsl(279, 39%, 54%)
    --lta-purple-hue: 279;
    --lta-purple-sat: 39%;
    --lta-purple-lum: 54%;

    // Grey #525a65 || hsl(215, 10%, 36%)
    --lta-grey-hue: 215;
    --lta-grey-sat: 10%;
    --lta-grey-lum: 36%;

    // Brown: #9e5726; ||
    --lta-brown-hue: 25;
    --lta-brown-sat: 61%;
    --lta-brown-lum: 38%;

    // Turquoise: #65C8BA || hsl(172, 47%, 59%)
    --lta-turquoise-hue: 172;
    --lta-turquoise-sat: 47%;
    --lta-turquoise-lum: 59%;

    // Pink: #ff99aa || hsl(350, 100%, 88%)
    --lta-pink-hue: 350;
    --lta-pink-sat: 100%;
    --lta-pink-lum: 80%;
}
