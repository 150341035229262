//
//  Details field grid
//  - CSS for the grid view of the fields with extra metadata
//
.mt-fields-grid {
    .mt-block {
        background-color: var(--f5);
        padding: 20px;
        font-size: var(--font-size-small);
        flex: 0 0 auto;
        margin-top: 20px;

        h2, h3 { font-size: var(--font-size-large); }
        @media print { border: 1px solid $e4; }

        &.text, &.mt-involved { flex-grow: 2; }
        &:last-of-type { flex-grow: 1; }

        &.overlap {
            &+.overlap {
                margin-top: -10px;
                padding-top: 0px;
            }
        }
    }

    .tile { margin: 0px; }

    .tile:empty {
        display: none;
    }

    @media ( min-width: $mobileLandscape ) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px var(--mt-details-grid-margin);
        margin: 20px 0px;

        .tile {
            display: flex;
            flex-flow: column nowrap;
        }
    }

    .tile:nth-of-type(even):last-of-type,
    .mt-metadata { grid-column: 1 / span 2; }

    .mt-involved .no-content {
        font-style: italic;
    }
}
