//
//  DMU Meetings
//  - shows an overview of archive or upcoming meetings of a DMU
//
.dmu-meeting-list {
    --_month-size: fit-content(3ch);
    --_pointer-size: 30px;
    --_grid: var(--_month-size) 1fr var(--_pointer-size);
    --_pointer-color: var(--primary);
    --_light-color: var(--alt-grey);

    li {
        margin-bottom: 10px;
    }
}

.dmu-meeting-item {

    // Grid
    display: grid;
    grid-template-columns: var(--_grid);
    grid-template-rows: 1fr;
    grid-gap: 0 15px;

    // On larger screens
    @media ( min-width: $mobileLandscape ) {
        grid-template-rows: 1fr;
    }

    // Styling
    box-shadow: 0px 0px 3px #0002;
    transition: all 0.15s linear;
    border: 1px solid var(--soft-grey);
    background-color: var(--white);
    text-decoration: none;
    padding: 5px 10px 5px 15px;
    color: inherit;
    max-width: 600px;

    &:hover {
        color: var(--white);
        background-color: var(--focus-color);
        --_pointer-color: var(--white);
        --_light-color: var(--white);
    }

    .date, .pointer {
        grid-row: 1 / 3;
        text-align: center;
        align-self: center;
    }

    .date {
        font-size: 26px;
        font-weight: bold;
        line-height: 1;
        color: var(--_light-color);

        span {
            font-size: 14px;
            display: block;
        }
    }

    .time-and-location .icn { color: var(--_light-color); }

    .pointer {
        grid-column: 3;
        color: var(--_pointer-color);
    }

    .tags {
        grid-column: 2;
        grid-row: 2;
    }
}
