//
//  Next meeting
//  - part of visual on home, show first upcomming meeting info
//

.streaming-row .next-meeting, .current-live-meta {
    .meta-block {
        flex: 1 1 auto;
        margin: 20px;
        padding-top: 0px;
        font-size: 1rem;
    }

    dt:not(.group-icn) { flex-basis: 100px; }

    & > h3, & > .toolbar { flex: 0 0 auto; }

    .meta-item { padding-left: 15px; }

    .meeting-group {
        padding-left: 0px;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 15px;
        border-bottom: 2px dotted $softGrey;

        dd {
            font-size: var(--font-size-larger);
            font-weight: bold;
            margin-left: 15px;
            line-height: 1.4;
        }
    }

    .group-icn .icn { color: inherit; margin: 0px; }
    .group-icn { flex-basis: 50px; }

    .toolbar {
        margin: auto 20px 20px 20px;
        text-align: center;
        justify-content: center;
    }

    // Different layout for tablet portait
    @media ( min-width: $mobileLandscape ) and ( max-width: ( $landscape - 1px )) {
        .toolbar {
            position: absolute;
            top: 72px;
            right: 20px;
        }
    }

    // Hide stuff for print
    @media print {
        height: auto;

        a { display: none; }
    }
}

.streaming-row .next-meeting {
	width: calc( 33.333333% - 20px );
	display: flex;
	flex-flow: column nowrap;
	float: right;
    margin: 0px;

	.block.next {
        display: flex;
        flex-flow: column nowrap;
    }

    @media ( min-width: $desktop ) {
        height: 441px;
    }
}

#next_live {
    position: relative;
    min-height: 100%;
    width: 100%;
}
